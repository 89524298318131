import { CompanyLocationState } from "entities/ApiModel/CompanyLocationState";
import { CompanyStates } from "entities/ApiModel/CompanyStates";
import { SessionStorageKeys } from "utils/data/enum";
import { StoreActionApi } from "react-sweet-state";
import { axiosSecuredInstance } from "configurations/axiosConfig";
import { createHook } from "react-sweet-state";
import { createStore } from "react-sweet-state";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";

interface State {
  companyLocationStates: CompanyLocationState[];
  allAgencies: SelectFieldOption[];
  stateList: CompanyStates[];
  globalAccessLoaded: boolean;
  selectedAgency: CompanyLocationState[] | [];
  showChangeAgency: boolean;
  fileNumber: string; 
  showFileNumberBox: boolean;
  siteError: boolean;
}

type StoreApi = StoreActionApi<State>;

const setCompanyLocationStates =
  (companyLocationStates: CompanyLocationState[]) =>
  ({ setState }: StoreApi) => {
    let allAgencies: SelectFieldOption[] = [];
    if (companyLocationStates?.length > 0){
      allAgencies = companyLocationStates.map((a) => {
        const agency: SelectFieldOption = {
          text: a.CompanyName || "",
          value: a.CompanyID || "",
          legacyID: a.CompanyLegacyID,
        };
        return agency;
      })
    }

    setState({
      companyLocationStates,
      globalAccessLoaded: true,
      showChangeAgency: companyLocationStates?.length > 1,
      allAgencies: allAgencies,
    });
  };

const setStateList =
  (stateList: CompanyStates[]) =>
  ({ setState }: StoreApi) => {
    setState({ stateList, globalAccessLoaded: true });
  };
const setUserSelectedAgency =
  (selectedAgency: CompanyLocationState[]) =>
  ({ setState }: StoreApi) => {
    setState({ selectedAgency });
    sessionStorage.setItem(SessionStorageKeys.GLOBAL_ACCESS_SELECTED_AGENCY, JSON.stringify(selectedAgency));
  };

const setFileNumber =
  (fileNumber: string) =>
    ({ setState }: StoreApi) => {
      setState({ fileNumber });
    }

const getCompanyLocationStateListByUser =
  (includeInactiveAgencies: boolean = false) =>
  async ({ dispatch }: StoreApi) => {
    try {
      const response = await axiosSecuredInstance.get(`/user/GetCompanyLocationStateListByUser?includeInactiveAgencies=${includeInactiveAgencies}`);
      const companyLocationStates = response.data;
      dispatch(setCompanyLocationStates(companyLocationStates));
    } catch (error) {
      console.error("Error fetching Company Location State List:", error);
    }
  };

const getStateListByUser =
  () =>
  async ({ dispatch }: StoreApi) => {
    try {
      const response = await axiosSecuredInstance.get("user/GetStateListByUser");
      const stateList = response.data;
      dispatch(setStateList(stateList));
    } catch (error) {
      console.error("Error fetching State List:", error);
    }
  };
const getSelectedAgency =
  () =>
  ({ getState }: StoreApi) => {
    return getState().selectedAgency;
  };

const setShowChangeAgency =
  (showOrHide: boolean) =>
  async ({ setState }: StoreApi) => {
    setState({ showChangeAgency: showOrHide });
  };

const clearUserSelectedAgency =
  () =>
  ({ setState }: StoreApi) => {
    setState({ selectedAgency: [] });
    sessionStorage.removeItem(SessionStorageKeys.GLOBAL_ACCESS_SELECTED_AGENCY);
  };
const getFileNumber =
  () =>
    ({ getState }: StoreApi) => {
      return getState().fileNumber;
    };

const setShowFileNumberBox = (show: boolean) => ({ setState }: StoreApi) => {
  setState({ showFileNumberBox: show });
};

const setSiteError = (isError: boolean) => ({ setState }: StoreApi) => {
  setState({ siteError: isError });
};

const actions = {
  getCompanyLocationStateListByUser,
  getStateListByUser,
  getSelectedAgency,
  getFileNumber,
  setUserSelectedAgency,
  setShowChangeAgency,
  clearUserSelectedAgency,
  setFileNumber,
  setShowFileNumberBox,
  setSiteError,
};

const CompanyLocationStateStore = createStore<State, typeof actions>({
  initialState: {
    companyLocationStates: [],
    allAgencies: [],
    stateList: [],
    globalAccessLoaded: false,
    selectedAgency: JSON.parse(sessionStorage.getItem(SessionStorageKeys.GLOBAL_ACCESS_SELECTED_AGENCY) || "[]"),
    showChangeAgency: false,
    showFileNumberBox: false,
    fileNumber: "",
    siteError: false,
  },
  actions,
  name: "companyLocationStateStore",
});

const hook = createHook(CompanyLocationStateStore);
export const useGlobalAccess = () => {
  return hook();
};

export default CompanyLocationStateStore;
