import ReportContentMessage from "controls/global/dialogs/stewart-dialog/dialog-content/ReportContentMessage";
import ReportOnlyButton from "controls/global/report-only/ReportOnlyButton";
import { ReportAndPayCriteria } from "entities/ApiModel/ReportAndPayCriteria";
import { SCFile } from "entities/UIModel";
import { ReportingResult } from "entities/UIModel/ReportingResult";
import mapUiSCFileToApiSCFile from "pages/file/utils/toApi/mapUiSCFileToApiSCFile";
import { runValidation } from "pages/file/utils/yup/validator";
import React, {
  useEffect,
  useRef, 
  useState,
} from "react";
import { useAutomaticProgressDialogActions } from "utils/context/AutomaticProgressDialogContext";
import { useDialog } from "utils/context/DialogContext";
import { useInitialValuesFiles } from "utils/context/FilesContext";
import { usePayment } from "utils/context/PaymentContext";
import { useProgressbar } from "utils/context/ProgressbarContext";
import useCreateFile from "utils/custom-hooks/useCreateFile";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import usePricingCalculator from "utils/custom-hooks/usePricingCalculator";
import {
  MapActionType,
  ReportAndPayAction,
} from "utils/data/enum";
import { v4 as uuidv4 } from "uuid";

type Props = {
  disabled?: boolean;
  isPricingSectionUpdated: boolean;
  hasFileLockedWithUpdateablePricingItem: boolean;
  displayRiskRateColumn: boolean;
  onReportingComplete: (reportingResult: ReportingResult) => void;
};

export const REPORT_ONLY_HELPER_TEXT =
  "By clicking <strong>Submit</strong>, the file will be reported and closed, and can be found in the Pending Payments section.";

export default function PricingReportOnly({
  disabled,
  isPricingSectionUpdated,
  hasFileLockedWithUpdateablePricingItem,
  displayRiskRateColumn,
  onReportingComplete,
}: Props) {
  const [, { openDialog }] = useDialog();
  const [, { openProgressbar }] = useProgressbar();
  const { setValue, getValues, trigger } = useFormWrapper();
  const [{ initialValues }, { saveFile }] = useInitialValuesFiles();
  const [, { reportPricing }] = usePayment();
  const { isFileLocked, reloadFile } = useCreateFile();
  const { getPricingTotal, getPricingNewTotal } = usePricingCalculator();
  const [, { openAutomaticProgressDialog, closeAutomaticProgressDialog }] =
    useAutomaticProgressDialogActions();
  const fileId = getValues("id");
  const reportingResultRef = useRef<ReportingResult>({});   

  const [disabledStatus, setDisabledStatus] = useState<boolean>(false);

  let totalDueTotal = "";
  if (isFileLocked) {
    const pricingNewTotal = getPricingNewTotal(isPricingSectionUpdated, displayRiskRateColumn);
    totalDueTotal = pricingNewTotal.newTotalTotalDue;
  } else {
    const pricingTotal = getPricingTotal(isPricingSectionUpdated, hasFileLockedWithUpdateablePricingItem, displayRiskRateColumn);
    totalDueTotal = pricingTotal.totalDueTotal;
  }

  const handleReportOnlyClick = () => {
    openDialog({
      title: "Report Only",
      content: (
        <ReportContentMessage
          mainText={`Are you ready to report this file for ${totalDueTotal}?`}
          helperText={REPORT_ONLY_HELPER_TEXT}
        />
      ),
      onSubmitAction: handleDialogSubmit,
    });
  };

  const handleDialogSubmit = async () => {
    const requestId = uuidv4();

    const formValues: SCFile = getValues();
    
    const isFormValid = await runValidation({
      values: formValues,
      trigger,
      setValue,
      productAction: MapActionType.ProductAction,
    });

    if (!isFormValid) {      
      return;
    }    

    openProgressbar({
      requestId,
      title: "Please wait while reporting your premiums...",
      onCloseAction: handleReportComplete,
    });

    const fileToSave = mapUiSCFileToApiSCFile(
      initialValues,
      formValues,
      undefined,
      MapActionType.SaveFile
    );

    await saveFile(fileToSave, false);

    const reportAndPayCriteria: ReportAndPayCriteria = {
      PendingPaymentItems: [
        {
          FileID: fileId,
        },
      ]
    }

    const result = await reportPricing(reportAndPayCriteria, requestId, ReportAndPayAction.ReportOnly);
    reportingResultRef.current = result;
  };

  const handleReportComplete = async () => {
    openAutomaticProgressDialog();
    await reloadFile(fileId);
    closeAutomaticProgressDialog();

    onReportingComplete(reportingResultRef.current);
  };

  // Reason for adding:
  // disabled property was not working i.e. disabled = true was still keeping the button enabled  
  useEffect(() => {        
    if(disabled && !disabledStatus) {      
      setDisabledStatus(true);
    } else if(!disabled && disabledStatus) {
      setDisabledStatus(false);
    }
  },[disabled, disabledStatus, setDisabledStatus]);
  
  return (
    <ReportOnlyButton
      onClick={handleReportOnlyClick}
      disabled={disabledStatus}
    />
  );
}
