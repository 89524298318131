import { yupResolver } from "@hookform/resolvers/yup";
import {
  styled,
} from "@mui/material";
import ScrollToTopArrow from "controls/global/scroll-to-top-arrow/ScrollToTopArrow";
import SideNav from "controls/global/side-nav";
import {
  ActionType,
  useSideNavProvider,
} from "pages/file/SideNavProvider";
import validationSchema from "pages/file/utils/yup/schema/createFileValidationSchema";
import React, {
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  FormProvider,
  useForm,
} from "react-hook-form";
import {
  gaps,
  padding,
} from "theme/defaultStyle";
import { useConfigContext } from "utils/context/ConfigContextProvider";
import { useDuplicatedFileCheck } from "utils/context/DuplicatedFileCheckContext";
import { useFilePropertyState } from "utils/context/FilePropertyStateContext";
import {
  useFiles,
  useInitialValuesFiles,
} from "utils/context/FilesContext";
import { usePartyAdditionalParties } from "utils/context/PartyAdditionalPartyContext";
import { usePartyBuyerBorrowerActions } from "utils/context/PartyBuyerBorrowerContext";
import { usePartyLenderActions } from "utils/context/PartyLenderContext";
import { usePartySellerActions } from "utils/context/PartySellerContext";
import { useLiabilityConfig } from "utils/context/PricingConfigContext";
import useNavRedirect from "utils/custom-hooks/useNavRedirect";
import useTitle from "utils/custom-hooks/useTitle";
import theme from "theme/default";
import FileInfo from "../file-info";
import FileSection from "../file-section";
import PartySection from "../parties-section/PartySection";
import PricingSection from "../pricing-section";
import ProductSection from "../product-section";
import PropertySection from "../property-section";
import PricingErrorHandler from "./PricingErrorHandler";
import SaveActions from "./SaveActions";
import SpyGlass from "./SpyGlass";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";
import useDocumentStore from "utils/context/ImageArchiveContext";
import { ImageArchiveSearchCriteria } from "entities/ApiModel/ImageArchiveSearchCriteria";
import { ImageArchiveUI } from "entities/UIModel/ImageArchiveUI";

interface Props {
  saveFileRef: any;
  saveFileChangeRef: any;
}

const PageContainer = styled("div")((props) => ({
  alignItems: "flex-start",
  display: "flex",
  gap: gaps.large1,
  maxWidth: "100%",
  position: "relative",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
  "& > :first-child": {
    flexGrow: 0,
    flexShrink: 0,
  },
  "& > :last-child": {
    flex: "auto",
    overflowY: "auto",
    paddingBottom: padding.small1,
    paddingLeft: padding.small1,
    paddingRight: padding.small1,
  },
}));

const SectionsContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large3,
  maxWidth: "100%",
  [theme.breakpoints.down("lg")]: {
    minWidth: "100%",
  },
});

function NewFileForm({ saveFileRef, saveFileChangeRef }: Props) {
  const [scrollToTop, setScrollToTop] = useState<boolean>(false);
  const [{ initialValues }] = useInitialValuesFiles();
  const [{ isReadOnly }, { setIsReadOnly }] = useFiles();
  const [{ selectedAgency, }, { setFileNumber ,setShowFileNumberBox }] = useGlobalAccess();  
     
  const [
    { isDuplicatedFile },
    { setIsDuplicatedFile, setPrevFileNameValueState },
  ] = useDuplicatedFileCheck();
  const [, { setBuyerBorrowers }] = usePartyBuyerBorrowerActions();
  const [, { setSellers }] = usePartySellerActions();
  const [, { setLenders }] = usePartyLenderActions();
  const [, { setAdditionalParties }] = usePartyAdditionalParties();
  const [, { setFilePropertyState, resetPropertyState }] =
    useFilePropertyState();
  const { featureConfig: { enableSpyMode } } = useConfigContext();
  const [liabilityConfig] = useLiabilityConfig();
  const { sideNavState, sideNavAction } = useSideNavProvider();
  const [, { getSearchDocuments }] = useDocumentStore();
  
  const getResolver = useCallback(
    async (data: any, context: any, options: any) => {
      const ctx = { ...context, liabilityConfig, isDuplicatedFile, isReadOnly };
      return yupResolver(validationSchema)(data, ctx, options);
    },
    [isDuplicatedFile, liabilityConfig, isReadOnly]
  );
  const methods = useForm({
    resolver: getResolver,
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: initialValues,
  });
  const { setValue, getValues, reset } = methods;
  const { redirect } = useNavRedirect();

  const handleNavigate = (url: string) => {       
    redirect(url, saveFileChangeRef);
  };

  useTitle(
    `Stewart Connect - ${initialValues.fileNameNumber
      ? initialValues.fileNameNumber
      : "Create File"
    }`
  );

  const setHasPolicyDocument = async (fileId: number) => {
    const criteria: ImageArchiveSearchCriteria = {
      fileId: fileId,
      listCount: 0,
      requestedPage: 1,
      isInitialLoad: false,
    };     
    let files: ImageArchiveUI[] | undefined = [];
    try {
      files = await getSearchDocuments({ ...criteria });
    } catch (error) {
      // do nothing here
    }
    setValue("hasUploadedPolicy", (files && files.length > 0) ? true:false);
    let hasIssuedProducts = getValues("hasIssuedProducts");    
    if(!hasIssuedProducts){
      setValue("hasIssuedProducts", (files && files.length > 0) ? true:false);
    }
  }  


  useEffect(() => {
    setIsDuplicatedFile(false);
    setPrevFileNameValueState(Date.now());
  }, []);

  useEffect(() => {    
    if(selectedAgency?.length <= 0) {      
      setIsReadOnly(false);
    }

    if (!initialValues) return;
    reset(initialValues);

    if (initialValues.buyerBorrowerParties)
      setBuyerBorrowers(initialValues.buyerBorrowerParties);
    if (initialValues.sellerParties) setSellers(initialValues.sellerParties);
    if (initialValues.lenderParties) setLenders(initialValues.lenderParties);
    if (initialValues.additionalParties)
      setAdditionalParties(initialValues.additionalParties);
    if (
      initialValues.properties &&
      initialValues.properties.length > 0 &&
      initialValues.properties[0].state?.code !== ""
    ) {
      setFilePropertyState(
        initialValues.properties[0].state.abbr,
        initialValues.properties[0].state.code
      );
    } else {
      resetPropertyState();
    }
    setFileNumber(initialValues.fileNameNumber)

    const setPolicyDocument = async() => {
      await setHasPolicyDocument(Number(initialValues.id));
    }
    if ((initialValues.id || 0) > 0) {
      setPolicyDocument();
    }
    
  }, [reset, initialValues ]);

  useEffect(() => {
    setShowFileNumberBox(true)
    return () => {
      sideNavAction({ type: ActionType.RESET });
      setShowFileNumberBox(false)
    };
  }, []);

  const filteredItems = Array.from(sideNavState.items.values()).filter(
    (item) => item.childRef !== undefined && item.childRef.current !== null
  );

  return (
    <FormProvider {...methods}>       
      <form noValidate>
        <PageContainer>
          <SideNav
            scrollToTop={scrollToTop}
            includeIconOverflowPadding={false}
            navItems={filteredItems}
          />
          <SectionsContainer>
            <FileSection onNavigate={handleNavigate} />
            <PropertySection />
            <PartySection />
            <ProductSection />
            <PricingSection onNavigate={handleNavigate} />
            <FileInfo />
            {enableSpyMode && <SpyGlass />}
            <ScrollToTopArrow
              refreshSize={[initialValues, filteredItems]}
              onScrollToTop={() => setScrollToTop(!scrollToTop)}
            />
          </SectionsContainer>
        </PageContainer>
      </form>
      <PricingErrorHandler />
      <SaveActions
        saveFileRef={saveFileRef}
        saveFileChangeRef={saveFileChangeRef}
      />
    </FormProvider>
  );
}

export default NewFileForm;
