import React, { 
  useEffect,
 } from "react";
import {
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useAutomaticProgressDialogActions } from "utils/context/AutomaticProgressDialogContext";
import { useFiles } from "utils/context/FilesContext";
import { usePricingActions } from "utils/context/PricingContext";
import { PricingErrorMessageProvider } from "./PricingErrorMessageProvider";
import { SideNavProvider } from "./SideNavProvider";
import NewFileForm from "./components/file-form-layout/CreateFile";
import { SessionStorageKeys } from "utils/data/enum";

interface Props {
  saveFileRef: any;
  saveFileChangeRef: any;
}

const CreateFilePage = ({ saveFileRef, saveFileChangeRef }: Props) => {
  const [, { getFileById, resetInitialValues }] = useFiles();
  const [, { setPricingNotificationUpdated }] = usePricingActions();
  const [, { openAutomaticProgressDialog, closeAutomaticProgressDialog }] =
    useAutomaticProgressDialogActions();
  const navigate = useNavigate();
  const location = useLocation();
  const fileID = location?.state?.fileID as string ?? undefined;

  useEffect(() => {
    const isAdvSearchOpen = (location?.state?.isAdvSearchOpen as boolean) ?? undefined;
    const handlePopstate = () => {
      if (isAdvSearchOpen) {
        sessionStorage.setItem(SessionStorageKeys.USE_PRIOR_SEARCH_CRITERIA_ADVANCE_SEARCH, "1");
        sessionStorage.removeItem(SessionStorageKeys.USE_PRIOR_SEARCH_CRITERIA);
      } else {
        sessionStorage.removeItem(SessionStorageKeys.USE_PRIOR_SEARCH_CRITERIA_ADVANCE_SEARCH);
      }
      sessionStorage.setItem(SessionStorageKeys.USE_PRIOR_SEARCH_CRITERIA, "1");
    };
    window.addEventListener("popstate", handlePopstate);
  }, [fileID]);

 useEffect(() => {
    if (!fileID)  {
      resetInitialValues();
      return;
    }

    const getFileAsync = async () => {
      openAutomaticProgressDialog();
      setPricingNotificationUpdated(false);
      setTimeout(async () => {
        const response = await getFileById(btoa(fileID));
        if (!response || response instanceof Error) {
          navigate("/404", { replace: true });
        }
      }, 500);
      setPricingNotificationUpdated(true);
      //DELAY UNTIL REACT-HOOK-FORM IS FULLY LOADED.
      closeAutomaticProgressDialog(300);
    };

    getFileAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileID, getFileById]);
  
  return (
    <PricingErrorMessageProvider>
      <SideNavProvider>
        <NewFileForm
          saveFileRef={saveFileRef}
          saveFileChangeRef={saveFileChangeRef}
        />
      </SideNavProvider>
    </PricingErrorMessageProvider>
  );
};

export default CreateFilePage;
