import { axiosSecuredInstance } from "configurations/axiosConfig";
import { ActionResult, Agency } from "entities/UIModel";
import {
  createHook,
  createStore,
  StoreActionApi,
} from "react-sweet-state";
import { hasValue } from "../shared";

interface State {
  isLoading: boolean;
  error: string | null;
  uploadImageLastSelectedAgency: Agency | null;
}

type StoreApi = StoreActionApi<State>;

const setLoading =
  () =>
    ({ setState }: StoreApi) => {
      setState({ isLoading: true });
    };

const setError =
  (error: string | null) =>
    ({ setState }: StoreApi) => {
      setState({ error });
    };

const Store = createStore<State, any>({
  initialState: {
    isLoading: false,
    error: null,
    uploadImageLastSelectedAgency: null,
  },
  actions: {
    getDocument:
      (
        documentId: string,
        defaultFilename: string,
        showHidden: number | undefined
      ) =>
        async ({ dispatch, setState }: StoreApi) => {
          try {
            dispatch(setLoading());
            if (!hasValue(showHidden)) {
              showHidden = 0;
            }
            const { data } = await axiosSecuredInstance.get(
              `/Documents/GetDocument?documentId=${documentId}&filename=${defaultFilename}&showHidden=${showHidden}`
            );
            setState({ isLoading: false });
            return data;
          } catch (error) {
            dispatch(setError(String(error)));
          }
        },
    getDocumentUrl:
      (
        fileId: number,
        productType: string,
        orderId: number,
        documentId: string,
        showHidden: number | undefined
      ) =>
        async ({ dispatch, setState }: StoreApi): Promise<ActionResult> => {
          let result: ActionResult = {
            error: null,
            pdfUrl: "",
          };

          try {

            dispatch(setLoading());
            if (!hasValue(showHidden)) {
              showHidden = 0;
            }
            const { data, headers } = await axiosSecuredInstance.get(
              `/Documents/GetDocumentUrl?fileID=${fileId}&productType=${productType}&orderID=${orderId}&documentId=${documentId}&showHidden=${showHidden}`,
              { responseType: "json" }
            );
            setState({ isLoading: false });
            result.pdfUrl = data.pdfUrl;
            result.documentId = data.documentId;
            result.headers = headers;
            return result;
          } catch (error) {
            dispatch(setError(String(error)));
            result.error = error;
            return result;
          }
        },
    getSupplementalDocumentUrl:
        (
          documentId: number,
          showHidden?: number,
        ) =>
          async ({ dispatch, setState }: StoreApi): Promise<ActionResult> => {
            let result: ActionResult = {
              error: null,
              pdfUrl: "",
            };
  
            try {
  
              dispatch(setLoading());
              if (!hasValue(showHidden)) {
                showHidden = 0;
              }
              const { data, headers } = await axiosSecuredInstance.get(
                `/Documents/GetSupplementalDocumentUrl?documentSupplementalId=${documentId}&showHidden=${showHidden}`,
                { responseType: "json" }
              );
              setState({ isLoading: false });
              result.documentUrl = data.documentUrl;
              result.documentId = data.documentId;
              result.headers = headers;
              return result;
            } catch (error) {
              dispatch(setError(String(error)));
              result.error = error;
              return result;
            }
          },        
    uploadFiles:
        (
          requestId: string,
          formData: FormData,
        ) =>
          async ({ dispatch, setState }: StoreApi) => {
            try {
              dispatch(setLoading());
              const { data } = await axiosSecuredInstance.post(
                `/documents/fileupload?requestId=${requestId}`,
                formData,
                { headers: { "Content-Type": "multipart/form-data" } }
              );
              setState({ isLoading: false });
              return data;
            } catch (error) {
              dispatch(setError(String(error)));
            }
          },        
    deleteDocument: (documentId: number) => async ({ dispatch, setState }: StoreApi) => {
          try {
            await axiosSecuredInstance.delete(`/Documents/DeleteDocumentSupplemental?documentSupplementalId=${documentId}`);
          } catch (error: any) {
            console.log(error);
          }
        },      
    setUploadImageLastSelectedAgency:
        (agency: Agency) =>
        ({ setState }: StoreApi) => {
          setState(
            { 
              uploadImageLastSelectedAgency: agency,
            });
        },        
  },
  name: "document",
});

const hook = createHook(Store);
export const useDocument = () => { return hook(); };
