import * as ApiModel from "entities/ApiModel";
import * as UIModel from "entities/UIModel";
import {
  Jacket,
  PricingDetail,
  PricingProductItem
} from "entities/UIModel";
import isEqual from "lodash/isEqual";
import remove from "lodash/remove";
import { Location } from "utils/context/StewartAccessContext";
import { Translation } from "utils/context/TranslationContext";
import {
  Country,
  EndorsementStatusType,
  FileStatusType,
  JacketFormType,
  MapActionType,
  OrderStatusType,
  PartyRoleType,
  PartyType,
  PricingType,
  ProductAction,
  ProductType,
  SequenceType,
} from "utils/data/enum";
import {
  INT_MAX,
  REGEX_NONE_ASCII,
  cloneObject,
  convertToNumber,
  formatDateToJSONString,
  roundToPrecision,
} from "utils/shared";
import { v4 as uuidv4 } from "uuid";
import {
  checkIfFileIsLocked,
  getFormDetailTranslatedName,
  hasNotNullOrEmptyProp,
  hasPendingProducts,
  isIssuableEndorsement,
  isPricingItemUpdateable,
  isSavableParty,
  isValidEndorsement,
  isValidJacket,
  isValidParty,
  isValidLetter,
  isValidProperty,
  isValidStandalone,
} from "../helper";

const setPrimaryParty = (parties: UIModel.GeneralFileParty[]) => {
  parties
    .filter((fileParty: UIModel.GeneralFileParty) => isValidParty(fileParty))
    .map((fileParty: UIModel.GeneralFileParty) => {
      fileParty.isPrimary = false;
      fileParty.sequenceTypeCode = SequenceType.Second;
      return fileParty;
    });

  parties[0].isPrimary = true;
  parties[0].sequenceTypeCode = SequenceType.First;
};

const notEqualWithKeys = <T>(
  keys: (keyof T)[],
  first: T | undefined,
  second: T,
  logKeyValues: boolean = false
) => {
  let isNotEqual = false;
  keys.forEach((key: keyof T) => {
    isNotEqual =
      isNotEqual || notEqualWithKey(key, first, second, logKeyValues);
  });
  return isNotEqual;
};

const notEqualWithKey = <T>(
  key: keyof T,
  first: T | undefined,
  second: T,
  logKeyValues: boolean
) => {
  if (!first) return true;

  const val1 = first[key];
  const val2 = second[key];
  let isNotEqual: boolean = false;
  if (val1 && typeof val1 === "object") {
    if (typeof val2 === "object") isNotEqual = !isEqual(val1, val2);
  } else if (typeof val1 === "string" && typeof val2 === "string")
    isNotEqual = val1.trimEnd().toLowerCase() !== val2.trimEnd().toLowerCase();
  else if (!val1 && !val2) isNotEqual = false;
  else if (typeof val1 === "number" || typeof val2 === "number")
    isNotEqual = !isEqual(
      convertToNumber(val1 as any),
      convertToNumber(val2 as any)
    );
  else isNotEqual = val1 !== val2;

  if (logKeyValues || isNotEqual) {
    // console.log("first:", first, "second:", second);
    logKeyEquality(key, val1, val2, isNotEqual);
  }
  return isNotEqual;
};

const logKeyEquality = <T>(
  key: keyof T,
  initialValue: T[keyof T],
  newValue: T[keyof T],
  notEqual: boolean
) => {
  console.log({ key, initialValue, newValue, notEqual });
  console.groupCollapsed(
    `%c${key.toString().toUpperCase()}`,
    `color:${notEqual ? "red" : "green"}`
  );
  console.log(
    `%cINITIAL_VALUE=${initialValue}, VALUE TYPE=${typeof initialValue}`,
    "color:lightblue"
  );
  console.log(
    `%cCURRENT_VALUE=${newValue}, VALUE TYPE=${typeof newValue}`,
    "color:magenta"
  );
  console.groupEnd();
};

const markIsDirtyIsNew = (
  apiFile: ApiModel.SCFile,
  initialFile: UIModel.SCFile,
  scFile: UIModel.SCFile,
  mapActionType: string = MapActionType.SaveFile
) => {
  //#region File Level
  apiFile.IsNew = apiFile.IdentityKey === 0;
  let checkFileFields: (keyof UIModel.SCFile)[] = [
    "fileNameNumber",
    "statusTypeCode",
  ];

  if (mapActionType !== MapActionType.CheckFormDirty)
    checkFileFields.push("authorityLimit");
  if (mapActionType !== MapActionType.CheckFormDirty || !scFile.isFileDefault)
    checkFileFields.push("transactionTypeCode");

  apiFile.IsDirty =
    !scFile.isFileDefault ||
    notEqualWithKeys(checkFileFields, initialFile, scFile);

  if (!apiFile.IsDirty && mapActionType !== MapActionType.CheckFormDirty) {
    apiFile.IsDirty =
      notEqualWithKeys(["id", "name"], initialFile.agency, scFile.agency) ||
      notEqualWithKeys(
        ["id", "name"],
        initialFile.agencyLocation,
        scFile.agencyLocation
      );
  }
  //#endregion

  //#region FilePricing Configuration
  if (apiFile.FilePricing) {
    apiFile.FilePricing.IsNew = apiFile.FilePricing.IdentityKey === 0;    
    apiFile.FilePricing.IsDirty = notEqualWithKeys(
      [
        "isActualCalc",
        "isCalculationRequired",
        "isDisclosed_TRIDCalc",
        "isOverride",
        "isExcludedFromAutoReport",
        "isSimultaneousRate",
        "overrideReason",
        "rateEffectiveDate",
      ],
      initialFile.pricing,
      scFile.pricing
    );
  }
  //#endregion

  //#region Property
  apiFile.Properties?.forEach((property) => {
    const initProperty = initialFile.properties.find(
      (p) => p.integrationKey === property.IntegrationKey
    );
    const saveProperty = scFile.properties.find(
      (p) => p.integrationKey === property.IntegrationKey
    );

    const isPropertyDirty = saveProperty?.isDefault !== true;
    if (saveProperty) {
      if ((saveProperty?.identityKey || 0) <= 0) {
        property.IsNew = saveProperty?.isDefault !== true;
        property.IsDirty = isPropertyDirty;
        property.IdentityKey = saveProperty?.isDefault
          ? -1
          : property.IdentityKey;
      } else {
        property.IsDirty = isPropertyDirty ||
          notEqualWithKeys(
            [
              "addressOne",
              "addressTwo",
              "legalDescription",
              "zipCode",
              "city",
              "state",
              "county",
              "propertyType",
              "propertyAddressTypeCode",
              "taxCode",
            ],
            initProperty,
            saveProperty
          );
      }
    }
  });
  //#endregion

  //#region Parties
  const saveAllParties = [
    ...scFile.buyerBorrowerParties,
    ...scFile.sellerParties,
    ...scFile.lenderParties,
    ...scFile.additionalParties,
  ].map((p) => {
    p.integrationKey = p.integrationKey ? p.integrationKey : uuidv4();
    return p;
  });

  const initAllParties = [
    ...initialFile.buyerBorrowerParties,
    ...initialFile.sellerParties,
    ...initialFile.lenderParties,
    ...initialFile.additionalParties,
  ].map((p) => {
    p.integrationKey = p.integrationKey ? p.integrationKey : uuidv4();
    return p;
  });

  apiFile.Parties?.forEach((party) => {
    let isPartyDirty = false;
    const initParty = initAllParties.find(
      (p) => p.integrationKey === party.IntegrationKey
    );
    const saveParty = saveAllParties.find(
      (p) => p.integrationKey === party.IntegrationKey
    );

    const isNew = saveParty?.identityKey === 0;

    if (isNew || initParty === undefined || saveParty === undefined) {
      party.IsNew = true;
      party.IsDirty = true;
    } else {
      isPartyDirty =
        isPartyDirty ||
        notEqualWithKeys(
          [
            "partyRoleTypeCode",
            "partyTypeCode",
            "companyName",
            "partyFirstName",
            "partyMiddleName",
            "partyLastName",
            "individualAttorney",
            "suffixType",
            "loanNumber",
            "successorLanguage",
            "attentionTo",
            "isForeignAddress",
            "addressOne",
            "addressTwo",
            "addressThree",
            "city",
            "state",
            "zipCode",
            "county",
            "country",
            "phone",
            "mobile",
            "fax",
            "email",
            "isExcludedOnCPL",
            "favorite",
            "isHidden",
            "saveToContacts",
            "isExcludedOnAAL",
          ],
          initParty,
          saveParty
        );

      party.IsDirty = isPartyDirty;
    }
  });
  //#endregion

  //#region AALs
  apiFile.AALProducts?.forEach((product) => {
    let isProductDirty = false;

    const initProduct =
      initialFile.aALProducts &&
      initialFile.aALProducts.find(
        (aal) => aal.integrationKey === product.IntegrationKey
      );
    const saveProduct =
      scFile.aALProducts &&
      scFile.aALProducts.find(
        (aal) => aal.integrationKey === product.IntegrationKey
      );

    if (saveProduct) {
      if (
        //This will recognize a cpl as new if it has no identityKey
        //and it's isDefault is not set to true
        (saveProduct?.identityKey || 0) <= 0 ||
        (mapActionType === MapActionType.CheckFormDirty &&
          saveProduct.orderStatusTypeCode === OrderStatusType.Pending)
      ) {
        product.IsNew = saveProduct?.isDefault !== true;
        product.IsDirty = saveProduct?.isDefault !== true;
        //server side sees IsNew = IdentityKey == 0.  So we need to be -1
        product.IdentityKey = saveProduct?.isDefault ? -1 : product.IdentityKey;
      } else {
        let checkFields: (keyof UIModel.AAL)[] = [
          "orderStatusTypeCode",
          "productReferenceID",
          "effectiveDate",
          "coveredParty",
          "addresseeNames",
          "form",
          "includeAllBranches",
          "counsel",
          "addressOne",
          "addressTwo",
          "city",
          "zipCode",
          "loanNumber",
          "successorLanguage",
          "attentionTo",
          "buyerBorrower",
          "formID",
          "formVersion",
          "formTemplateName",
          "opaIdentifier",
          "isAgencyExclusive",
          "liabilityAmount",
          "altaRegistryID",
          "territoryID",
          "isForeignAddress",
          "foreignCountry",
          "foreignState",
        ];

        // exclude these fields from Save Prompt checking
        if (mapActionType === MapActionType.CheckFormDirty) {
          remove(checkFields, (field) => {
            return [
              "formID",
              "formVersion",
              "formTemplateName",
              "opaIdentifier",
              "isAgencyExclusive",
              "liabilityAmount",
              "altaRegistryID",
              "territoryID",
            ].includes(field);
          });
        }

        isProductDirty =
          isProductDirty ||
          notEqualWithKeys(checkFields, initProduct, saveProduct);

        if (initProduct?.state && saveProduct.state) {
          isProductDirty =
            isProductDirty ||
            notEqualWithKeys(["code"], initProduct.state, saveProduct.state);
        }

        if (
          initProduct?.pricingDetail &&
          saveProduct.pricingDetail &&
          !saveProduct.isLocationDefault
        ) {
          let pricingFields: (keyof PricingDetail)[] = [
            "contractID",
            "legacyID",
            "locationDisplayName",
            "locationID",
            "locationLegalName",
            "locationUniqueID",
          ];

          // exclude these fields from Save Prompt checking
          if (mapActionType === MapActionType.CheckFormDirty) {
            remove(pricingFields, (field) => {
              return [
                "contractID",
                "legacyID",
                "locationID",
                "locationLegalName",
                "locationUniqueID",
              ].includes(field);
            });
          }

          const isPricingDetailDirty = notEqualWithKeys(
            pricingFields,
            initProduct.pricingDetail,
            saveProduct.pricingDetail
          );

          product.PricingDetail!.IsDirty = isPricingDetailDirty;
          if (isPricingDetailDirty) {
            isProductDirty = true;
          }
        }

        product.IsDirty = isProductDirty;
      }
    }
  });
  //#endregion

  //#region CPLs
  apiFile.CPLProducts?.forEach((product) => {
    let isProductDirty = false;

    const initProduct = initialFile.cpls.find(
      (cpl) => cpl.integrationKey === product.IntegrationKey
    );
    const saveProduct = scFile.cpls.find(
      (cpl) => cpl.integrationKey === product.IntegrationKey
    );

    if (saveProduct) {
      if (
        //This will recognize a cpl as new if it has no identityKey
        //and it's isDefault is not set to true
        (saveProduct?.identityKey || 0) <= 0 ||
        (mapActionType === MapActionType.CheckFormDirty &&
          saveProduct.orderStatusTypeCode === OrderStatusType.Pending)
      ) {
        product.IsNew = saveProduct?.isDefault !== true;
        product.IsDirty = saveProduct?.isDefault !== true;
        //server side sees IsNew = IdentityKey == 0.  So we need to be -1
        product.IdentityKey = saveProduct?.isDefault ? -1 : product.IdentityKey;
      } else {
        let checkFields: (keyof UIModel.CPL)[] = [
          "orderStatusTypeCode",
          "printAttorneyOnly",
          "productReferenceID",
          "effectiveDate",
          "coveredParty",
          "addresseeNames",
          "form",
          "includeAllBranches",
          "addressOne",
          "addressTwo",
          "city",
          "zipCode",
          "loanNumber",
          "successorLanguage",
          "attentionTo",
          "buyerBorrower",
          "formID",
          "formVersion",
          "formTemplateName",
          "opaIdentifier",
          "isAgencyExclusive",
          "liabilityAmount",
          "altaRegistryID",
          "territoryID",
          "isForeignAddress",
          "foreignCountry",
          "foreignState",
          "addlParties",
        ];

        // exclude these fields from Save Prompt checking
        if (mapActionType === MapActionType.CheckFormDirty) {
          remove(checkFields, (field) => {
            return [
              "formID",
              "formVersion",
              "formTemplateName",
              "opaIdentifier",
              "isAgencyExclusive",
              "liabilityAmount",
              "altaRegistryID",
              "territoryID",
            ].includes(field);
          });
        }

        isProductDirty =
          isProductDirty ||
          notEqualWithKeys(checkFields, initProduct, saveProduct);

        if (initProduct?.state && saveProduct.state) {
          isProductDirty =
            isProductDirty ||
            notEqualWithKeys(["code"], initProduct.state, saveProduct.state);
        }

        if (product.CoveredParty.toUpperCase() === PartyRoleType.Seller) {
          isProductDirty =
            isProductDirty ||
            notEqualWithKeys(["seller"], initProduct, saveProduct);
        }

        if (
          initProduct?.pricingDetail &&
          saveProduct.pricingDetail &&
          !saveProduct.isLocationDefault
        ) {
          let pricingFields: (keyof PricingDetail)[] = [
            "contractID",
            "legacyID",
            "locationDisplayName",
            "locationID",
            "locationLegalName",
            "locationUniqueID",
          ];

          // exclude these fields from Save Prompt checking
          if (mapActionType === MapActionType.CheckFormDirty) {
            remove(pricingFields, (field) => {
              return [
                "contractID",
                "legacyID",
                "locationID",
                "locationLegalName",
                "locationUniqueID",
              ].includes(field);
            });
          }

          const isPricingDetailDirty = notEqualWithKeys(
            pricingFields,
            initProduct.pricingDetail,
            saveProduct.pricingDetail
          );

          product.PricingDetail!.IsDirty = isPricingDetailDirty;
          if (isPricingDetailDirty) {
            isProductDirty = true;
          }
        }

        product.IsDirty = isProductDirty;
      }
    }
  });
  //#endregion

  //#region Jackets
  apiFile.JacketProducts?.forEach((product) => {
    let isProductDirty = false;

    const initProduct = initialFile.jackets.find(
      (jacket) => jacket.integrationKey === product.IntegrationKey
    );
    const saveProduct = scFile.jackets.find(
      (jacket) => jacket.integrationKey === product.IntegrationKey
    );

    if (saveProduct) {
      if ((saveProduct?.identityKey || 0) <= 0) {
        product.IsNew = saveProduct?.isDefault !== true;
        product.IsDirty = saveProduct?.isDefault !== true;
        product.IdentityKey = saveProduct?.isDefault ? -1 : product.IdentityKey;
      } else {
        let checkFields: (keyof UIModel.Jacket)[] = [
          "effectiveDate",
          "formType",
          "form",
          "isDeleted",
          "insuredNames",
          "underwriterTypeCode",          
          "integrationKey",
          "productReferenceID",
          "void",
          "form",
          "formType",
          "formID",
          "formVersion",
          "formTemplateName",
          "opaIdentifier",
          "isAgencyExclusive",
          "altaRegistryID",
          "territoryID",
        ];

        // exclude these fields from Save Prompt checking
        if (mapActionType === MapActionType.CheckFormDirty) {
          remove(checkFields, (field) => {
            return [
              "formID",
              "formVersion",
              "formTemplateName",
              "opaIdentifier",
              "isAgencyExclusive",
              "altaRegistryID",
              "territoryID",
            ].includes(field);
          });
        }

        // if (scFile.pricing.isIntegratedPricing) {
        //   remove(checkFields, (field) => {
        //     return [
        //       "originalJacketDate",
        //       "originalJacketLiability",
        //       "originalJacketNumber",
        //       "originalJacketUnderwriterTypeCode",
        //     ].includes(field);
        //   });
        // }
        isProductDirty =
          isProductDirty ||
          notEqualWithKeys(checkFields, initProduct, saveProduct);

        if (initProduct?.insuredNamesData && saveProduct.insuredNamesData) {
          const isIssuedNamesDataEqual = isEqual(
            initProduct?.insuredNamesData,
            saveProduct.insuredNamesData
          );
          isProductDirty = isProductDirty || !isIssuedNamesDataEqual;
        }

        product.IsDirty = isProductDirty;

        if (initProduct?.pricingDetail && saveProduct.pricingDetail) {
          let varList: (keyof PricingDetail)[] = [
            "integrationKey",
            "isSimultaneous",
            "isLeasehold",
            "liability",
            // "actualFee",
            // "calculatedFee",
            // "actualRiskRate",
            // "calculatedRiskRate",
            // "actualRemittance",
            // "calculatedRemittance",
            // "actualRetention",
            // "calculatedRetention",
            "isReissue",
            // "coverageType",
            // "pricingRateType",
            "contractID",
            "legacyID",
            "locationDisplayName",
            "locationID",
            "locationLegalName",
            "locationUniqueID",
          ];

          // exclude these fields from Save Prompt checking
          if (mapActionType === MapActionType.CheckFormDirty) {
            remove(varList, (field) => {
              return [
                "contractID",
                "legacyID",
                "locationID",
                "locationLegalName",
                "locationUniqueID",
                // "isReissue",
              ].includes(field);
            });
          }

          if (saveProduct.isTransCodeRequired) {
            varList.push("transCode");
          }
          const isPricingDetailDirty = notEqualWithKeys(
            varList,
            initProduct.pricingDetail,
            saveProduct.pricingDetail
          );

          product.PricingDetail!.IsDirty = isPricingDetailDirty;

          if (isPricingDetailDirty) {
            product.IsDirty = isPricingDetailDirty;
          }

          // if (!scFile.pricing.isIntegratedPricing) {

            // let OPNCheckFields: (keyof UIModel.Jacket)[] = [
            //   "originalJacketNumber",
            //   "originalJacketLiability",
            //   "originalJacketType",
            //   "originalJacketDate"
            // ];
            // // Check if ReIssue has changes
            // const hasChangesPricingDetail = notEqualWithKeys(
            //   OPNCheckFields,
            //   initProduct,
            //   saveProduct
            // );

            // if (hasChangesPricingDetail) {
            //   product.PricingDetail!.IsDirty = true;
            //   product.IsDirty = true;
            // }
          // }
        }

        //#region Jacket Endorsements
        if (product.Endorsements && product.Endorsements.length > 0) {
          product.Endorsements.forEach((endorsement) => {
            let isEndorsementDirty = false;

            const initEndorsement = initProduct?.endorsements?.find(
              (end) => end.integrationKey === endorsement.IntegrationKey
            );
            const saveEndorsement = saveProduct.endorsements?.find(
              (end) => end.integrationKey === endorsement.IntegrationKey
            );

            const isNew = saveEndorsement?.identityKey === 0;

            if (
              isNew ||
              initEndorsement === undefined ||
              saveEndorsement === undefined
            ) {
              endorsement.IsNew = true;
              endorsement.IsDirty = true;
              product.IsDirty = true;
            } else {
              let checkEndorsementEffectiveDate: (keyof UIModel.Endorsement)[] =
                ["effectiveDate"];

              const isEndorsementEffectiveDateDirty = notEqualWithKeys(
                checkEndorsementEffectiveDate,
                initEndorsement,
                saveEndorsement
              );

              let checkEndorsementFields: (keyof UIModel.Endorsement)[] = [
                "endorsementName",
                "endorsementNumber",
                "effectiveDate",
                "endorsementStatusTypeCode",
                "issueDateTime",
                "revisionDate",
                "voidedDate",
                "integrationKey",
                "isSFE",
                "altEndorsementID",
                "endorsementVersion",
                "opaIdentifier",
              ];

              // exclude these fields from Save Prompt checking
              if (mapActionType === MapActionType.CheckFormDirty) {
                remove(checkEndorsementFields, (field) => {
                  return [
                    "altEndorsementID",
                    "endorsementVersion",
                    "opaIdentifier",
                  ].includes(field);
                });
              }

              isEndorsementDirty =
                isEndorsementDirty ||
                notEqualWithKeys(
                  checkEndorsementFields,
                  initEndorsement,
                  saveEndorsement
                );

              if (
                initEndorsement?.pricingDetail &&
                saveEndorsement?.pricingDetail
              ) {
                let checkEPFields: (keyof PricingDetail)[] = [
                  "contractID",
                  "legacyID",
                  "locationDisplayName",
                  "locationID",
                  "locationLegalName",
                  "locationUniqueID",
                ];

                // exclude these fields from Save Prompt checking
                if (mapActionType === MapActionType.CheckFormDirty) {
                  remove(checkEPFields, (field) => {
                    return [
                      "contractID",
                      "legacyID",
                      "locationID",
                      "locationLegalName",
                      "locationUniqueID",
                    ].includes(field);
                  });
                }

                if (saveProduct.isTransCodeRequired) {
                  checkEPFields.push("transCode");
                }

                const isEPDirty = notEqualWithKeys(
                  checkEPFields,
                  initEndorsement.pricingDetail,
                  saveEndorsement.pricingDetail
                );

                endorsement.PricingDetail!.IsDirty = isEPDirty;

                if (isEPDirty) {
                  isEndorsementDirty = true;
                }
              }

              endorsement.IsDirty = isEndorsementDirty;

              if (
                //isEndorsementDirty &&
                isEndorsementEffectiveDateDirty &&
                product.ProductAction === ProductAction.Revise
              )
                endorsement.ProductAction = ProductAction.Revise;

              if (!product.IsDirty)
                product.IsDirty = isEndorsementDirty
                  ? isEndorsementDirty
                  : isProductDirty;
            }
          });
          if (
            initProduct?.endorsements &&
            initProduct.endorsements.length > 0 &&
            initProduct.endorsements.length !== product.Endorsements.length
          ) {
            product.IsDirty = true;
          }
        }

        // check if number of initial product's endorsements have been altered
        if (
          initProduct?.endorsements &&
          initProduct.endorsements.length > 0 &&
          initProduct.endorsements.length !== product.Endorsements?.length
        ) {
          product.IsDirty = true;
        }

        //#endregion

        //#region Jacket Form Details
        if (product.JacketFormDetails) {
          if (product.ProductAction || mapActionType === MapActionType.SaveFile) {
            product.JacketFormDetails?.forEach((formDetail) => {
              let isFormDetailDirty = false;

              const initFormDetail = initProduct?.formDetailsCopy?.find(
                (end) => end?.integrationKey === formDetail?.IntegrationKey
              );
              const saveFormDetail =
                saveProduct &&
                saveProduct.formDetails?.find(
                  (end) => end?.integrationKey === formDetail?.IntegrationKey
                );

              const isNew = saveFormDetail?.identityKey === 0;

              if (
                isNew ||
                initFormDetail === undefined ||
                saveFormDetail === undefined
              ) {
                formDetail.IsNew = true;
                formDetail.IsDirty = true;
                product.IsDirty = true;
              } else {
                const formDetailFields: (keyof UIModel.JacketFormDetail)[] = [
                  "formID",
                  "formDetailID",
                  "formDetailValue",
                  "integrationKey",
                ];

                // exclude these fields from Save Prompt checking
                if (mapActionType === MapActionType.CheckFormDirty) {
                  remove(formDetailFields, (field) => {
                    return ["formID", "formDetailID"].includes(field);
                  });
                }

                isFormDetailDirty =
                  isFormDetailDirty ||
                  notEqualWithKeys(
                    formDetailFields,
                    initFormDetail,
                    saveFormDetail
                  ) ||
                  wasPremiumUpdatedOnRevise(formDetail, saveProduct, initProduct);

                formDetail.IsDirty = isFormDetailDirty;
                if (!product.IsDirty)
                  product.IsDirty = isFormDetailDirty
                    ? isFormDetailDirty
                    : isProductDirty;
              }
            });
          } else {
            product.JacketFormDetails?.forEach((formDetail) => {
              formDetail.IsNew = false;
              formDetail.IsDirty = false;
            });
          }
        }
        //#endregion

        //#region Jacket Signatures
        if (product.JacketSignatures) {
          if (product.ProductAction || mapActionType === MapActionType.SaveFile) {
            product.JacketSignatures?.forEach((signature) => {
              const isDirty = signature.IsDirty;

              const initSignature = initProduct?.jacketSignatures?.find(
                (s) => s?.integrationKey === signature?.IntegrationKey
              );
              const saveSignature =
                saveProduct &&
                saveProduct.jacketSignatures?.find(
                  (s) => s?.integrationKey === signature?.IntegrationKey
                );

              const isNew = saveSignature?.identityKey === 0;

              if (
                isNew ||
                initSignature === undefined ||
                saveSignature === undefined
              ) {
                signature.IsNew = true;
                signature.IsDirty = true;
                product.IsDirty = true;
              } else {
                // Currently jacketSignature.isDirty is handled via the SignatureField logic.
                // Update the check below if this changes.
                // const jacketSignatureFields: (keyof UIModel.JacketSignature)[] = [];

                // // exclude these fields from Save Prompt checking
                // if (mapActionType === MapActionType.CheckFormDirty) {
                //   remove(jacketSignatureFields, (field) => {
                //     return ["formID", "formDetailID", "signatureImageID"].includes(field);
                //   });
                // }

                // isDirty =
                //   isDirty ||
                //   notEqualWithKeys(
                //     jacketSignatureFields,
                //     initSignature,
                //     saveSignature
                //   );

                signature.IsDirty = isDirty;
                if (!product.IsDirty)
                  product.IsDirty = isDirty
                    ? isDirty
                    : isProductDirty;
              }
            });
          } else {
            product.JacketSignatures?.forEach((s) => {
              s.IsNew = false;
              s.IsDirty = false;
            });
          }
        }
        //#endregion

      }
    }
  });
  //#endregion

  //#region Standalone Endorsements
  apiFile.StandaloneEndorsementProducts?.forEach((endorsement) => {
    let isProductDirty = false;

    const initProduct = initialFile.standaloneEndorsements.find(
      (standalone) => standalone.integrationKey === endorsement.IntegrationKey
    );
    const saveStandalone = scFile.standaloneEndorsements.find(
      (standalone) => standalone.integrationKey === endorsement.IntegrationKey
    );

    if (saveStandalone) {
      if ((saveStandalone?.identityKey || 0) <= 0) {
        endorsement.IsNew = !saveStandalone.isDefault;
        endorsement.IsDirty = !saveStandalone.isDefault;
        endorsement.IdentityKey = saveStandalone.isDefault
          ? -1
          : endorsement.IdentityKey;
      } else {
        let checkFields: (keyof UIModel.StandaloneEndorsement)[] = [
          "effectiveDate",
          "integrationKey",
          "orderStatusTypeCode",
          "originalJacketDate",
          "originalJacketLiability",
          "originalJacketNumber",
          "originalJacketType",
          "productReferenceID",
          "altaRegistryID",
          "territoryID",
        ];

        // exclude these fields from Save Prompt checking
        if (mapActionType === MapActionType.CheckFormDirty) {
          remove(checkFields, (field) => {
            return ["altaRegistryID", "territoryID"].includes(field);
          });
        }

        isProductDirty =
          isProductDirty ||
          notEqualWithKeys(checkFields, initProduct, saveStandalone);

        if (initProduct?.pricingDetail && saveStandalone.pricingDetail) {
          let pricingFields: (keyof PricingDetail)[] = [
            "contractID",
            "legacyID",
            "locationDisplayName",
            "locationID",
            "locationLegalName",
            "locationUniqueID",
          ];

          // exclude these fields from Save Prompt checking
          if (mapActionType === MapActionType.CheckFormDirty) {
            remove(pricingFields, (field) => {
              return [
                "contractID",
                "legacyID",
                "locationID",
                "locationLegalName",
                "locationUniqueID",
              ].includes(field);
            });
          }

          const isPricingDetailDirty = notEqualWithKeys(
            pricingFields,
            initProduct.pricingDetail,
            saveStandalone.pricingDetail
          );

          endorsement.PricingDetail!.IsDirty = isPricingDetailDirty;
          if (isPricingDetailDirty) {
            isProductDirty = true;
          }
        }

        endorsement.IsDirty = isProductDirty;

        if (endorsement.Endorsements) {
          endorsement.Endorsements.forEach((standalone) => {
            let isEndorsementDirty = false;

            const initEndorsement = initProduct?.endorsements?.find(
              (end) => end.integrationKey === standalone.IntegrationKey
            );
            const saveEndorsement = saveStandalone.endorsements?.find(
              (end) => end.integrationKey === standalone.IntegrationKey
            );

            const isNew = saveEndorsement?.identityKey === 0;

            if (
              isNew ||
              initEndorsement === undefined ||
              saveEndorsement === undefined
            ) {
              standalone.IsNew = true;
              standalone.IsDirty = true;
              endorsement.IsDirty = true;
            } else {
              let checkEndorsementFields: (keyof UIModel.Endorsement)[] = [
                "endorsementName",
                "endorsementNumber",
                "effectiveDate",
                "endorsementStatusTypeCode",
                "issueDateTime",
                "revisionDate",
                "voidedDate",
                "integrationKey",
                "isSFE",
                "altEndorsementID",
                "endorsementVersion",
                "opaIdentifier",
              ];

              // exclude these fields from Save Prompt checking
              if (mapActionType === MapActionType.CheckFormDirty) {
                remove(checkEndorsementFields, (field) => {
                  return [
                    "altEndorsementID",
                    "endorsementVersion",
                    "opaIdentifier",
                  ].includes(field);
                });
              }             

              isEndorsementDirty =
                isEndorsementDirty ||
                notEqualWithKeys(
                  checkEndorsementFields,
                  initEndorsement,
                  saveEndorsement
                );

              if (
                initEndorsement?.pricingDetail &&
                saveEndorsement?.pricingDetail
              ) {
                let checkEPFields: (keyof PricingDetail)[] = [
                  "contractID",
                  "legacyID",
                  "locationDisplayName",
                  "locationID",
                  "locationLegalName",
                  "locationUniqueID",
                ];

                // exclude these fields from Save Prompt checking
                if (mapActionType === MapActionType.CheckFormDirty) {
                  remove(checkEPFields, (field) => {
                    return [
                      "contractID",
                      "legacyID",
                      "locationID",
                      "locationLegalName",
                      "locationUniqueID",
                    ].includes(field);
                  });
                }

                if (saveStandalone.isTransCodeRequired) {
                  checkEPFields.push("transCode");
                }

                const isEPDirty = notEqualWithKeys(
                  checkEPFields,
                  initEndorsement.pricingDetail,
                  saveEndorsement.pricingDetail
                );

                endorsement.PricingDetail!.IsDirty = isEPDirty;

                if (isEPDirty) {
                  isEndorsementDirty = true;
                }
              }

              standalone.IsDirty = isEndorsementDirty;
              if (isEndorsementDirty) endorsement.IsDirty = true;
            }
          });
        }
      }
    }
  });
  //#endregion

  //#region Pricing Products
  // -- IsDirty for Pricing Products (FilePricingDetails) is being handled in the mapping section
  //#endregion
};

const wasPremiumUpdatedOnRevise = (
  formDetail: ApiModel.JacketFormDetail,
  saveProduct: UIModel.Jacket,
  initProduct?: UIModel.Jacket
) => {
  const isPremiumDetail =
    formDetail.FormDetailName === "Premium" ||
    formDetail.FormDetailName === "Premium (for Sch A only)";
  return (
    isPremiumDetail &&
    notEqualWithKeys(["newPremiumAmount"], initProduct, saveProduct)
  );
};

const mapUiSCFileToApiSCFile = (
  initialSCFile: UIModel.SCFile,
  scfile: UIModel.SCFile,
  locations?: Array<Location>,
  mapActionType?: string,
  productAction?: string,
  productType?: string,
  integrationKeys?: string[],
  jacketsOPN?: Jacket[],
  translations?: Array<Translation>
): ApiModel.SCFile => {
  // Notes: // SqlNull is not the real sql NULL value, but the Stored Procedure will convert it to SQL NULL value when it's set to 'NULL' OR -9999999

  // Only map to "NULL" for existing record and not Revise CPL
  const mapStringToSqlNull = (
    productType: string,
    identityKey: number,
    value?: string
  ) => {
    if (
      identityKey === 0 ||
      ((productType === ProductType.Cpl || productType === ProductType.Aal) &&
        mapActionType === MapActionType.ProductAction &&
        productAction === ProductAction.Revise)
    ) {
      return !value ? undefined : value;
    } else {
      return !value ? "NULL" : value;
    }
  };

  // Only map to -9999999 for existing record and not Revise CPL
  const mapNumberToSqlNull = (
    productType: string,
    identityKey: number,
    value?: string | number | null
  ) => {
    if (
      identityKey === 0 ||
      ((productType === ProductType.Cpl || productType === ProductType.Aal) &&
        mapActionType === MapActionType.ProductAction &&
        productAction === ProductAction.Revise)
    ) {
      return !value ? undefined : convertToNumber(value);
    } else {
      return !value ? -9999999 : convertToNumber(value);
    }
  };

  const mapYNToSqlNull = (
    productType: string,
    identityKey: number,
    value?: string | null
  ) => {
    if (
      identityKey === 0 ||
      ((productType === ProductType.Cpl || productType === ProductType.Aal) &&
        mapActionType === MapActionType.ProductAction &&
        productAction === ProductAction.Revise)
    ) {
      return !value ? undefined : value;
    } else {
      return !value ? "E" : value;
    }
  };

  const mapNumberToRealSqlNull = (value?: string | number | null) => {
    return !value ? undefined : convertToNumber(value);
  };

  if (scfile.buyerBorrowerParties && scfile.buyerBorrowerParties.length > 0) {
    setPrimaryParty(scfile.buyerBorrowerParties);
  }

  if (scfile.sellerParties && scfile.sellerParties.length > 0) {
    setPrimaryParty(scfile.sellerParties);
  }

  if (scfile.lenderParties && scfile.lenderParties.length > 0) {
    setPrimaryParty(scfile.lenderParties);
  }

  if (scfile.additionalParties && scfile.additionalParties.length > 0) {
    setPrimaryParty(scfile.additionalParties);
  }
  
  const allParties = [
    ...scfile.buyerBorrowerParties,
    ...scfile.sellerParties,
    ...scfile.lenderParties,
    ...scfile.additionalParties.filter((p) => p.selectedAdditionalPartyID && p.companyName),
  ].map((p) => {
    p.integrationKey = p.integrationKey ? p.integrationKey : uuidv4();
    return p;
  });

  // -- rlo 7/2/2021 - ONLY map additional party now -- other order parties will be mapped in back end
  const getLetterOrderParties = (
    productType: string,
    orderID: number | undefined,
    integrationKey: string,
    addlParties: string[] | undefined,
    oldOrderParties: UIModel.OrderParty[] | undefined,
    coveredParty: string,
    isDirty: boolean,
    fileId?: number
  ) => {
    let orderParties: Array<ApiModel.OrderParty> = [];
    let partyRoleTypeCodes: string[] = [];
    // let isAttorneySelected: boolean = false;

    // -- map additional party first
    if (addlParties && addlParties.length > 0) {
      addlParties?.forEach((filePartyKey) => {
        let party = allParties.find((p) => p.filePartyKey === filePartyKey);
        let oParty = oldOrderParties?.find(
          (op) => op.integrationKey === filePartyKey
        );
        if (party) {
          // if (party.partyTypeCode === PartyType.Attorneys) {
          //   isAttorneySelected = true;
          // }

          let seequenceTypeCode = SequenceType.First;
          if (
            partyRoleTypeCodes.findIndex(
              (partyRoleType) =>
                partyRoleType === party?.partyRoleTypeCode || ""
            ) >= 0
          ) {
            seequenceTypeCode = SequenceType.Second;
          }

          orderParties.push({
            IsDirty: true,
            IsNew: (oParty?.orderPartyID || 0) > 0 ? false : true,
            IntegrationKey: party.integrationKey,
            FileID: fileId || oParty?.fileID || 0,
            FilePartyID: party.filePartyId || 0,
            FilePartyDataID: oParty?.filePartyDataID || 0,
            OrderPartyID: oParty?.orderPartyID || 0,
            OrderID: oParty?.orderID || 0,
            OrderPartyDataID: oParty?.orderPartyDataID || 0,
            PartyRoleTypeCode: party.partyRoleTypeCode,
            PartyTypeCode: party.partyTypeCode,
            SequenceTypeCode: seequenceTypeCode,
            PartyFirstName: party.partyFirstName,
            PartyMiddleName: party.partyMiddleName,
            PartyLastName: party.partyLastName,
            PartyCompanyName: party.companyName,
            PartyAdditionalName: party.individualAttorney,
            SuffixTypeCode: party.suffixType?.code,
            SuffixTypeName: party.suffixType?.name,
            Address: party.addressOne,
            Address2: party.addressTwo,
            City: party.city,
            StateCode: party.state?.code,
            StateAbbr: party.state?.abbr,
            Zip: party.zipCode,
          });
          partyRoleTypeCodes.push(party.partyRoleTypeCode);
        }
      });
    }

    return orderParties;
  };

  const getAttorneySelected = (addlParties: string[] | undefined) => {
    let isAttorneySelected = false;

    if (addlParties && addlParties.length > 0) {
      addlParties.forEach((filePartyKey) => {
        let party = allParties.find(
          (p) =>
            p.filePartyKey === filePartyKey &&
            p.partyTypeCode === PartyType.Attorneys
        );

        if (party) isAttorneySelected = true;
      });
    }

    return isAttorneySelected;
  };

  const setSequenceTypeCode = (partyRoleType: string) => {
    apiscfile.Parties &&
      apiscfile.Parties.forEach((p) => {
        if (p.PartyRoleTypeCode === partyRoleType) {
          p.SequenceTypeCode = SequenceType.Second;
        }
      });
  };

  const getAddresseeNameData = (addresseeNamesData?: any) => {
    if (!addresseeNamesData) return "";

    return JSON.stringify(addresseeNamesData);
  };
  
  const mapFileBase = (): ApiModel.SCFile => {
    let filebase: ApiModel.SCFile = {
      IsLocked: scfile.isLocked,
      IsDirty: true, //scfile.isDirty,
      FileID: scfile.id || 0,
      IdentityKey: scfile.identityKey || 0,
      IntegrationKey: scfile.integrationKey,
      ClientFileID: scfile.fileNameNumber?.replace(REGEX_NONE_ASCII, "")?.trim(),
      FilePricingID: scfile.filePricingID,
      FileStatusTypeCode: scfile.statusTypeCode || FileStatusType.Open,
      FileStatusTypeName: scfile.statusTypeName || "",
      PrevFileStatusTypeCode: scfile.prevStatusTypeCode || "",
      AgencyID: scfile.agency && scfile.agency.id ? scfile.agency.id : "",
      AgencyName: scfile.agency && scfile.agency.name ? scfile.agency.name : "",
      ActiveContractID: scfile.agency && scfile.agency.activeContractID ? scfile.agency.activeContractID : "",
      LocationID: scfile?.locationID || "",
      // LocationName:
      //   scfile.agencyLocation && scfile.agencyLocation.name
      //     ? scfile.agencyLocation.name
      //     : "",
      LocationDisplayName:
        scfile.agencyLocation && scfile.agencyLocation.name
          ? scfile.agencyLocation.name
          : "",
      LegacyID: scfile?.agency?.legacyID ?? "",
      LocationUniqueID: scfile?.locationUniqueID || "",
      TransactionTypeCode: scfile.transactionTypeCode || "",
      AuthorityLimit: mapNumberToRealSqlNull(scfile.authorityLimit),
      AltFileReferenceID: scfile?.altFileReferenceId,
    };
    return filebase;
  };

  const mapFilePricing = (
    isRetrievePricingData?: boolean
  ): ApiModel.FilePricing => {
    const newIntegrationKey = uuidv4();

    let filePricing: ApiModel.FilePricing = {
      DisableSimultaneousRate: scfile.pricing?.disableSimultaneousRate || false,
      CoInsurancePct: convertToNumber(scfile.pricing?.coInsurancePct),
      IsCoInsurance: scfile.pricing?.isCoInsurance || false,
      IsDirty: true,
      IntegrationKey: scfile.pricing?.integrationKey || newIntegrationKey,
      IdentityKey: scfile.pricing?.identityKey || 0,
      FilePricingID: scfile.pricing?.id || 0,
      FileID: scfile.pricing?.fileID || 0,
      IsActualCalc: scfile.pricing?.isActualCalc || false,
      IsCalculationRequired:
        ((productType === ProductType.Jacket ||
          productType === ProductType.Endorsement ||
          productType === ProductType.StandaloneEndorsement) &&
          productAction === ProductAction.Issue) ||
          isRetrievePricingData
          ? false
          : scfile.pricing?.isCalculationRequired || false,
      IsDisclosed_TRIDCalc: scfile.pricing?.isDisclosed_TRIDCalc || false,
      IsExcludedFromAutoReport: Number(scfile.pricing?.isExcludedFromAutoReport || false),
      IsIntegratedPricing: scfile.pricing?.isIntegratedPricing || false,
      IsOverride: scfile.pricing?.isOverride || false,
      IsSimultaneousRate: scfile.pricing?.isSimultaneousRate || false,
      OPNLiabilityLabel: scfile.pricing?.policy?.liabilityLabel,
      OPNWindowTitle: scfile.pricing?.policy?.windowTitle,
      OverrideReasonTypeCode: scfile.pricing?.overrideReason || "",
      PartitionYear: scfile.pricing?.partitionYear,
      RateEffectiveDate: formatDateToJSONString(
        scfile.pricing?.rateEffectiveDate,
        true
      ),
      ShowAgentRetention: scfile.pricing?.showAgentRetention || false,
      ShowCoInsurance: scfile.pricing?.showCoInsurance || false,
      ShowLiabilityAmount: scfile.pricing?.showLiabilityAmount || false,
      ShowOPNCoverageType: scfile.pricing?.policy?.showCoverageType || false,
      ShowOPNFormType: scfile.pricing?.policy?.showFormType || false,
      ShowOPNLiabilityAmount:
        scfile.pricing?.policy?.showLiabilityAmount || false,
      ShowOPNPolicyDate: scfile.pricing?.policy?.showPolicyDate || false,
      ShowOPNPolicyNumber: scfile.pricing?.showOPNPolicyNumber || false,
      ShowOPNPriorPolicyLast10Yrs:
        scfile.pricing?.policy?.showOPNPriorPolicyLast10Yrs || false,
      ShowReissue: scfile.pricing?.showReissue || false,
      ShowReissueCheckbox: scfile.pricing?.showReissueCheckbox || false,
      ShowReissueColumnLabel: scfile.pricing?.showReissueColumnLabel || false,
      ShowReissueEdit: scfile.pricing?.showReissueEdit || false,
      ShowRiskRate: scfile.pricing?.showRiskRate || false,
      ShowTax: scfile.pricing?.showTax || false,
      TaxCode: scfile.pricing?.taxCode,
      LegacyID: scfile?.agency?.legacyID ?? "",
      LocationUniqueID: scfile.locationUniqueID,

      ReportOptionTypeCode: scfile.pricing?.reportOptionTypeCode,
    };
    return filePricing;
  };

  const mapDocumentFileData = (): ApiModel.DocumentFileData => {
    let documentFileDataUI: ApiModel.DocumentFileData = {
      DocumentFileDataID: scfile.documentFileData?.documentFileDataID || 0,
      IdentityKey: scfile.documentFileData?.identityKey || 0,
      IntegrationKey: scfile.documentFileData?.integrationKey || uuidv4(),
      FilePricingID: scfile.documentFileData?.filePricingID || 0,
      FileID: scfile.id || 0,
      CompanyAddress: scfile.documentFileData?.companyAddress || "",
      CompanyAddress2: scfile.documentFileData?.companyAddress2 || "",
      CompanyCity: scfile.documentFileData?.companyCity || "",
      CompanyZipCode: scfile.documentFileData?.companyZipCode || "",
      CompanyCountry: scfile.documentFileData?.companyCountry || "",
      CompanyMailPhone: scfile.documentFileData?.companyMailPhone || "",
      CompanyMobilePhone: scfile.documentFileData?.companyMobilePhone || "",
      CompanyFaxNumber: scfile.documentFileData?.companyFaxNumber || "",
      CompanyPrimaryEmail: scfile.documentFileData?.companyPrimaryEmail || "",
    };
    return documentFileDataUI;
  };

  const mapQNAs = (): Array<ApiModel.UserInput> => {
    let userInputs: Array<ApiModel.UserInput> = [];
    if (scfile.combinedQNAs) {
      userInputs = scfile.combinedQNAs.map((userInput) => {
        return {
          QuestionsAnswersID: userInput.questionsAnswersID,
          OrderID: userInput.orderID,
          FilePricingDetailID: userInput.filePricingDetailID,
          Questions: userInput.questions.map((question) => {
            return {
              QuestionID: question.questionID,
              QuestionText: question.questionText,
              AnswerValue: question.answerValue,
            };
          }),
        };
      });
    }
    return userInputs;
  };

  const mapProperty = (): Array<ApiModel.Property> => {
    let properties: Array<ApiModel.Property> = [];
    if (scfile.properties) {
      scfile.properties.forEach((property) => {
        if (isValidProperty(property) || (property.id || 0) > 0) {
          properties.push({
            IsDirty: true,
            PropertyID: property.id || 0,
            FileID: scfile.id || 0,
            IdentityKey: property.identityKey || 0,
            IntegrationKey: property.integrationKey,
            PropertyAddressTypeCode:
              property.propertyAddressTypeCode || "PHYSICAL",
            PropertyTypeCode: property.propertyType,
            Address: property.addressOne,
            Address2: property.addressTwo,
            City: property.city,
            StateCode: property.state?.code,
            StateAbbr: property.state?.abbr,
            Zip: property.zipCode,
            CountyCode: property.county?.code || "",
            CountyName: property.county?.name,
            PropertyIdentifiers: property.legalDescription,
            TaxCode: property.showTaxCode ? property.taxCode?.value : "",
          });
          if (
            (property.identityKey || 0) <= 0 &&
            property.isDefault &&
            scfile.fileNameNumber &&
            scfile.agency &&
            scfile.agency.id &&
            (productAction === ProductAction.Issue ||
              mapActionType === MapActionType.SaveFile)
          )
            property.isDefault = !(
              property.county.name || property.state?.code
            );
        }
      });
    }

    return properties;
  };

  const mapParty = (): Array<ApiModel.Party> => {
    let parties = new Array<ApiModel.Party>(0);

    if (allParties) {
      parties = allParties
        .filter((fileParty: UIModel.GeneralFileParty) =>
          isSavableParty(fileParty)
        )
        .map((p) => {
          let party: ApiModel.Party = {
            // Party keys
            IsDirty: true, // p.isDirty
            FilePartyID: p.filePartyId || 0,
            IdentityKey: p.identityKey || 0,
            FileID: p.fileID,
            IntegrationKey: p.integrationKey ? p.integrationKey : uuidv4(),
            FilePartyDataID: p.filePartyDataID,

            // Party information
            PartyRoleTypeCode: p.partyRoleTypeCode,
            PartyTypeCode: p.partyTypeCode,
            PartyCompanyName: p.companyName,
            PartyFirstName: p.partyFirstName,
            PartyMiddleName: p.partyMiddleName,
            PartyLastName: p.partyLastName,
            PartyAdditionalName: p.individualAttorney,
            SuffixTypeCode: p.suffixType?.code,
            SuffixTypeName: p.suffixType?.name,
            LoanNumber: p.loanNumber,
            SuccessorLanguage: p.successorLanguage,
            AttentionTo: p.attentionTo,

            // Address information
            IsForeignAddress: p.isForeignAddress,
            ForeignCountry: p.foreignCountry,
            ForeignState: p.foreignState,

            Address: p.addressOne,
            Address2: p.addressTwo,
            Address3: p.addressThree,
            City: p.city,
            StateCode: p.state?.code,
            StateAbbr: p.state?.abbr,
            StateName: p.state?.code,
            Zip: p.zipCode,
            // CountyCode: p.county ? p.county.code : undefined,
            // CountyName: p.county ? p.county.name : undefined,
            CountryCodeNum: p.country ? p.country.codeNum : undefined,
            CountryName: p.country ? p.country.name : undefined,
            Phone: p.phone,
            Mobile: p.mobile,
            Fax: p.fax,
            Email: p.email,
            MigrationKey: p.migrationKey,

            // Other information
            IsExcludedOnCPL: p.isExcludedOnCPL ? 1 : 0,
            Favorite: p.favorite ? 1 : 0,
            IsHidden: p.isHidden ? 1 : 0,
            IsPrimary: p.isPrimary ? 1 : 0,
            SaveToContacts: p.saveToContacts ? 1 : 0,
            IsExcludedOnAAL: p.isExcludedOnAAL ? 1 : 0,
            SequenceTypeCode: p.sequenceTypeCode,
            AltReferenceID: p.altReferenceID,
            LocationID: p.locationID,
            CompanyID: p.companyID,
          };

          return party;
        });
    }

    return parties;
  };

  const mapDocumentOrderData = (
    documentOrderData?: any,
    formPrefix?: string,
    pricingDetail?: PricingDetail
  ): ApiModel.DocumentOrderData => {
    let documentFileDataUI: ApiModel.DocumentOrderData = {
      DocumentOrderDataID: documentOrderData?.documentOrderDataID || 0,
      IdentityKey: documentOrderData?.identityKey || 0,
      IntegrationKey: documentOrderData?.integrationKey || uuidv4(),
      FilePricingID:
        documentOrderData?.filePricingID || pricingDetail?.filePricingID,
      FilePricingDetailID:
        documentOrderData?.filePricingDetailID ||
        pricingDetail?.filePricingDetailID,
      FileID: scfile.id || 0,
      OrderID: documentOrderData?.orderID || pricingDetail?.orderID,
      FormPrefix: formPrefix || documentOrderData?.formPrefix,
      LocationAddress: documentOrderData?.locationAddress || "",
      LocationAddress2: documentOrderData?.locationAddress2 || "",
      LocationCity: documentOrderData?.locationCity || "",
      LocationZipCode: documentOrderData?.locationZipCode || "",
      LocationCountry: documentOrderData?.locationCountry || "",
      LocationMailPhone: documentOrderData?.locationMailPhone || "",
      LocationMobilePhone: documentOrderData?.locationMobilePhone || "",
      LocationFaxNumber: documentOrderData?.locationFaxNumber || "",
      LocationPrimaryEmail: documentOrderData?.locationPrimaryEmail || "",
    };
    return documentFileDataUI;
  };

  const mapCpl = (isCalculateRequested: boolean = false): Array<ApiModel.CPLProduct> => {
    let cplproducts: Array<ApiModel.CPLProduct> = [];

    const getProductAction = (cpl: UIModel.CPL) => {
      let derivedProductAction = "";
      if (mapActionType === MapActionType.ProductAction) {
        //if(productAction) return productAction;
        if (
          integrationKeys &&
          integrationKeys?.indexOf(cpl.integrationKey) >= 0
        ) {
          derivedProductAction = productAction
            ? productAction
            : ProductAction.Issue;
        }
      }

      return derivedProductAction;
    };

    if (scfile.cpls) {
      cplproducts = scfile.cpls
        .filter((cpl: UIModel.CPL) => isValidLetter(cpl))
        .map((cpl) => {
          let orderParties: Array<ApiModel.OrderParty> = [];
          // let partyRoleTypeCodes: Array<string | undefined> = [];
          let isAttorneySelected: boolean = getAttorneySelected(
            cpl.addlParties
          ); // false;
          let pendingChanges: boolean =
            cpl.isDirty ||
              cpl.orderStatusTypeCode === OrderStatusType.Pending ||
              cpl.orderStatusTypeCode === OrderStatusType.Error
              ? true
              : false;

          // -- rlo 7/2/2021 - ONLY map additional party now -- other order parties will be mapped in back end
          // -- map additional party and other parties based on CPL's CoveredParty
          if (
            cpl.orderStatusTypeCode !== OrderStatusType.Issued ||
            (cpl.orderStatusTypeCode === OrderStatusType.Issued &&
              mapActionType === MapActionType.ProductAction &&
              productAction === ProductAction.Revise)
          ) {
            orderParties = getLetterOrderParties(
              ProductType.Cpl,
              cpl.orderID,
              cpl.integrationKey,
              cpl.addlParties,
              cpl.orderParties,
              cpl.coveredParty,
              pendingChanges,
              cpl.fileID
            );
          }

          let isReviseWithActualFeeUpdated: boolean =
            productAction === ProductAction.Revise &&
            cpl.pricingDetail?.actualFee !==
            cpl.pricingDetail?.originalActualFee;

          //During issue and revise, the hasAddressedChanged property should
          //be set to true to avoid revised defaults from overwriting user
          //data on future revisions
          if (
            cpl.addresseeNamesData &&
            (productAction === ProductAction.Issue ||
              productAction === ProductAction.Revise)
          )
            cpl.addresseeNamesData.hasAddressedChanged = true;

          let cplproduct: ApiModel.CPLProduct = {
            // keys
            IsDirty: false,
            ProductID: cpl.id || 0,
            IdentityKey: cpl.identityKey || 0,
            ProductAction: getProductAction(cpl),
            ProductTypeCode: ProductType.Cpl,
            OrderID: cpl.orderID,
            FileID: cpl.fileID,

            // cpl information
            OrderStatusTypeCode: cpl.orderStatusTypeCode, // OrderStatusType.Pending, // temp -- will need to set in UIModel cpl object
            OrderStatusTypeName: cpl.orderStatusTypeName,
            IssueDate: cpl.issueDate || undefined,
            IssueDateTime:
              formatDateToJSONString(cpl.issueDateTime) || undefined,
            IntegrationKey: cpl.integrationKey,
            EffectiveDate: formatDateToJSONString(cpl.effectiveDate, true),
            CoveredParty: cpl.coveredParty,
            PrintAttorneyOnly:
              isAttorneySelected && cpl.printAttorneyOnly ? 1 : 0,
            ProductReferenceID: cpl.productReferenceID,
            VoidReason: cpl.void ? cpl.void.reason : "",
            Branch: cpl.includeAllBranches ? "Include All Branches" : "None",
            TransactionTypeCode: scfile.transactionTypeCode,
            Form: cpl.form,
            FormID: mapStringToSqlNull(
              ProductType.Cpl,
              cpl.identityKey || 0,
              cpl.formID
            ),
            FormVersion: mapNumberToSqlNull(
              ProductType.Cpl,
              cpl.identityKey || 0,
              cpl.formVersion
            ),
            FormPolicyPrefix: mapStringToSqlNull(
              ProductType.Cpl,
              cpl.identityKey || 0,
              cpl.formPrefix
            ),
            FormPrefix: mapStringToSqlNull(
              ProductType.Cpl,
              cpl.identityKey || 0,
              cpl.formPrefix
            ),
            FormTemplateName: mapStringToSqlNull(
              ProductType.Cpl,
              cpl.identityKey || 0,
              cpl.formTemplateName
            ),
            IsAgencyExclusive: mapYNToSqlNull(
              ProductType.Cpl,
              cpl.identityKey || 0,
              cpl.isAgencyExclusive
            ),
            OPAIdentifier: mapStringToSqlNull(
              ProductType.Cpl,
              cpl.identityKey || 0,
              cpl.opaIdentifier
            ),
            LiabilityAmount: mapNumberToSqlNull(
              ProductType.Cpl,
              cpl.identityKey || 0,
              cpl.liabilityAmount
            ),

            // Location info
            // rlo 3/03/2022 - MUST SET in Order level too
            LocationID: cpl.pricingDetail?.locationID || "",
            // LocationName: cpl.pricingDetail?.locationDisplayName || "", // will be dropped after backend drop it
            LocationDisplayName: cpl.pricingDetail?.locationDisplayName || "",
            LegacyID: cpl.pricingDetail?.legacyID || "",
            LocationUniqueID: cpl.pricingDetail?.locationUniqueID || "",
            LocationLegalName: cpl.pricingDetail?.locationLegalName || "",
            ContractID: cpl.pricingDetail?.contractID || "",
            UnderwriterTypeCode: cpl.underwriterTypeCode || cpl.pricingDetail?.underwriterTypeCode || "",

            TerritoryID: mapStringToSqlNull(
              ProductType.Cpl,
              cpl.identityKey || 0,
              cpl.territoryID
            ),
            ALTARegistryID: mapStringToSqlNull(
              ProductType.Cpl,
              cpl.identityKey || 0,
              cpl.altaRegistryID
            ),

            Address: cpl.addressOne,
            Address2: cpl.addressTwo,
            City: cpl.city,
            StateCode: cpl.state?.code,
            StateAbbr: cpl.state?.abbr,
            Zip: cpl.zipCode,
            ForeignCountry: cpl.foreignCountry,
            ForeignState: cpl.foreignState,
            IsForeignAddress: cpl.isForeignAddress,

            // Order Parties
            OrderParties: orderParties,
            //AddresseeNames: cpl.addresseeNames ? cpl.addresseeNames[0] : getAddresseeNameDisplay(cpl.addresseeNamesData),
            //AddresseeNames: getAddresseeNameDisplay(cpl.addresseeNamesData),
            AddresseeNames: cpl.addresseeNames,
            AddresseeNameData: getAddresseeNameData(cpl.addresseeNamesData),
            BuyerBorrower: cpl.buyerBorrower,
            Seller: cpl.seller,
            LoanNumber: cpl.loanNumber,
            SuccessorLanguage: cpl.successorLanguage,
            AttentionTo: cpl.attentionTo,
            DisplayOrder: cpl.displayOrder,
            FilePricingDetailID: cpl.filePricingDetailID,
            DocumentOrderData: mapDocumentOrderData(
              cpl.documentOrderData,
              cpl.formPrefix,
              cpl.pricingDetail
            ),
            PricingDetail: {
              IntegrationKey: cpl.pricingDetail?.integrationKey || uuidv4(),
              IsDirty: pendingChanges,
              OrderID: cpl.orderID || 0,
              FileID: cpl.fileID || scfile.id || 0,
              FilePricingID: cpl.pricingDetail?.filePricingID || 0,
              FilePricingDetailID: cpl.filePricingDetailID || 0,
              IsReissue: cpl.pricingDetail?.isReissue || false,
              IsSimultaneous: cpl.pricingDetail?.isSimultaneous || false,
              IsLeasehold: cpl.pricingDetail?.isLeasehold || false,
              TransCode: cpl.pricingDetail?.transCode,
              // Product: ProductType.Jacket,
              Liability: cpl.pricingDetail?.liability
                ? parseFloat(
                  cpl.pricingDetail?.liability
                    .toString()
                    .replace("$", "")
                    .replace(",", "")
                )
                : 0.0,
              AltPricingReferenceID: cpl.pricingDetail?.altPricingReferenceID,
              LastErrorMessage: cpl.pricingDetail?.lastErrorMessage,
              LastDisplayMessage: cpl.pricingDetail?.lastDisplayMessage,
              IsFeeError: cpl.pricingDetail?.isFeeError,
              // IsManualFee: cpl.pricingDetail?.isManualFee,

              // Map pricing amounts here
              ActualFee: convertToNumber(cpl.pricingDetail?.actualFee),
              CalculatedFee: convertToNumber(cpl.pricingDetail?.calculatedFee),
              ActualPremiumTax: convertToNumber(
                cpl.pricingDetail?.actualPremiumTax
              ),
              CalculatedPremiumTax: convertToNumber(
                cpl.pricingDetail?.calculatedPremiumTax
              ),
              ActualRemittance: isReviseWithActualFeeUpdated
                ? 0
                : convertToNumber(cpl.pricingDetail?.actualRemittance),
              CalculatedRemittance: isReviseWithActualFeeUpdated
                ? 0
                : convertToNumber(cpl.pricingDetail?.calculatedRemittance),
              ActualRetention: isReviseWithActualFeeUpdated
                ? 0
                : convertToNumber(cpl.pricingDetail?.actualRetention),
              CalculatedRetention: isReviseWithActualFeeUpdated
                ? 0
                : convertToNumber(cpl.pricingDetail?.calculatedRetention),
              ActualRiskRate: isReviseWithActualFeeUpdated
                ? 0
                : convertToNumber(cpl.pricingDetail?.actualRiskRate),
              CalculatedRiskRate: isReviseWithActualFeeUpdated
                ? 0
                : convertToNumber(cpl.pricingDetail?.calculatedRiskRate),

              // Location info
              LocationID: cpl.pricingDetail?.locationID || "",
              // LocationName: cpl.pricingDetail?.locationDisplayName || "", // will be dropped after backend drop it
              LocationDisplayName: cpl.pricingDetail?.locationDisplayName || "",
              LegacyID: cpl.pricingDetail?.legacyID || "",
              LocationUniqueID: cpl.pricingDetail?.locationUniqueID || "",
              LocationLegalName: cpl.pricingDetail?.locationLegalName || "",
              ContractID: cpl.pricingDetail?.contractID || "",
              UnderwriterTypeCode: cpl.pricingDetail?.underwriterTypeCode || "",
            },
          };

          if (cplproduct.BuyerBorrower && cplproduct.BuyerBorrower.length > 0) {
            setSequenceTypeCode(PartyRoleType.BuyerBorrower);
          }
          if (cplproduct.Seller && cplproduct.Seller.length > 0) {
            setSequenceTypeCode(PartyRoleType.Seller);
          }
          if (isCalculateRequested && cplproduct.PricingDetail) {
            cplproduct.PricingDetail.IsBilled = cpl.pricingDetail?.isBilled;
            cplproduct.PricingDetail.IsBillable = cpl.pricingDetail?.isBillable;
            cplproduct.PricingDetail.IsReadyToBeBilled = cpl.pricingDetail?.isReadyToBeBilled;
          }
          return cplproduct;
        });
    }

    return cplproducts;
  };

  const mapAal = (isCalculateRequested: boolean = false): Array<ApiModel.AALProduct> => {
    let aalproducts: Array<ApiModel.AALProduct> = [];

    const getProductAction = (aal: UIModel.AAL) => {
      let derivedProductAction = "";
      if (mapActionType === MapActionType.ProductAction) {
        //if(productAction) return productAction;
        if (
          integrationKeys &&
          integrationKeys?.indexOf(aal.integrationKey) >= 0
        ) {
          derivedProductAction = productAction
            ? productAction
            : ProductAction.Issue;
        }
      }

      return derivedProductAction;
    };

    if (scfile.aALProducts) {
      aalproducts = scfile.aALProducts
        .filter((aal: UIModel.AAL) => isValidLetter(aal))
        .map((aal) => {
          let orderParties: Array<ApiModel.OrderParty> = [];
          // let partyRoleTypeCodes: Array<string | undefined> = [];
          //let isAttorneySelected: boolean = false;

          let pendingChanges: boolean =
            aal.isDirty ||
              aal.orderStatusTypeCode === OrderStatusType.Pending ||
              aal.orderStatusTypeCode === OrderStatusType.Error
              ? true
              : false;

          // -- rlo 7/2/2021 - ONLY map additional party now -- other order parties will be mapped in back end
          // -- map additional party and other parties based on AAL's CoveredParty
          if (
            aal.orderStatusTypeCode !== OrderStatusType.Issued ||
            (aal.orderStatusTypeCode === OrderStatusType.Issued &&
              mapActionType === MapActionType.ProductAction &&
              productAction === ProductAction.Revise)
          ) {
            orderParties = getLetterOrderParties(
              ProductType.Aal,
              aal.orderID,
              aal.integrationKey,
              aal.addlParties,
              aal.orderParties,
              aal.coveredParty,
              pendingChanges,
              aal.fileID
            );
          }

          let isReviseWithActualFeeUpdated: boolean =
            productAction === ProductAction.Revise &&
            aal.pricingDetail?.actualFee !==
            aal.pricingDetail?.originalActualFee;

          //During issue and revise, the hasAddressedChanged property should
          //be set to true to avoid revised defaults from overwriting user
          //data on future revisions
          if (
            aal.addresseeNamesData &&
            (productAction === ProductAction.Issue ||
              productAction === ProductAction.Revise)
          )
            aal.addresseeNamesData.hasAddressedChanged = true;

          let aalproduct: ApiModel.AALProduct = {
            // keys
            IsDirty: pendingChanges,
            ProductID: aal.id || 0,
            IdentityKey: aal.identityKey || 0,
            ProductAction: getProductAction(aal),
            ProductTypeCode: ProductType.Aal,
            OrderID: aal.orderID,
            FileID: aal.fileID,
            TransactionTypeCode: scfile.transactionTypeCode,
            // cpl information
            ForeignCountry: aal.foreignCountry,
            ForeignState: aal.foreignState,
            IsForeignAddress: aal.isForeignAddress,
            ForeignAddressOne: aal.foreignAddressOne,
            ForeignAddressTwo: aal.foreignAddressTwo,
            ForeignCity: aal.foreignCity,

            OrderStatusTypeCode: aal.orderStatusTypeCode, // OrderStatusType.Pending, // temp -- will need to set in UIModel cpl object
            OrderStatusTypeName: aal.orderStatusTypeName,
            IssueDate: aal.issueDate || undefined,
            IssueDateTime:
              formatDateToJSONString(aal.issueDateTime) || undefined,
            IntegrationKey: aal.integrationKey,
            EffectiveDate: formatDateToJSONString(aal.effectiveDate, true),
            CoveredParty: aal.coveredParty,
            // PrintAttorneyOnly:
            //   isAttorneySelected && aal.printAttorneyOnly ? 1 : 0,
            ProductReferenceID: aal.productReferenceID,
            VoidReason: aal.void ? aal.void.reason : "",

            Form: aal.form,
            FormID: mapStringToSqlNull(
              ProductType.Aal,
              aal.identityKey || 0,
              aal.formID
            ),
            FormVersion: mapNumberToSqlNull(
              ProductType.Aal,
              aal.identityKey || 0,
              aal.formVersion
            ),
            FormPolicyPrefix: mapStringToSqlNull(
              ProductType.Aal,
              aal.identityKey || 0,
              aal.formPrefix
            ),
            FormPrefix: mapStringToSqlNull(
              ProductType.Aal,
              aal.identityKey || 0,
              aal.formPrefix
            ),
            FormTemplateName: mapStringToSqlNull(
              ProductType.Aal,
              aal.identityKey || 0,
              aal.formTemplateName
            ),
            IsAgencyExclusive: mapYNToSqlNull(
              ProductType.Aal,
              aal.identityKey || 0,
              aal.isAgencyExclusive
            ),
            OPAIdentifier: mapStringToSqlNull(
              ProductType.Aal,
              aal.identityKey || 0,
              aal.opaIdentifier
            ),
            LiabilityAmount: mapNumberToSqlNull(
              ProductType.Aal,
              aal.identityKey || 0,
              aal.liabilityAmount
            ),
            DocumentOrderData: mapDocumentOrderData(
              aal.documentOrderData,
              aal.formPrefix,
              aal.pricingDetail
            ),
            PricingDetail: {
              IntegrationKey: aal.pricingDetail?.integrationKey || uuidv4(),
              IsDirty: pendingChanges,
              OrderID: aal.orderID || 0,
              FileID: aal.fileID || scfile.id || 0,
              FilePricingID: aal.pricingDetail?.filePricingID || 0,
              FilePricingDetailID: aal.filePricingDetailID || 0,
              // Product: ProductType.Jacket,
              Liability: aal.pricingDetail?.liability
                ? parseFloat(
                  aal.pricingDetail?.liability
                    .toString()
                    .replace("$", "")
                    .replace(",", "")
                )
                : 0.0,

              // Map pricing amounts here
              ActualFee: convertToNumber(aal.pricingDetail?.actualFee),
              CalculatedFee: convertToNumber(aal.pricingDetail?.calculatedFee),
              ActualPremiumTax: convertToNumber(
                aal.pricingDetail?.actualPremiumTax
              ),
              CalculatedPremiumTax: convertToNumber(
                aal.pricingDetail?.calculatedPremiumTax
              ),
              ActualRemittance: isReviseWithActualFeeUpdated
                ? 0
                : convertToNumber(aal.pricingDetail?.actualRemittance),
              CalculatedRemittance: isReviseWithActualFeeUpdated
                ? 0
                : convertToNumber(aal.pricingDetail?.calculatedRemittance),
              ActualRetention: isReviseWithActualFeeUpdated
                ? 0
                : convertToNumber(aal.pricingDetail?.actualRetention),
              CalculatedRetention: isReviseWithActualFeeUpdated
                ? 0
                : convertToNumber(aal.pricingDetail?.calculatedRetention),
              ActualRiskRate: isReviseWithActualFeeUpdated
                ? 0
                : convertToNumber(aal.pricingDetail?.actualRiskRate),
              CalculatedRiskRate: isReviseWithActualFeeUpdated
                ? 0
                : convertToNumber(aal.pricingDetail?.calculatedRiskRate),
              IsFeeError: aal.pricingDetail?.isFeeError,
              // IsManualFee: aal.pricingDetail?.isManualFee,

              // Location info
              LocationID: aal.pricingDetail?.locationID || "",
              // LocationName: aal.pricingDetail?.locationDisplayName || "", // will be dropped after backend drop it
              LocationDisplayName: aal.pricingDetail?.locationDisplayName || "",
              LegacyID: aal.pricingDetail?.legacyID || "",
              LocationUniqueID: aal.pricingDetail?.locationUniqueID || "",
              LocationLegalName: aal.pricingDetail?.locationLegalName || "",
              ContractID: aal.pricingDetail?.contractID || "",
              UnderwriterTypeCode: aal.pricingDetail?.underwriterTypeCode || "",
            },
            Branch: aal.includeAllBranches ? "Include All Branches" : "None",

            // Other location info
            TerritoryID: mapStringToSqlNull(
              ProductType.Aal,
              aal.identityKey || 0,
              aal.territoryID
            ),
            ALTARegistryID: mapStringToSqlNull(
              ProductType.Aal,
              aal.identityKey || 0,
              aal.altaRegistryID
            ),

            // rlo 3/03/2022 - MUST SET in Order level too
            LocationID: aal.pricingDetail?.locationID || "",
            // LocationName: aal.pricingDetail?.locationDisplayName || "", // will be dropped after backend drop it
            LocationDisplayName: aal.pricingDetail?.locationDisplayName || "",
            LegacyID: aal.pricingDetail?.legacyID || "",
            LocationUniqueID: aal.pricingDetail?.locationUniqueID || "",
            LocationLegalName: aal.pricingDetail?.locationLegalName || "",
            ContractID: aal.pricingDetail?.contractID || "",
            UnderwriterTypeCode: aal.pricingDetail?.underwriterTypeCode || "",

            // Order Parties
            OrderParties: orderParties,
            //AddresseeNames: getAddresseeNameDisplay(aal.addresseeNamesData), // aal.addresseeNames ? aal.addresseeNames.join(", ") : "",
            AddresseeNames: aal.addresseeNames,
            AddresseeNameData: getAddresseeNameData(aal.addresseeNamesData),
            BuyerBorrower: aal.buyerBorrower,
            Seller: aal.seller,
            Address: aal.addressOne,
            Address2: aal.addressTwo,
            City: aal.city,
            StateCode: aal.state?.code,
            StateAbbr: aal.state?.abbr,
            Zip: aal.zipCode,
            Counsel: aal.counsel,
            LoanNumber: aal.loanNumber,
            SuccessorLanguage: aal.successorLanguage,
            AttentionTo: aal.attentionTo,
            DisplayOrder: aal.displayOrder,
          };

          if (aalproduct.BuyerBorrower && aalproduct.BuyerBorrower.length > 0) {
            setSequenceTypeCode(PartyRoleType.BuyerBorrower);
          }
          if (aalproduct.Seller && aalproduct.Seller.length > 0) {
            setSequenceTypeCode(PartyRoleType.Seller);
          }

          if (isCalculateRequested && aalproduct.PricingDetail) {
            aalproduct.PricingDetail.IsBilled = aal.pricingDetail?.isBilled;
            aalproduct.PricingDetail.IsBillable = aal.pricingDetail?.isBillable;
            aalproduct.PricingDetail.IsReadyToBeBilled = aal.pricingDetail?.isReadyToBeBilled;
          }

          return aalproduct;
        });
    }

    return aalproducts;
  };

  const mapJacket = (isCalculateRequested: boolean = false): Array<ApiModel.JacketProduct> => {
    let jacketproducts: Array<ApiModel.JacketProduct> = [];

    const getProductAction = (jacket: UIModel.Jacket) => {
      let productActionType = "";
      if (mapActionType === MapActionType.ProductAction) {
        if (
          integrationKeys &&
          integrationKeys?.indexOf(jacket.integrationKey || "") >= 0 &&
          (productType === ProductType.Jacket ||
            productType === ProductType.AllProducts)
        ) {
          productActionType = productAction || "";
        }
      }

      if (
        productActionType === ProductAction.Issue &&
        jacket.formType === JacketFormType.Owners
      ) {
        setBuyerBorrowerValidationRequired(jacket);
      }

      return productActionType;
    };

    const setBuyerBorrowerValidationRequired = (jacket: UIModel.Jacket) => {
      if (jacket.insuredNames && jacket.insuredNames.length === 0) return;
    };

    if (scfile.jackets) {
      jacketproducts = scfile.jackets
      .filter((jacket) => isValidJacket(jacket))
      .map((jacket) => {
        let orderParties: Array<ApiModel.OrderParty> = [];
        let pendingChanges: boolean =
          jacket.isDirty ||
          (integrationKeys &&
            integrationKeys?.indexOf(jacket.integrationKey || "") >= 0) ||
          // jacket.orderStatusTypeCode === OrderStatusType.Pending ||
          jacket.orderStatusTypeCode === OrderStatusType.Error ||
          (productType === ProductType.Endorsement &&
            productAction === ProductAction.Issue);

        let isReviseWithActualFeeUpdated: boolean =
          productAction === ProductAction.Revise &&
          jacket.pricingDetail?.actualFee !==
          jacket.pricingDetail?.originalActualFee;

        let lenderPartyCount: number = 0;
        allParties &&
          allParties.forEach((party) => {
            let mapParty: boolean = false;

            if (
              party.partyRoleTypeCode === PartyRoleType.Lender &&
              lenderPartyCount === 0 &&
              hasNotNullOrEmptyProp(party, ["companyName"])
            ) {
              lenderPartyCount = lenderPartyCount + 1;
              mapParty = true;
            } else if (
              party.partyRoleTypeCode === PartyRoleType.BuyerBorrower
            ) {
              if (
                party.partyTypeCode === PartyType.Individual &&
                hasNotNullOrEmptyProp(party, ["partyFirstName"]) &&
                hasNotNullOrEmptyProp(party, ["partyLastName"])
              ) {
                mapParty = true;
              } else if (
                party.partyTypeCode === PartyType.BusTrustEstate &&
                hasNotNullOrEmptyProp(party, ["companyName"])
              ) {
                mapParty = true;
              }
            }

            // if (party && isValidFileParty(party)) {
            if (mapParty) {
              let oParty = jacket.orderParties?.find(
                (op) => op.integrationKey === party.integrationKey
              );

              orderParties.push({
                IsDirty: productAction === ProductAction.Issue, //set to true only for issue
                IsPrimary: party.isPrimary ? 1 : 0,
                IntegrationKey: party.integrationKey,
                FileID: oParty?.fileID || 0,
                FilePartyID: oParty?.filePartyId || 0,
                FilePartyDataID: oParty?.filePartyDataID || 0,
                OrderPartyID: oParty?.orderPartyID || 0,
                OrderID: oParty?.orderID || 0,
                OrderPartyDataID: oParty?.orderPartyDataID || 0,
                PartyRoleTypeCode: party.partyRoleTypeCode,
                PartyTypeCode: party.partyTypeCode,
                SequenceTypeCode: party.sequenceTypeCode,
                PartyFirstName: party.partyFirstName,
                PartyMiddleName: party.partyMiddleName,
                PartyLastName: party.partyLastName,
                PartyCompanyName: party.companyName,
                PartyAdditionalName: party.individualAttorney,
                SuffixTypeCode: party.suffixType?.code,
                SuffixTypeName: party.suffixType?.name,
                Address: party.addressOne,
                Address2: party.addressTwo,
                City: party.city,
                StateCode: party.state?.code,
                StateAbbr: party.state?.abbr,
                CountryCodeNum: scfile.properties[0].country?.codeNum
                  ? scfile.properties[0].country.codeNum
                  : Country.USA,
                CountryCodeA2: party.country?.codeNum,
                CountryName: party.country?.name,
                Zip: party.zipCode,
                LoanNumber:
                  party.partyRoleTypeCode === PartyRoleType.Lender
                    ? party.loanNumber
                    : undefined,
                SuccessorLanguage:
                  party.partyRoleTypeCode === PartyRoleType.Lender
                    ? party.successorLanguage
                    : undefined,
                AttentionTo:
                  party.partyRoleTypeCode === PartyRoleType.Lender
                    ? party.attentionTo
                    : undefined,
              });
              //partyTypeCodes.push(party.partyTypeCode);
            }
          });

        //During issue and revise, the hasAddressedChanged property should
        //be set to true to avoid revised defaults from overwriting user
        //data on future revisions
        if (
          jacket.insuredNamesData &&
          (productAction === ProductAction.Issue ||
            productAction === ProductAction.Revise)
        ) {
          jacket.insuredNamesData.hasInsuredNamesChanged = true;
        }
        
        let jacketproduct: ApiModel.JacketProduct = {
          // keys
          IsDirty: false,
          IssueDate: jacket.issueDate,
          IssueDateTime: jacket.issueDateTimeStr,
          OrderStatusTypeName: jacket.orderStatusTypeName,
          IsDeleted: jacket.isDeleted,
          ProductID: jacket.id || 0,
          IdentityKey: jacket.identityKey || 0,
          ProductAction: getProductAction(jacket),
          ProductTypeCode: ProductType.Jacket,
          OrderID: jacket.orderID || 0,
          FileID: jacket.fileID || scfile.id,
          FilePricingDetailID: jacket.filePricingDetailID,
          InsuredNames: jacket.insuredNames,
          InsuredNamesData: JSON.stringify(jacket.insuredNamesData),
          TransactionTypeCode: scfile.transactionTypeCode,
          // jacket information
          OrderStatusTypeCode: jacket.orderStatusTypeCode, //
          DocumentOrderData: mapDocumentOrderData(
            jacket.documentOrderData,
            jacket.formPrefix,
            jacket.pricingDetail
          ),
          PricingDetail: {
            IntegrationKey: jacket.pricingDetail?.integrationKey || uuidv4(),
            IsDirty: pendingChanges,
            OrderID: jacket.orderID || 0,
            FileID: jacket.fileID || scfile.id || 0,
            FilePricingID: jacket.pricingDetail?.filePricingID || 0,
            FilePricingDetailID: jacket.filePricingDetailID || 0,
            PricingRateType: jacket.pricingDetail?.pricingRateType || "",
            PrevPolicyCoverageType: jacket.pricingDetail?.prevPolicyCoverageType || "",
            PricingRateTypeData: jacket.pricingDetail?.pricingRateTypeData || "",
            PricingTransactionTypeCode: jacket.pricingDetail?.pricingTransactionTypeCode || "",
            PricingFeeTypeCode: jacket.pricingDetail?.pricingFeeTypeCode || "",
            CoverageType: jacket.pricingDetail?.coverageType || "",
            CoverageTypeData: jacket.pricingDetail?.coverageTypeData || "",
            IsReissue: jacket.pricingDetail?.isReissue || false,
            IsSimultaneous: jacket.pricingDetail?.isSimultaneous || false,
            IsLeasehold: jacket.pricingDetail?.isLeasehold || false,
            TransCode: jacket.pricingDetail?.transCode,
            TransCodeDescription: jacket.pricingDetail?.transCodeDescription,
            // Product: ProductType.Jacket,
            Liability: jacket.pricingDetail?.liability
              ? parseFloat(
                  jacket.pricingDetail?.liability
                    .toString()
                    .replace("$", "")
                    .replace(",", "")
                )
              : 0.0,
            AltPricingReferenceID: jacket.pricingDetail?.altPricingReferenceID,
            LastErrorMessage: jacket.pricingDetail?.lastErrorMessage,
            LastDisplayMessage: jacket.pricingDetail?.lastDisplayMessage,
            IsFeeError: jacket.pricingDetail?.isFeeError,
            // IsManualFee: jacket.pricingDetail?.isManualFee,

            // Map pricing amounts here
            ActualFee: convertToNumber(jacket.pricingDetail?.actualFee),
            CalculatedFee: convertToNumber(jacket.pricingDetail?.calculatedFee),
            ActualPremiumTax: convertToNumber(
              jacket.pricingDetail?.actualPremiumTax
            ),
            CalculatedPremiumTax: convertToNumber(
              jacket.pricingDetail?.calculatedPremiumTax
            ),
            ActualRemittance: isReviseWithActualFeeUpdated
              ? 0
              : convertToNumber(jacket.pricingDetail?.actualRemittance),
            CalculatedRemittance: isReviseWithActualFeeUpdated
              ? 0
              : convertToNumber(jacket.pricingDetail?.calculatedRemittance),
            ActualRetention: isReviseWithActualFeeUpdated
              ? 0
              : convertToNumber(jacket.pricingDetail?.actualRetention),
            CalculatedRetention: isReviseWithActualFeeUpdated
              ? 0
              : convertToNumber(jacket.pricingDetail?.calculatedRetention),
            ActualRiskRate: isReviseWithActualFeeUpdated
              ? 0
              : convertToNumber(jacket.pricingDetail?.actualRiskRate),
            CalculatedRiskRate: isReviseWithActualFeeUpdated
              ? 0
              : convertToNumber(jacket.pricingDetail?.calculatedRiskRate),

            //CoverageType: jacket.coverageType ? jacket.coverageType : "",
            IsPrevPolicyLast10Yrs: jacket.pricingDetail?.isPrevPolicyLast10Yrs,

            // Location info
            LocationID: jacket.pricingDetail?.locationID || "",
            //LocationName: jacket.pricingDetail?.locationDisplayName || "",
            LocationDisplayName:
              jacket.pricingDetail?.locationDisplayName || "",
            LegacyID: jacket.pricingDetail?.legacyID || "",
            LocationUniqueID: jacket.pricingDetail?.locationUniqueID || "",
            LocationLegalName: jacket.pricingDetail?.locationLegalName || "",
            ContractID: jacket.pricingDetail?.contractID || "",
            UnderwriterTypeCode: jacket.pricingDetail?.underwriterTypeCode,
          },
          // SettlementDate: jacket.settlementDate
          //   ? jacket.settlementDate
          //   : new Date(),          
          UnderwriterTypeCode: jacket.underwriterTypeCode,
          //CoverageType: jacket.coverageType ? jacket.coverageType : "",  // rlo 11/9/2021 - it has been moved to inside PricingDetail
          OriginalJacketDate: jacket.originalJacketDate,
          OriginalJacketLiability: convertToNumber(
            jacket.originalJacketLiability
          ),
          OriginalJacketNumber: jacket.originalJacketNumber,
          OriginalJacketState: scfile.properties[0].state?.code,
          OriginalJacketType: jacket.originalJacketType,
          OriginalJacketUnderwriterTypeCode:
          jacket.originalJacketUnderwriterTypeCode,
          IntegrationKey: jacket.integrationKey,
          EffectiveDate: jacket.effectiveDate
            ? formatDateToJSONString(jacket.effectiveDate, true)
            : undefined,
          ProductReferenceID: jacket.productReferenceID,
          VoidReason: jacket.void ? jacket.void.reason : "",

          Form: jacket.form,
          FormType: jacket.formType,
          FormID: mapStringToSqlNull(
            ProductType.Jacket,
            jacket.identityKey || 0,
            jacket.formID
          ),
          FormVersion: mapNumberToSqlNull(
            ProductType.Jacket,
            jacket.identityKey || 0,
            jacket.formVersion
          ),
          FormPolicyPrefix: mapStringToSqlNull(
            ProductType.Jacket,
            jacket.identityKey || 0,
            jacket.formPrefix
          ),
          FormPrefix: mapStringToSqlNull(
            ProductType.Jacket,
            jacket.identityKey || 0,
            jacket.formPrefix
          ),
          FormTemplateName: mapStringToSqlNull(
            ProductType.Jacket,
            jacket.identityKey || 0,
            jacket.formTemplateName
          ),
          IsAgencyExclusive: mapYNToSqlNull(
            ProductType.Jacket,
            jacket.identityKey || 0,
            jacket.isAgencyExclusive
          ),
          OPAIdentifier: mapStringToSqlNull(
            ProductType.Jacket,
            jacket.identityKey || 0,
            jacket.opaIdentifier
          ),

          // Other location info
          TerritoryID: mapStringToSqlNull(
            ProductType.Jacket,
            jacket.identityKey || 0,
            jacket.territoryID
          ),
          ALTARegistryID: mapStringToSqlNull(
            ProductType.Jacket,
            jacket.identityKey || 0,
            jacket.altaRegistryID
          ),

          // rlo 3/03/2022 - MUST SET in Order level too
          LocationID: jacket.pricingDetail?.locationID || "",
          //LocationName: jacket.pricingDetail?.locationDisplayName || "",
          LocationDisplayName: jacket.pricingDetail?.locationDisplayName || "",
          LegacyID: jacket.pricingDetail?.legacyID || "",
          LocationUniqueID: jacket.pricingDetail?.locationUniqueID || "",
          LocationLegalName: jacket.pricingDetail?.locationLegalName || "",
          ContractID: jacket.pricingDetail?.contractID || "",

          Endorsements: mapEndorsementsToApiModel(
            jacket,
            productAction,
            isCalculateRequested
          ),
          JacketSignatures: mapJacketSignaturesToApiModel(jacket),
          JacketFormDetails: mapFormDetailsToApiModel(jacket),

          // Order Parties
          OrderParties: orderParties,
          DisplayOrder: jacket.displayOrder,
        };

        if (isCalculateRequested && jacketproduct.PricingDetail) {
          jacketproduct.PricingDetail.IsBilled = jacket.pricingDetail?.isBilled;
          jacketproduct.PricingDetail.IsBillable = jacket.pricingDetail?.isBillable;
          jacketproduct.PricingDetail.IsReadyToBeBilled = jacket.pricingDetail?.isReadyToBeBilled;
        }

        return jacketproduct;
      });
    }

    return jacketproducts;
  };

  const mapJacketOPN = (jackets?: Jacket[]): Array<ApiModel.JacketProduct> => {
    let mappedJakets: ApiModel.JacketProduct[] = [];
    jackets &&
      jackets
        ?.filter((jacket) => isValidJacket(jacket))
        .forEach((jacket) => {
          mappedJakets.push({
            IsDirty: true,
            ProductID: jacket.id || 0,
            IdentityKey: jacket.identityKey,
            ProductTypeCode: ProductType.Jacket,
            OrderStatusTypeCode: jacket.orderStatusTypeCode,
            FileID: jacket.fileID,
            OrderID: jacket.orderID,
            DisplayOrder: jacket.displayOrder,
            EffectiveDate: jacket.effectiveDate
              ? formatDateToJSONString(jacket.effectiveDate, true)
              : undefined,
            InsuredNames: jacket.insuredNames,
            InsuredNamesData: JSON.stringify(jacket.insuredNamesData),
            PricingDetail: {
              IsDirty: scfile.pricing.isIntegratedPricing,
              IsReissue: jacket.pricingDetail?.isReissue,
              IsFeeError: jacket.pricingDetail?.isFeeError,
              // IsManualFee: jacket.pricingDetail?.isManualFee,
              IntegrationKey: jacket.pricingDetail?.integrationKey || uuidv4(),
              OrderID: jacket.orderID || 0,
              FileID: jacket.fileID || 0,
              FilePricingID: jacket.pricingDetail?.filePricingID || 0,
              FilePricingDetailID: jacket.filePricingDetailID || 0,
              Liability: jacket.pricingDetail?.liability
                ? parseFloat(
                    jacket.pricingDetail?.liability
                      .toString()
                      .replace("$", "")
                      .replace(",", "")
                  )
                : 0.0,
              PrevPolicyCoverageType: jacket.pricingDetail?.prevPolicyCoverageType || "",
              IsPrevPolicyLast10Yrs: jacket.pricingDetail?.isPrevPolicyLast10Yrs,
              PricingRateType: jacket.pricingDetail?.pricingRateType || "",
              PricingRateTypeData: jacket.pricingDetail?.pricingRateTypeData || "",
              PricingTransactionTypeCode: jacket.pricingDetail?.pricingTransactionTypeCode || "",
              PricingFeeTypeCode: jacket.pricingDetail?.pricingFeeTypeCode || "",
              CoverageType: jacket.pricingDetail?.coverageType || "",
              CoverageTypeData: jacket.pricingDetail?.coverageTypeData || "",
              IsSimultaneous: jacket.pricingDetail?.isSimultaneous || false,
              IsLeasehold: jacket.pricingDetail?.isLeasehold || false,
              AltPricingReferenceID: jacket.pricingDetail?.altPricingReferenceID,

              // Map pricing amounts here
              ActualFee: convertToNumber(jacket.pricingDetail?.actualFee),
              CalculatedFee: convertToNumber(
                jacket.pricingDetail?.calculatedFee
              ),
              ActualPremiumTax: convertToNumber(
                jacket.pricingDetail?.actualPremiumTax
              ),
              CalculatedPremiumTax: convertToNumber(
                jacket.pricingDetail?.calculatedPremiumTax
              ),
              ActualRemittance: convertToNumber(
                jacket.pricingDetail?.actualRemittance
              ),
              CalculatedRemittance: convertToNumber(
                jacket.pricingDetail?.calculatedRemittance
              ),
              ActualRetention: convertToNumber(
                jacket.pricingDetail?.actualRetention
              ),
              CalculatedRetention: convertToNumber(
                jacket.pricingDetail?.calculatedRetention
              ),
              ActualRiskRate: convertToNumber(
                jacket.pricingDetail?.actualRiskRate
              ),
              CalculatedRiskRate: convertToNumber(
                jacket.pricingDetail?.calculatedRiskRate
              ),
              TransCode: jacket.pricingDetail?.transCode,
              TransCodeDescription: jacket.pricingDetail?.transCodeDescription,

              // Location info
              LocationID: jacket.pricingDetail?.locationID || "",
              LocationDisplayName:
                jacket.pricingDetail?.locationDisplayName || "",
              LegacyID: jacket.pricingDetail?.legacyID || "",
              LocationUniqueID: jacket.pricingDetail?.locationUniqueID || "",
              LocationLegalName: jacket.pricingDetail?.locationLegalName || "",
              ContractID: jacket.pricingDetail?.contractID || "",
            },
            OriginalJacketDate: jacket.inProcessOPN?.originalJacketDate,
            OriginalJacketType: jacket.inProcessOPN?.originalJacketType,
            OriginalJacketNumber: jacket.inProcessOPN?.originalJacketNumber,
            OriginalJacketLiability: convertToNumber(
              jacket.inProcessOPN?.originalJacketLiability
            ),
            OriginalJacketState: scfile.properties[0].state?.code,
            IntegrationKey: jacket.integrationKey,
            Form: jacket.form,
            FormType: jacket.formType,
            Endorsements: mapEndorsementsToApiModel(
              jacket,
              productAction,
              false,
              true
            ),
            JacketFormDetails: mapFormDetailsToApiModel(jacket),
            JacketSignatures: mapJacketSignaturesToApiModel(jacket),

            // rlo 8/25/2022
            LocationID: jacket.pricingDetail?.locationID || "",
            LocationDisplayName: jacket.pricingDetail?.locationDisplayName || "",
            LegacyID: jacket.pricingDetail?.legacyID || "",
            LocationUniqueID: jacket.pricingDetail?.locationUniqueID || "",
            LocationLegalName: jacket.pricingDetail?.locationLegalName || "",
            ContractID: jacket.pricingDetail?.contractID || "",
          });
        });

    return mappedJakets;
  };

  const mapStandalone = (isCalculateRequested: boolean = false): Array<ApiModel.StandaloneEndorsementProduct> => {
    let saproducts: Array<ApiModel.StandaloneEndorsementProduct> = [];

    const getProductAction = (standalone: UIModel.StandaloneEndorsement) => {
      let productActionType = "";
      if (mapActionType === MapActionType.ProductAction) {
        if (
          integrationKeys &&
          integrationKeys?.indexOf(standalone.integrationKey) > -1 &&
          standalone.orderStatusTypeCode !== OrderStatusType.Issued &&
          standalone.orderStatusTypeCode !== OrderStatusType.Voided
        ) {
          productActionType = productAction!;
        }
      }
      return productActionType;
    };

    if (scfile.standaloneEndorsements) {
      saproducts = scfile.standaloneEndorsements
        .filter(
          (standalone) =>
            isValidStandalone(standalone) ||
            mapActionType === MapActionType.SaveFile
        )
        .map((standalone) => {
          let pendingChanges: boolean =
            standalone.isDirty ||
            (integrationKeys &&
              integrationKeys?.indexOf(standalone.integrationKey) >= 0) ||
            standalone.orderStatusTypeCode === OrderStatusType.Pending ||
            standalone.orderStatusTypeCode === OrderStatusType.Error;

          let saproduct: ApiModel.StandaloneEndorsementProduct = {
            // Product Action related
            ProductAction: getProductAction(standalone),
            OrderStatusTypeCode: standalone.orderStatusTypeCode,
            ProductTypeCode: ProductType.StandaloneEndorsement,
            TransactionTypeCode: scfile.transactionTypeCode,
            // Product keys
            IsDirty: pendingChanges,
            IsDeleted: standalone.isDeleted,
            IntegrationKey: standalone.integrationKey,
            ProductID: standalone.id || 0,
            IdentityKey: standalone.identityKey || 0,
            OrderID: standalone.orderID || 0,
            FileID: standalone.fileID || scfile.id || 0,
            FilePricingDetailID: standalone.filePricingDetailID || 0,

            // Other information
            OriginalJacketNumber: standalone.originalJacketNumber,
            OriginalJacketDate: standalone.originalJacketDate
              ? formatDateToJSONString(standalone.originalJacketDate, true)
              : null,
            OriginalJacketType: standalone.originalJacketType,

            OriginalJacketLiability: convertToNumber(
              standalone.originalJacketLiability
            ),
            OriginalJacketUnderwriterTypeCode: "Other", // getUnderwriterTypeCode(),
            OriginalJacketState: scfile.properties[0].state?.code,
            DisplayOrder: standalone.displayOrder,

            // Endorsements
            Endorsements: mapStandaloneEndorsements(standalone, productAction!, isCalculateRequested),

            PricingDetail: {
              IsDirty: true,
              IntegrationKey:
                standalone.pricingDetail?.integrationKey || uuidv4(),
              OrderID: standalone.orderID || 0,
              FileID: standalone.fileID || 0,
              FilePricingID: standalone.pricingDetail?.filePricingID || 0,
              FilePricingDetailID: standalone.filePricingDetailID || 0,

              // Location info
              LocationID: standalone.pricingDetail?.locationID || "",
              //LocationName: standalone.pricingDetail?.locationDisplayName || "",
              LocationDisplayName:
                standalone.pricingDetail?.locationDisplayName || "",
              LegacyID: standalone.pricingDetail?.legacyID || "",
              LocationUniqueID:
                standalone.pricingDetail?.locationUniqueID || "",
              LocationLegalName:
                standalone.pricingDetail?.locationLegalName || "",
              ContractID: standalone.pricingDetail?.contractID || "",
              UnderwriterTypeCode: standalone.pricingDetail?.underwriterTypeCode,
            },

            // Other Location info
            TerritoryID: mapStringToSqlNull(
              ProductType.StandaloneEndorsement,
              standalone.identityKey || 0,
              standalone.territoryID
            ),
            ALTARegistryID: mapStringToSqlNull(
              ProductType.StandaloneEndorsement,
              standalone.identityKey || 0,
              standalone.altaRegistryID
            ),

            // rlo 2/16/2022 - temporary required to set LocationID and LocationName until DB's team fixed it
            LocationID: standalone.pricingDetail?.locationID || "",
            //LocationName: standalone.pricingDetail?.locationDisplayName || "",
            LocationDisplayName:
              standalone.pricingDetail?.locationDisplayName || "",
            LegacyID: standalone.pricingDetail?.legacyID || "",
            LocationUniqueID: standalone.pricingDetail?.locationUniqueID || "",
            LocationLegalName:
              standalone.pricingDetail?.locationLegalName || "",
            ContractID: standalone.pricingDetail?.contractID || "",
            UnderwriterTypeCode: standalone.underwriterTypeCode,
          };

          if (isCalculateRequested && saproduct.PricingDetail) {
            saproduct.PricingDetail.IsBilled = standalone.pricingDetail?.isBilled;
            saproduct.PricingDetail.IsBillable = standalone.pricingDetail?.isBillable;
            saproduct.PricingDetail.IsReadyToBeBilled = standalone.pricingDetail?.isReadyToBeBilled;
          }

          return saproduct;
        });
    }

    return saproducts;
  };

  function getEndorsementProductAction(
    e: UIModel.Endorsement,
    productAction: string
  ) {
    if (isIssuableEndorsement(e)) return productAction;

    if (
      productAction === ProductAction.Void &&
      integrationKeys?.includes(e.integrationKey)
    )
      return ProductAction.Void;

    return "";
  }

  const mapStandaloneEndorsements = (
    standalone: UIModel.StandaloneEndorsement,
    productAction: string,
    isCalculateRequested: boolean = false
  ) => {    
    let apiEndorsements: ApiModel.Endorsement[] = [];
    if (standalone.endorsements) {
      apiEndorsements = standalone.endorsements
        ?.filter((e) => isValidEndorsement(e))
        ?.map((e) => {
          let apiEndorsement: ApiModel.Endorsement = {
            ProductAction: getEndorsementProductAction(e, productAction),
            IsDirty: isIssuableEndorsement(e) ? true : false,

            IdentityKey: standalone.identityKey,
            OrderID: standalone.orderID || 0,
            FileID: standalone.fileID || scfile.id || 0,

            // Location info
            ContractID: standalone.pricingDetail?.contractID || "",
            LegacyID: standalone.pricingDetail?.legacyID || "",
            LocationID: standalone.pricingDetail?.locationID || "",
            //LocationName: standalone.pricingDetail?.locationDisplayName || "",
            LocationDisplayName:
              standalone.pricingDetail?.locationDisplayName || "",
            LocationLegalName:
              standalone.pricingDetail?.locationLegalName || "",
            LocationUniqueID: standalone.pricingDetail?.locationUniqueID || "",
            UnderwriterTypeCode: standalone.pricingDetail?.underwriterTypeCode || standalone.underwriterTypeCode || "",

            EndorsementID: e.endorsementID || 0,
            ProductItemID: e.productItemID,
            EndorsementName: e.endorsementName,
            EndorsementNumber: e.endorsementNumber,
            AltEndorsementID: mapStringToSqlNull(
              ProductType.StandaloneEndorsement,
              e.identityKey || 0,
              e.altEndorsementID
            ),
            EndorsementVersion: mapNumberToSqlNull(
              ProductType.StandaloneEndorsement,
              e.identityKey || 0,
              e.endorsementVersion
            ),
            OPAIdentifier: mapStringToSqlNull(
              ProductType.StandaloneEndorsement,
              e.identityKey || 0,
              e.opaIdentifier
            ),
            IsIssuedAfterJacket: mapStringToSqlNull(
              ProductType.StandaloneEndorsement,
              e.identityKey || 0,
              e.isIssuedAfterJacket
            ),
            EffectiveDate: e.effectiveDate
              ? formatDateToJSONString(e.effectiveDate, true)
              : null,
            EndorsementStatusTypeCode:
              e.endorsementStatusTypeCode || EndorsementStatusType.Pending,
            IssueDate: e.issueDate ? e.issueDate : undefined,
            RevisionDate: e.revisionDate ? e.revisionDate : undefined,
            VoidedDate: e.voidedDate ? e.voidedDate : undefined,
            IntegrationKey: e.integrationKey,
            IsSFE: e.isSFE ? 1 : 0,
            FilePricingDetailID: e.filePricingDetailID || 0,
            PricingDetail: {
              IsDirty: true,
              IntegrationKey: e.integrationKey || uuidv4(),
              OrderID: standalone.orderID || 0,
              FileID: standalone.fileID || scfile.id || 0,
              FilePricingID: e.pricingDetail?.filePricingID || 0,
              FilePricingDetailID: e.filePricingDetailID || 0,
              Liability: 0.0,

              // Need to map pricing amounts here, otherwise it wil be set to 0 when void the endorsement
              ActualFee: convertToNumber(e.pricingDetail?.actualFee),
              CalculatedFee: convertToNumber(e.pricingDetail?.calculatedFee),
              ActualPremiumTax: convertToNumber(
                e.pricingDetail?.actualPremiumTax
              ),
              CalculatedPremiumTax: convertToNumber(
                e.pricingDetail?.calculatedPremiumTax
              ),
              ActualRiskRate: convertToNumber(e.pricingDetail?.actualRiskRate),
              CalculatedRiskRate: convertToNumber(
                e.pricingDetail?.calculatedRiskRate
              ),
              ActualRemittance: convertToNumber(
                e.pricingDetail?.actualRemittance
              ),
              CalculatedRemittance: convertToNumber(
                e.pricingDetail?.calculatedRemittance
              ),
              ActualRetention: convertToNumber(
                e.pricingDetail?.actualRetention
              ),
              CalculatedRetention: convertToNumber(
                e.pricingDetail?.calculatedRetention
              ),
              IsFeeError: e.pricingDetail?.isFeeError,
              IsManualFee: e.pricingDetail?.isManualFee,

              // Location info
              ContractID: standalone.pricingDetail?.contractID || "",
              LegacyID: standalone.pricingDetail?.legacyID || "",
              LocationID: standalone.pricingDetail?.locationID || "",
              //LocationName: standalone.pricingDetail?.locationDisplayName || "",
              LocationDisplayName:
                standalone.pricingDetail?.locationDisplayName || "",
              LocationLegalName:
                standalone.pricingDetail?.locationLegalName || "",
              LocationUniqueID:
                standalone.pricingDetail?.locationUniqueID || "",
              UnderwriterTypeCode: standalone.pricingDetail?.underwriterTypeCode || "",
              TransCode: e.pricingDetail?.transCode,
              TransCodeDescription: e.pricingDetail?.transCodeDescription,
            },
          };

          if (isCalculateRequested && apiEndorsement.PricingDetail) {
            apiEndorsement.PricingDetail.IsBilled = standalone.pricingDetail?.isBilled;
            apiEndorsement.PricingDetail.IsBillable = standalone.pricingDetail?.isBillable;
            apiEndorsement.PricingDetail.IsReadyToBeBilled = standalone.pricingDetail?.isReadyToBeBilled;
          }


          return apiEndorsement;
        });
    }
    return apiEndorsements;
  };

  const mapFormDetailsToApiModel = (jacket: UIModel.Jacket) => {
    const formDetails = jacket.formDetails || [];

    const formDetailsToSave: ApiModel.JacketFormDetail[] = formDetails
      .filter((detail) =>
        isPremiumDetail(detail.formDetailName) ? !jacket.excludePremium : true
      )
      .map((fd) => ({
        IsDirty: true,
        FormID: mapStringToSqlNull(
          ProductType.Jacket,
          fd.identityKey || 0,
          fd.formID
        ),
        FormDetailID: mapStringToSqlNull(
          ProductType.Jacket,
          fd.identityKey || 0,
          fd.formDetailID
        ),
        FormDetailName: getFormDetailTranslatedName(
          fd.formDetailName,
          translations
        ),
        FormDetailType: fd.formDetailType,
        FormDetailValue: getFormDetailValue(fd, jacket.newPremiumAmount),
        JacketFormDetailID: fd.jacketFormDetailID || 0,
        FilePricingDetailID: fd.filePricingDetailID || 0,
        IntegrationKey: fd.integrationKey,
        FileID: fd.fileID || jacket.fileID,
        OrderID: fd.orderID || jacket.orderID,
        ProductItemID: fd.productItemID,
      }));

    return formDetailsToSave || [];
  };

  const mapJacketSignaturesToApiModel = (jacket: UIModel.Jacket) => {
    let signatures: ApiModel.JacketSignature[] = [];
    if (jacket.jacketSignatures?.length) {
      // All but the first signature on a jacket are currently ignored.
      const firstSignature = jacket.jacketSignatures[0];

      if (firstSignature) {
        // The INT_MAX sentinel value for SignatureImageID indicates it should perform a default lookup.
        // This value will replace of value of 0 which is needed for UI purposes.
        const signatureImageID = firstSignature.signatureImageID || INT_MAX;

        // Select is used in the UI, but needs to be renamed if used.
        const signatureImageName =
          firstSignature.signatureImageName !== "Select"
            ? firstSignature.signatureImageName
            : "Signature - 0";

        signatures = [
          {
            IsDirty: firstSignature.isDirty,
            IdentityKey: firstSignature.identityKey,
            IntegrationKey: firstSignature.integrationKey,
            JacketSignatureID: firstSignature.jacketSignatureID || 0,
            ProductItemID: firstSignature.productItemID,
            OrderID: firstSignature.orderID || jacket.orderID,
            FileID: firstSignature.fileID || jacket.fileID,
            FilePricingDetailID: firstSignature.filePricingDetailID || 0,
            SignatureTypeCode: firstSignature.signatureTypeCode || "AUTHORIZEDCOUNTERSIGNATURE",
            SignatureImageData: firstSignature.signatureImageData || "",
            SignatureImageFormatTypeCode: firstSignature.signatureImageFormatTypeCode || "",
            SignatureImageID: signatureImageID,
            SignatureImageName: signatureImageName,
            FPDIdentityKey: firstSignature.fpdIdentityKey || 0,
          },
        ];
      }
    }

    return signatures;
  };

  const getFormDetailValue = (
    {
      formDetailName = "",
      formDetailValue,
      formDetailType,
    }: UIModel.JacketFormDetail,
    premiumAmount: number
  ): string | undefined => {
    if (isPremiumDetail(formDetailName)) {
      return premiumAmount?.toString();
    }

    const parseBooleanValue = (): string | undefined => {
      return formDetailValue === "Yes"
        ? "Y"
        : formDetailValue === "No"
          ? "N"
          : formDetailValue;
    };

    const parseIntegerValue = (value?: string): string | undefined => {
      return value === "$0.00" || value === "0.00"
        ? ""
        : value?.includes("$")
          ? value.replace("$", "")
          : value;
    };

    switch (formDetailType) {
      case "Boolean":
        return parseBooleanValue();
      case "Integer":
        return parseIntegerValue(formDetailValue);
      default:
        return formDetailValue;
    }
  };

  const isPremiumDetail = (name: string = "") =>
    name === "Premium" || name === "Premium (for Sch A only)";

  const mapEndorsementsToApiModel = (
    jacket: UIModel.Jacket,
    action?: string,
    isCalculateRequested: boolean = false,
    isSaveJacketOPN: boolean = false,
  ) => {
    const endorsements = jacket.endorsements || [];
    const endorsementsToSave: ApiModel.Endorsement[] = endorsements
      .filter((e) => isValidEndorsement(e))
      .map((e) => ({
        IsDirty: isSaveJacketOPN ? true : e.isDirty,
        IdentityKey: e.identityKey,
        EndorsementID: e.endorsementID || 0,
        ProductItemID: e.productItemID,

        // Location info
        ContractID: jacket.pricingDetail?.contractID || "",
        LegacyID: jacket.pricingDetail?.legacyID || "",
        LocationID: jacket.pricingDetail?.locationID || "",
        //LocationName: jacket.pricingDetail?.locationDisplayName || "",
        LocationDisplayName: jacket.pricingDetail?.locationDisplayName || "",
        LocationLegalName: jacket.pricingDetail?.locationLegalName || "",
        LocationUniqueID: jacket.pricingDetail?.locationUniqueID || "",
        UnderwriterTypeCode: jacket.pricingDetail?.underwriterTypeCode || jacket.underwriterTypeCode || "",

        EndorsementName: e.endorsementName,
        EndorsementNumber: e.endorsementNumber,
        AltEndorsementID: mapStringToSqlNull(
          ProductType.Jacket,
          e.identityKey || 0,
          e.altEndorsementID
        ),
        EndorsementVersion: mapNumberToSqlNull(
          ProductType.Jacket,
          e.identityKey || 0,
          e.endorsementVersion
        ),
        OPAIdentifier: mapStringToSqlNull(
          ProductType.Jacket,
          e.identityKey || 0,
          e.opaIdentifier
        ),
        IsIssuedAfterJacket: mapStringToSqlNull(
          ProductType.Jacket,
          e.identityKey || 0,
          e.isIssuedAfterJacket
        ),
        EffectiveDate: e.effectiveDate
          ? formatDateToJSONString(e.effectiveDate, true)
          : null,
        EndorsementStatusTypeCode: e.endorsementStatusTypeCode || "",
        IssueDate: e.issueDate ? e.issueDate : undefined,
        RevisionDate: e.revisionDate ? e.revisionDate : undefined,
        VoidedDate: e.voidedDate ? e.voidedDate : undefined,
        IntegrationKey: e.integrationKey,
        IsSFE: e.isSFE ? 1 : 0,
        FilePricingDetailID: e.filePricingDetailID || 0,
        ProductAction: isIssuableEndorsement(e)
          ? action
          : action === ProductAction.Void &&
            integrationKeys?.includes(e.integrationKey)
            ? ProductAction.Void
            : "",
        // !e.endorsementID ||
        // (!!e.endorsementStatusTypeCode &&
        //   e.endorsementStatusTypeCode === OrderStatusType.Pending)
        //   ? action
        //   : "",
        OrderID: jacket.orderID || 0,
        FileID: jacket.fileID || scfile.id || 0,
        IssueDateTime: formatDateToJSONString(e.issueDate) || undefined,
        PricingDetail: {
          IsDirty: true,
          IntegrationKey: e.integrationKey || uuidv4(),
          OrderID: jacket.orderID || 0,
          FileID: jacket.fileID || scfile.id || 0,
          FilePricingID: e.pricingDetail?.filePricingID || 0,
          FilePricingDetailID: e.filePricingDetailID || 0,
          Liability: 0.0,
          TransCode: e.pricingDetail?.transCode,
          TransCodeDescription: e.pricingDetail?.transCodeDescription,
          AltPricingReferenceID: e.pricingDetail?.altPricingReferenceID,
          PricingRateType: e.pricingDetail?.pricingRateType || "",
          PricingRateTypeData: e.pricingDetail?.pricingRateTypeData || "",
          PricingTransactionTypeCode:
            jacket.pricingDetail?.pricingTransactionTypeCode || "",
          PricingFeeTypeCode: jacket.pricingDetail?.pricingFeeTypeCode || "",

          // Need to map pricing amounts here, otherwise it wil be set to 0 when void the endorsement
          ActualFee: convertToNumber(e.pricingDetail?.actualFee),
          CalculatedFee: convertToNumber(e.pricingDetail?.calculatedFee),
          ActualPremiumTax: convertToNumber(e.pricingDetail?.actualPremiumTax),
          CalculatedPremiumTax: convertToNumber(
            e.pricingDetail?.calculatedPremiumTax
          ),
          ActualRiskRate: convertToNumber(e.pricingDetail?.actualRiskRate),
          CalculatedRiskRate: convertToNumber(
            e.pricingDetail?.calculatedRiskRate
          ),
          ActualRemittance: convertToNumber(e.pricingDetail?.actualRemittance),
          CalculatedRemittance: convertToNumber(
            e.pricingDetail?.calculatedRemittance
          ),
          ActualRetention: convertToNumber(e.pricingDetail?.actualRetention),
          CalculatedRetention: convertToNumber(
            e.pricingDetail?.calculatedRetention
          ),
          LastErrorMessage: e.pricingDetail?.lastErrorMessage || "",
          LastDisplayMessage: e.pricingDetail?.lastDisplayMessage || "",
          IsFeeError: e.pricingDetail?.isFeeError,
          IsManualFee: e.pricingDetail?.isManualFee,

          // Location info
          ContractID: jacket.pricingDetail?.contractID || "",
          LegacyID: jacket.pricingDetail?.legacyID || "",
          LocationID: jacket.pricingDetail?.locationID || "",
          //LocationName: jacket.pricingDetail?.locationDisplayName || "",
          LocationDisplayName: jacket.pricingDetail?.locationDisplayName || "",
          LocationLegalName: jacket.pricingDetail?.locationLegalName || "",
          LocationUniqueID: jacket.pricingDetail?.locationUniqueID || "",
          UnderwriterTypeCode: jacket.pricingDetail?.underwriterTypeCode,

          //Map only for Calculate
          IsBillable: isCalculateRequested ? e.pricingDetail?.isBillable : undefined,
          IsReadyToBeBilled: isCalculateRequested ? e.pricingDetail?.isReadyToBeBilled : undefined,
          IsBilled: isCalculateRequested ? e.pricingDetail?.isBilled : undefined,
        },
      }));

    return endorsementsToSave;
  };

  // const mapPricing = (
  //   isReporting: boolean = false,
  //   isCalculateRequested: boolean = false
  // ): Array<ApiModel.FilePricingDetail> => {
  //   let filePricingDetails: Array<ApiModel.FilePricingDetail> = [];
  //   // Update Original UiModel FilePricingDetails based on the updated values from pricingProducts
  //   scfile.pricingProducts
  //     ?.filter((p) => p.pricingType === PricingType.Product)
  //     ?.forEach((newPricing) => {
  //       // pricing header (jackets or standalone endorsements)
  //       let originalPricing = scfile.filePricingDetails.find(
  //         (op) => op.id === newPricing.filePricingDetailId
  //       );
  //       if (originalPricing) {
  //         if (
  //           !(
  //             originalPricing.actualFee ===
  //             convertToNumber(newPricing.actualFee) &&
  //             originalPricing.actualRiskRate ===
  //             convertToNumber(newPricing.actualRiskRate) &&
  //             originalPricing.actualRetention ===
  //             convertToNumber(newPricing.agentRetention) &&
  //             originalPricing.actualRemittance ===
  //             convertToNumber(newPricing.totalDue) &&
  //             originalPricing.actualPremiumTax ===
  //             convertToNumber(newPricing.actualPremiumTax) &&
  //             originalPricing.pricingRateType === newPricing.pricingRateType &&
  //             Boolean(originalPricing.isReissue) ===
  //             Boolean(newPricing.isReissue)
  //           ) ||
  //           (isReporting &&
  //             newPricing.isReadyToBeBilled === 1 &&
  //             newPricing.isBilled === 0)
  //           //newPricing.actualFee !== 0)
  //         ) {
  //           //originalPricing.isReadyToBeBilled = 1; // Robun 03/28/2022 - if it's set to 1, the  middleware will send REVISE action to Stewart Access
  //           originalPricing.actualFee = convertToNumber(newPricing.actualFee);
  //           originalPricing.actualPremiumTax = convertToNumber(
  //             newPricing.actualPremiumTax
  //           );
  //           originalPricing.calculatedPremiumTax = convertToNumber(
  //             newPricing.calculatedPremiumTax
  //           );
  //           originalPricing.calculatedFee = convertToNumber(
  //             newPricing.calculatedFee
  //           );
  //           originalPricing.actualRiskRate = convertToNumber(
  //             newPricing.actualRiskRate
  //           );
  //           originalPricing.calculatedRiskRate = convertToNumber(
  //             newPricing.calculatedRiskRate
  //           );
  //           originalPricing.actualRetention = convertToNumber(
  //             newPricing.agentRetention
  //           );
  //           originalPricing.calculatedRetention = convertToNumber(
  //             newPricing.calculatedRetention
  //           );
  //           originalPricing.actualRemittance = convertToNumber(
  //             newPricing.totalDue
  //           );
  //           originalPricing.calculatedRemittance = convertToNumber(
  //             newPricing.calculatedTotalDue
  //           );
  //           originalPricing.pricingRateType = newPricing.pricingRateType;
  //           originalPricing.pricingRateTypeData =
  //             newPricing.pricingRateTypeData;
  //           originalPricing.pricingTransactionTypeCode =
  //             newPricing.pricingTransactionTypeCode;
  //           originalPricing.pricingFeeTypeCode = newPricing.pricingFeeTypeCode;
  //           originalPricing.coverageType = newPricing.coverageType;
  //           originalPricing.coverageTypeData = newPricing.coverageTypeData;
  //           originalPricing.isReissue = newPricing.isReissue;
  //           originalPricing.altPricingReferenceID =
  //             newPricing.altPricingReferenceID;
  //           originalPricing.lastErrorMessage = newPricing.lastErrorMessage;
  //           originalPricing.isFeeError = newPricing.isFeeError;
  //           originalPricing.isManualFee = newPricing.isManualFee;
  //         }
  //         originalPricing.lastBilledDate = newPricing.lastBilledDate;
  //         if (isCalculateRequested) {
  //           originalPricing.isBilled = newPricing.isBilled;
  //           originalPricing.isBillable = newPricing.isBillable;
  //           originalPricing.isReadyToBeBilled = newPricing.isReadyToBeBilled;
  //         }
  //       }

  //       // pricing items (endorsement)
  //       newPricing.productItems?.forEach(
  //         (itemNewPricing: PricingProductItem) => {
  //           let originalPricing = scfile.filePricingDetails.find(
  //             (op) => op.id === itemNewPricing.filePricingDetailId
  //           );
  //           if (originalPricing) {
  //             if (
  //               !(
  //                 originalPricing.actualFee ===
  //                 convertToNumber(itemNewPricing.actualFee) &&
  //                 originalPricing.actualRiskRate ===
  //                 convertToNumber(itemNewPricing.actualRiskRate) &&
  //                 originalPricing.actualRetention ===
  //                 convertToNumber(itemNewPricing.agentRetention) &&
  //                 originalPricing.actualRemittance ===
  //                 convertToNumber(itemNewPricing.totalDue) &&
  //                 originalPricing.actualPremiumTax ===
  //                 convertToNumber(itemNewPricing.actualPremiumTax)
  //               ) ||
  //               (isReporting &&
  //                 itemNewPricing.isReadyToBeBilled === 1 &&
  //                 itemNewPricing.isBilled === 0)
  //             ) {
  //               originalPricing.actualFee = convertToNumber(
  //                 itemNewPricing.actualFee
  //               );
  //               originalPricing.calculatedFee = convertToNumber(
  //                 itemNewPricing.calculatedFee
  //               );
  //               originalPricing.actualRiskRate = convertToNumber(
  //                 itemNewPricing.actualRiskRate
  //               );
  //               originalPricing.calculatedRiskRate = convertToNumber(
  //                 itemNewPricing.calculatedRiskRate
  //               );
  //               originalPricing.actualRetention = convertToNumber(
  //                 itemNewPricing.agentRetention
  //               );
  //               originalPricing.calculatedRetention = convertToNumber(
  //                 itemNewPricing.calculatedRetention
  //               );

  //               originalPricing.actualPremiumTax = convertToNumber(
  //                 itemNewPricing.actualPremiumTax
  //               );
  //               originalPricing.calculatedPremiumTax = convertToNumber(
  //                 itemNewPricing.calculatedPremiumTax
  //               );

  //               originalPricing.actualRemittance = convertToNumber(
  //                 itemNewPricing.totalDue
  //               );
  //               originalPricing.calculatedRemittance = convertToNumber(
  //                 itemNewPricing.calculatedTotalDue
  //               );
  //               originalPricing.altPricingReferenceID =
  //                 itemNewPricing.altPricingReferenceID;
  //               originalPricing.lastErrorMessage =
  //                 itemNewPricing.lastErrorMessage;
  //               originalPricing.isFeeError = itemNewPricing.isFeeError;
  //               originalPricing.isManualFee = itemNewPricing.isManualFee;

  //               if (isCalculateRequested) {
  //                 originalPricing.isReadyToBeBilled = itemNewPricing.isReadyToBeBilled;
  //                 originalPricing.isBillable = itemNewPricing.isBillable;
  //                 originalPricing.isBilled = itemNewPricing.isBilled;
  //               }
  //             }
  //           }
  //         }
  //       );

  //       // if (scfile.pricing.isIntegratedPricing) {
  //         const matchingAPIJacket = apiscfile.JacketProducts?.find(
  //           (j) => j.FilePricingDetailID === newPricing.filePricingDetailId
  //         );
  //         if (matchingAPIJacket) {
  //           matchingAPIJacket.OriginalJacketDate =
  //             newPricing.originalPolicy?.date;
  //           matchingAPIJacket.OriginalJacketLiability = convertToNumber(
  //             newPricing.originalPolicy?.liabilityAmount
  //           );
  //           if (matchingAPIJacket.PricingDetail) {
  //             matchingAPIJacket.PricingDetail.IsReissue = newPricing.isReissue;
  //             matchingAPIJacket.PricingDetail.IsPrevPolicyLast10Yrs =
  //               newPricing.originalPolicy?.previouslyIssued;
  //             matchingAPIJacket.PricingDetail.PrevPolicyCoverageType =
  //               newPricing.originalPolicy?.coverageType;
  //             matchingAPIJacket.PricingDetail.ActualPremiumTax =
  //               newPricing.actualPremiumTax;
  //             matchingAPIJacket.PricingDetail.CalculatedPremiumTax =
  //               newPricing.calculatedPremiumTax;
  //           }
  //         }

  //         const matchingSCJacket = scfile.jackets?.find(
  //           (j) => j.filePricingDetailID === newPricing.filePricingDetailId
  //         );
  //         if (matchingSCJacket) {
  //           matchingSCJacket.originalJacketDate =
  //             newPricing.originalPolicy?.date;
  //           matchingSCJacket.originalJacketLiability =
  //             newPricing.originalPolicy?.liabilityAmount;
  //           if (matchingSCJacket.pricingDetail) {
  //             matchingSCJacket.pricingDetail.isReissue = newPricing.isReissue;
  //             matchingSCJacket.pricingDetail.isPrevPolicyLast10Yrs =
  //               newPricing.originalPolicy?.previouslyIssued;
  //             matchingSCJacket.pricingDetail.prevPolicyCoverageType =
  //               newPricing.coverageType;
  //           }
  //         }
  //       // }
  //     });

  //   // Map to ApiModel FilePricingDetails
  //   scfile.filePricingDetails?.forEach((op) => {
  //     filePricingDetails.push({
  //       ActualFee: op.actualFee,
  //       ActualPremiumTax: op.actualPremiumTax,
  //       ActualRemittance: op.actualRemittance,
  //       ActualRetention: op.actualRetention,
  //       ActualRiskRate: op.actualRiskRate,
  //       CalculatedFee: op.calculatedFee,
  //       CalculatedPremiumTax: op.calculatedPremiumTax,
  //       CalculatedRemittance: op.calculatedRemittance,
  //       CalculatedRetention: op.calculatedRetention,
  //       CalculatedRiskRate: op.calculatedRiskRate,
  //       ChargeType: op.chargeType,
  //       CoverageType: op.coverageType,
  //       CoverageTypeData: op.coverageTypeData,
  //       FileID: op.fileID,
  //       FilePricingID: op.filePricingID,
  //       FilePricingDetailID: op.id,
  //       IdentityKey: op.identityKey,
  //       IntegrationKey: op.integrationKey,
  //       IsConsent: op.isConsent,
  //       IsDeleted: op.isDeleted,
  //       IsDirty: op.isDirty,
  //       LastBilledDate: op.lastBilledDate,
  //       IsHidden: op.isHidden,
  //       IsNew: op.isNew,
  //       IsPrevPolicyLast10Yrs: op.isPrevPolicyLast10Yrs,
  //       IsReadOnly: op.isReadOnly,
  //       IsReissue: op.isReissue,
  //       IsSimultaneous: op.isSimultaneous,
  //       IsLeasehold: op.isLeasehold,
  //       Liability: op.liability,
  //       OrderID: op.orderID,
  //       PartitionYear: op.partitionYear,
  //       Product: op.product,
  //       ProductItemID: op.productItemID,
  //       ProductTypeCode: op.productTypeCode,
  //       TransCode: op.transCode,
  //       TransCodeDescription: op.transCodeDescription,
  //       PricingRateType: op.pricingRateType,
  //       PricingRateTypeData: op.pricingRateTypeData,
  //       PricingTransactionTypeCode: op.pricingTransactionTypeCode,
  //       PricingFeeTypeCode: op.pricingFeeTypeCode,
  //       OrderStatusTypeCode: op.orderStatusTypeCode,
  //       AltPricingReferenceID: op.altPricingReferenceID,
  //       LastErrorMessage: op.lastErrorMessage,
  //       IsFeeError: op.isFeeError,
  //       // IsManualFee: op.isManualFee,
  //       IsBilled: isCalculateRequested ? op.isBilled : undefined,
  //       IsBillable: isCalculateRequested ? op.isBillable : undefined,
  //       IsReadyToBeBilled: isCalculateRequested ? op.isReadyToBeBilled : undefined,
  //     });
  //   });

  //   return filePricingDetails;
  // };

  // Update product's and endorsements taxes when the total tax is overriden
  const updatePricingTax = () => {
    const isFileLocked = checkIfFileIsLocked(scfile.isLocked);

    scfile.pricingProducts
      ?.filter((p) => p.pricingType === PricingType.Tax)
      ?.forEach((updatedTax) => {
        const initialTax = scfile.updatedInitialPricingProducts?.find(
          (ip) =>
            ip.pricingType === PricingType.Tax &&
            ip.filePricingDetailId === updatedTax.filePricingDetailId
        );
        if (
          updatedTax.actualFeeUpdated &&
          initialTax &&
          initialTax.actualFee !== updatedTax.actualFee
        ) {
          // find product to be updated
          let pricingProduct = scfile.pricingProducts?.find(
            (pp) =>
              pp.pricingType === PricingType.Product &&
              pp.filePricingDetailId === updatedTax.filePricingDetailId
          );

          if (!pricingProduct) return;
          // calculate total premium from the product and its endorsements
          let totalPremium: number = 0.0;
          if (
            !isFileLocked ||
            (isFileLocked && isPricingItemUpdateable(pricingProduct))
          ) {
            totalPremium = convertToNumber(pricingProduct.actualFee);
          }

          pricingProduct.productItems
            ?.filter(
              (pi) =>
                !isFileLocked || (isFileLocked && isPricingItemUpdateable(pi))
            )
            ?.forEach((pi) => {
              totalPremium = roundToPrecision(
                totalPremium + convertToNumber(pi.actualFee),
                2
              );
            });

          // calculate tax rate (updated tax / total premium )
          const taxRate: number =
            totalPremium > 0
              ? convertToNumber(updatedTax.actualFee) / totalPremium
              : 0;

          // update each product item's tax
          let subtotalTax: number = 0; // subtotal for all of endorsements taxes

          let updateableItems: PricingProductItem[] =
            pricingProduct.productItems?.filter(
              (pi) =>
                !isFileLocked || (isFileLocked && isPricingItemUpdateable(pi))
            );

          const itemsCount = updateableItems ? updateableItems?.length : 0;

          //pricingProduct.ProductItems
          updateableItems?.map((pi, index: number) => {

            const actualPremiumTax = roundToPrecision(
              convertToNumber(pi.actualFee) * taxRate,
              2
            );

            // when the file is locked, check for the last item, and use the remaining tax amount
            if (isFileLocked && index === itemsCount - 1) {
              pi.actualPremiumTax = roundToPrecision(
                updatedTax.actualFee - subtotalTax,
                2
              );
            }
            else {
              pi.actualPremiumTax = actualPremiumTax;
            }

            subtotalTax += actualPremiumTax;

            // pi.actualPremiumTax = roundToPrecision(
            //   convertToNumber(pi.actualFee) * taxRate,
            //   2
            // );
            // subtotalTax = roundToPrecision(
            //   subtotalTax + convertToNumber(pi.actualPremiumTax),
            //   2
            // );
            return pi;
          });

          // update product's tax ONLY when the file is not locked
          // don't use multiply here to prevent the fraction rounding error
          if (!isFileLocked) {
            const remainingTax = roundToPrecision(
              updatedTax.actualFee - subtotalTax,
              2
            );
            if (
              pricingProduct.productType === ProductType.StandaloneEndorsement
            ) {
              const lastIndex = pricingProduct.productItems.length - 1;
              pricingProduct.productItems[lastIndex].actualPremiumTax =
                pricingProduct.productItems[lastIndex].actualPremiumTax ||
                0 + remainingTax;
            } else {
              pricingProduct.actualPremiumTax = remainingTax;
            }
          }
        }
      });
  };

  const getUpdatedFilePricingDetails = () => {
    let updatedFilePricingDetails: UIModel.FilePricingDetail[] = [];

    // Update Original UiModel FilePricingDetails based on the updated values from pricingProducts
    scfile.pricingProducts
      ?.filter((p) => p.pricingType === PricingType.Product)
      ?.forEach((newPricing) => {
        // pricing header (jackets or standalone endorsements)
        let originalPricing = scfile.updatedInitialFilePricingDetail.find(
          (op) => op.id === newPricing.filePricingDetailId
        );
        if (originalPricing) {
          if (
            !(
              originalPricing.actualFee ===
              convertToNumber(newPricing.actualFee) &&
              originalPricing.actualRiskRate ===
              convertToNumber(newPricing.actualRiskRate) &&
              originalPricing.actualRetention ===
              convertToNumber(newPricing.agentRetention) &&
              originalPricing.actualRemittance ===
              convertToNumber(newPricing.totalDue) &&
              originalPricing.actualPremiumTax ===
              convertToNumber(newPricing.actualPremiumTax) &&
              originalPricing.pricingRateType === newPricing.pricingRateType &&
              (originalPricing.lastErrorMessage ?? "") ===
              (newPricing.lastErrorMessage ?? "") &&
              originalPricing.altPricingReferenceID ===
              newPricing.altPricingReferenceID
            ) ||
            (mapActionType === MapActionType.ProductAction &&
              productAction === ProductAction.ReportPricing &&
              newPricing.isReadyToBeBilled === 1 &&
              newPricing.isBilled === 0) ||
            newPricing.isReissueUpdated
          ) {
            let newOriginalPricing: UIModel.FilePricingDetail =
              cloneObject(originalPricing);
            newOriginalPricing.pricingRateType = newPricing.pricingRateType;
            newOriginalPricing.pricingTransactionTypeCode =
              newPricing.pricingTransactionTypeCode;
            newOriginalPricing.pricingFeeTypeCode =
              newPricing.pricingFeeTypeCode;
            newOriginalPricing.actualFee = convertToNumber(
              newPricing.actualFee
            );
            newOriginalPricing.calculatedFee = convertToNumber(
              newPricing.calculatedFee
            );
            newOriginalPricing.actualRiskRate = convertToNumber(
              newPricing.actualRiskRate
            );
            newOriginalPricing.calculatedRiskRate = convertToNumber(
              newPricing.calculatedRiskRate
            );
            newOriginalPricing.actualRetention = convertToNumber(
              newPricing.agentRetention
            );
            newOriginalPricing.calculatedRetention = convertToNumber(
              newPricing.calculatedRetention
            );
            newOriginalPricing.actualRemittance = convertToNumber(
              newPricing.totalDue
            );
            newOriginalPricing.calculatedRemittance = convertToNumber(
              newPricing.calculatedTotalDue
            );
            newOriginalPricing.actualPremiumTax = convertToNumber(
              newPricing.actualPremiumTax
            );
            newOriginalPricing.calculatedPremiumTax = convertToNumber(
              newPricing.calculatedPremiumTax
            );
            newOriginalPricing.altPricingReferenceID =
              newPricing.altPricingReferenceID;
            newOriginalPricing.lastErrorMessage = newPricing.lastErrorMessage;
            newOriginalPricing.lastDisplayMessage = newPricing.lastDisplayMessage;
            newOriginalPricing.lastBilledDate = newPricing.lastBilledDate;
            newOriginalPricing.isFeeError = newPricing.isFeeError;
            newOriginalPricing.isManualFee = newPricing.isManualFee;

            updatedFilePricingDetails.push(newOriginalPricing);
          }
        }

        // pricing items (endorsement)
        newPricing.productItems?.forEach((itemNewPricing) => {
          let originalPricing = scfile.updatedInitialFilePricingDetail.find(
            (op) => op.id === itemNewPricing.filePricingDetailId
          );
          // let originalPricing = initialSCFile.filePricingDetails.find(
          //   (op) => op.id === itemNewPricing.filePricingDetailId
          // );
          if (!originalPricing) return;

          if (
            !(
              originalPricing.actualFee ===
              convertToNumber(itemNewPricing.actualFee) &&
              originalPricing.actualRiskRate ===
              convertToNumber(itemNewPricing.actualRiskRate) &&
              originalPricing.actualRetention ===
              convertToNumber(itemNewPricing.agentRetention) &&
              originalPricing.actualRemittance ===
              convertToNumber(itemNewPricing.totalDue) &&
              originalPricing.actualPremiumTax ===
              convertToNumber(itemNewPricing.actualPremiumTax) &&
              originalPricing.altPricingReferenceID ===
              itemNewPricing.altPricingReferenceID
            ) ||
            (mapActionType === MapActionType.ProductAction &&
              productAction === ProductAction.ReportPricing &&
              itemNewPricing.isReadyToBeBilled === 1 &&
              itemNewPricing.isBilled === 0)
          ) {
            let newOriginalPricing: UIModel.FilePricingDetail =
              cloneObject(originalPricing);

            newOriginalPricing.actualFee = convertToNumber(
              itemNewPricing.actualFee
            );
            newOriginalPricing.calculatedFee = convertToNumber(
              itemNewPricing.calculatedFee
            );

            newOriginalPricing.actualRiskRate = convertToNumber(
              itemNewPricing.actualRiskRate
            );

            newOriginalPricing.calculatedRiskRate = convertToNumber(
              itemNewPricing.calculatedRiskRate
            );

            newOriginalPricing.actualRetention = convertToNumber(
              itemNewPricing.agentRetention
            );
            newOriginalPricing.calculatedRetention = convertToNumber(
              itemNewPricing.calculatedRetention
            );

            newOriginalPricing.actualRemittance = convertToNumber(
              itemNewPricing.totalDue
            );
            newOriginalPricing.calculatedRemittance = convertToNumber(
              itemNewPricing.calculatedTotalDue
            );

            newOriginalPricing.actualPremiumTax = convertToNumber(
              itemNewPricing.actualPremiumTax
            );
            newOriginalPricing.calculatedPremiumTax = convertToNumber(
              itemNewPricing.calculatedPremiumTax
            );

            newOriginalPricing.altPricingReferenceID =
              itemNewPricing.altPricingReferenceID;
            newOriginalPricing.lastErrorMessage =
              itemNewPricing.lastErrorMessage;
            newOriginalPricing.lastDisplayMessage =
              itemNewPricing.lastDisplayMessage;              
            newOriginalPricing.isFeeError = itemNewPricing.isFeeError;
            newOriginalPricing.isManualFee = itemNewPricing.isManualFee;
            newOriginalPricing.lastBilledDate = newPricing.lastBilledDate;

            updatedFilePricingDetails.push(newOriginalPricing);
          }
        });
      });

    return updatedFilePricingDetails;
  };

  const updateFilePricingDetails = (apiscfile: ApiModel.SCFile) => {
    if (updatedFilePricingDetails.length > 0) {
      // Update CPL's FilePricingDetails
      apiscfile.CPLProducts?.filter(
        (cpl) => cpl.OrderStatusTypeCode === OrderStatusType.Issued
      )?.forEach((cpl) => {
        // check if jacket pricing is updated
        let updatedCPLPricing = updatedFilePricingDetails.find(
          (p) => p.id === cpl.FilePricingDetailID
        );

        if (updatedCPLPricing) {
          cpl.IsDirty = true;
          cpl.PricingDetail!.IsDirty = true;
          cpl.PricingDetail!.ActualFee = updatedCPLPricing.actualFee;
          cpl.PricingDetail!.CalculatedFee = updatedCPLPricing.calculatedFee;
          cpl.PricingDetail!.ActualRiskRate = updatedCPLPricing.actualRiskRate;
          cpl.PricingDetail!.CalculatedRiskRate = updatedCPLPricing.calculatedRiskRate;
          cpl.PricingDetail!.ActualRetention = updatedCPLPricing.actualRetention;
          cpl.PricingDetail!.CalculatedRetention = updatedCPLPricing.calculatedRetention;
          cpl.PricingDetail!.ActualRemittance = updatedCPLPricing.actualRemittance;
          cpl.PricingDetail!.CalculatedRemittance = updatedCPLPricing.calculatedRemittance;
          cpl.PricingDetail!.ActualPremiumTax = updatedCPLPricing.actualPremiumTax;
          cpl.PricingDetail!.CalculatedPremiumTax = updatedCPLPricing.calculatedPremiumTax;
          cpl.PricingDetail!.AltPricingReferenceID = updatedCPLPricing.altPricingReferenceID;
          cpl.PricingDetail!.LastErrorMessage = updatedCPLPricing.lastErrorMessage;
          cpl.PricingDetail!.LastDisplayMessage = updatedCPLPricing.lastDisplayMessage;
          cpl.PricingDetail!.IsFeeError = updatedCPLPricing.isFeeError;
          // cpl.PricingDetail!.IsManualFee = updatedCPLPricing.isManualFee;
        }
      });

      // Update jacket's FilePricingDetails
      apiscfile.JacketProducts?.filter(
        (jacket) => jacket.OrderStatusTypeCode === OrderStatusType.Issued
      )?.forEach((jacket) => {
        // check if jacket pricing is updated
        let updatedJacketPricing = updatedFilePricingDetails.find(
          (p) => p.id === jacket.FilePricingDetailID
        );

        if (updatedJacketPricing) {
          jacket.IsDirty = true;
          jacket.PricingDetail!.IsDirty = true;
          jacket.PricingDetail!.ActualFee = updatedJacketPricing.actualFee;
          jacket.PricingDetail!.CalculatedFee = updatedJacketPricing.calculatedFee;
          jacket.PricingDetail!.ActualRiskRate = updatedJacketPricing.actualRiskRate;
          jacket.PricingDetail!.CalculatedRiskRate = updatedJacketPricing.calculatedRiskRate;
          jacket.PricingDetail!.ActualRetention = updatedJacketPricing.actualRetention;
          jacket.PricingDetail!.CalculatedRetention = updatedJacketPricing.calculatedRetention;
          jacket.PricingDetail!.ActualRemittance = updatedJacketPricing.actualRemittance;
          jacket.PricingDetail!.CalculatedRemittance = updatedJacketPricing.calculatedRemittance;
          jacket.PricingDetail!.ActualPremiumTax = updatedJacketPricing.actualPremiumTax;
          jacket.PricingDetail!.CalculatedPremiumTax = updatedJacketPricing.calculatedPremiumTax;
          jacket.PricingDetail!.PricingRateType = updatedJacketPricing!.pricingRateType;
          jacket.PricingDetail!.PricingTransactionTypeCode = updatedJacketPricing!.pricingTransactionTypeCode;
          jacket.PricingDetail!.PricingFeeTypeCode = updatedJacketPricing!.pricingFeeTypeCode;
          jacket.PricingDetail!.AltPricingReferenceID = updatedJacketPricing.altPricingReferenceID;
          jacket.PricingDetail!.LastErrorMessage = updatedJacketPricing.lastErrorMessage;
          jacket.PricingDetail!.LastDisplayMessage = updatedJacketPricing.lastDisplayMessage;
          jacket.PricingDetail!.IsFeeError = updatedJacketPricing.isFeeError;
          // jacket.PricingDetail!.IsManualFee = updatedJacketPricing.isManualFee;
        }

        // check if endorsement pricing is updated
        let isEndorsementPricingUpdated = false;
        jacket.Endorsements?.forEach((e) => {
          let updatedEndorsementPricing = updatedFilePricingDetails.find(
            (p) => p.id === e.FilePricingDetailID
          );

          if (updatedEndorsementPricing) {
            isEndorsementPricingUpdated = true;
            e.IsDirty = true;
            e.PricingDetail!.IsDirty = true;
            e.PricingDetail!.ActualFee = updatedEndorsementPricing.actualFee;
            e.PricingDetail!.CalculatedFee =
              updatedEndorsementPricing.calculatedFee;
            e.PricingDetail!.ActualRiskRate =
              updatedEndorsementPricing.actualRiskRate;
            e.PricingDetail!.CalculatedRiskRate =
              updatedEndorsementPricing.calculatedRiskRate;
            e.PricingDetail!.ActualRetention =
              updatedEndorsementPricing.actualRetention;
            e.PricingDetail!.CalculatedRetention =
              updatedEndorsementPricing.calculatedRetention;
            e.PricingDetail!.ActualRemittance =
              updatedEndorsementPricing.actualRemittance;
            e.PricingDetail!.CalculatedRemittance =
              updatedEndorsementPricing.calculatedRemittance;

            e.PricingDetail!.ActualPremiumTax =
              updatedEndorsementPricing.actualPremiumTax;
            e.PricingDetail!.CalculatedPremiumTax =
              updatedEndorsementPricing.calculatedPremiumTax;

            e.PricingDetail!.AltPricingReferenceID =
              updatedEndorsementPricing.altPricingReferenceID;
            e.PricingDetail!.LastErrorMessage =
              updatedEndorsementPricing.lastErrorMessage;
            e.PricingDetail!.LastDisplayMessage =
              updatedEndorsementPricing.lastDisplayMessage;              
            e.PricingDetail!.IsFeeError =
              updatedEndorsementPricing.isFeeError
            e.PricingDetail!.IsManualFee =
              updatedEndorsementPricing.isManualFee;

            e.PricingDetail!.TransCode = updatedEndorsementPricing.transCode;
            e.PricingDetail!.TransCodeDescription =
              updatedEndorsementPricing.transCodeDescription;
          }
        });
        if (isEndorsementPricingUpdated) jacket.IsDirty = true;
      });

      // Update Standalone's FilePricingDetails
      if (updatedFilePricingDetails.length > 0) {
        apiscfile.StandaloneEndorsementProducts?.filter(
          (sa) => sa.OrderStatusTypeCode === OrderStatusType.Issued
        )?.forEach((sa) => {
          let isPricingUpdated = false;
          sa.Endorsements?.forEach((e) => {
            // check if pricing is updated
            let updatedPricing = updatedFilePricingDetails.find(
              (p) => p.id === e.FilePricingDetailID
            );
            if (updatedPricing) {
              isPricingUpdated = true;
              e.IsDirty = true;
              e.PricingDetail!.IsDirty = true;
              e.PricingDetail!.ActualFee = updatedPricing.actualFee;
              e.PricingDetail!.CalculatedFee = updatedPricing.calculatedFee;
              e.PricingDetail!.ActualRiskRate = updatedPricing.actualRiskRate;
              e.PricingDetail!.CalculatedRiskRate =
                updatedPricing.calculatedRiskRate;
              e.PricingDetail!.ActualRetention = updatedPricing.actualRetention;
              e.PricingDetail!.CalculatedRetention =
                updatedPricing.calculatedRetention;
              e.PricingDetail!.ActualRemittance =
                updatedPricing.actualRemittance;
              e.PricingDetail!.CalculatedRemittance =
                updatedPricing.calculatedRemittance;

              e.PricingDetail!.ActualPremiumTax =
                updatedPricing.actualPremiumTax;
              e.PricingDetail!.CalculatedPremiumTax =
                updatedPricing.calculatedPremiumTax;

              e.PricingDetail!.AltPricingReferenceID =
                updatedPricing.altPricingReferenceID;
              e.PricingDetail!.LastErrorMessage =
                updatedPricing.lastErrorMessage;
              e.PricingDetail!.LastDisplayMessage =
                updatedPricing.lastDisplayMessage;                
              e.PricingDetail!.IsFeeError =
                updatedPricing.isFeeError;
              e.PricingDetail!.IsManualFee =
                updatedPricing.isManualFee;
              e.PricingDetail!.TransCode = updatedPricing.transCode;
              e.PricingDetail!.TransCodeDescription =
                updatedPricing.transCodeDescription;
            }
          });
          if (isPricingUpdated) sa.IsDirty = true;
        });
      }
    }
  };

  const setPropertyToDirty = (
    apiscfile: ApiModel.SCFile,
    scfile: UIModel.SCFile
  ) => {
    if (
      apiscfile.Properties &&
      apiscfile.Properties.length === 1 &&
      !scfile.properties[0].isDirty &&
      scfile.properties[0].identityKey === 0
    ) {
      scfile.properties[0].isDefault = false;
    }
  };

  let apiscfile = mapFileBase();
  let updatedFilePricingDetails: UIModel.FilePricingDetail[] = [];
  if (productAction !== ProductAction.CalculateItem) {
    updatePricingTax(); // must be called before getUpdatedFilePricingDetails
    updatedFilePricingDetails = getUpdatedFilePricingDetails();
  }

  switch (mapActionType) {
    case MapActionType.SaveFileStatus:
      apiscfile.IsDirty = true;
      // don't call markIsDirtyIsNew here
      break;
    case MapActionType.CheckFormDirty:
    case MapActionType.SaveFile:
      apiscfile.Properties = mapProperty();
      apiscfile.Parties = mapParty();
      apiscfile.AALProducts = mapAal();
      apiscfile.CPLProducts = mapCpl();
      apiscfile.JacketProducts = mapJacket(); // need to include pricing for issued jacket
      apiscfile.StandaloneEndorsementProducts = mapStandalone(); // need to include pricing for issued standalone endorsmeent
      apiscfile.FilePricing = mapFilePricing();
      apiscfile.DocumentFileData = mapDocumentFileData();
      // apiscfile.FilePricingDetails = mapPricing(false);
      if (
        (apiscfile.Parties && apiscfile.Parties.length > 0) ||
        hasPendingProducts(scfile)
      ) {
        setPropertyToDirty(apiscfile, scfile);
      }
      markIsDirtyIsNew(apiscfile, initialSCFile, scfile, mapActionType);
      updateFilePricingDetails(apiscfile);

      break;

    case MapActionType.ProductAction:
      apiscfile.Properties = mapProperty();
      apiscfile.Parties = mapParty();
      if (apiscfile.Parties && apiscfile.Parties.length > 0) {
        setPropertyToDirty(apiscfile, scfile);
      }
      apiscfile.FilePricing = mapFilePricing();
      apiscfile.DocumentFileData = mapDocumentFileData();

      switch (productAction) {
        case ProductAction.Issue:
          apiscfile.CombinedQNAs = mapQNAs();
          apiscfile.AALProducts = mapAal();
          apiscfile.CPLProducts = mapCpl();
          apiscfile.JacketProducts = mapJacket();
          apiscfile.StandaloneEndorsementProducts = mapStandalone();
          // apiscfile.FilePricingDetails = mapPricing();
          if (hasPendingProducts(scfile)) {
            setPropertyToDirty(apiscfile, scfile);
          }
          break;

        case ProductAction.Revise: {
          const jackets = mapJacket();
          apiscfile.CombinedQNAs = mapQNAs();
          apiscfile.CPLProducts = mapCpl();
          apiscfile.AALProducts = mapAal();
          apiscfile.JacketProducts = jackets;
          apiscfile.StandaloneEndorsementProducts = mapStandalone();
          // apiscfile.FilePricingDetails = mapPricing();
          break;
        }

        case ProductAction.Void:
          // mapping for Void here
          apiscfile.CPLProducts = mapCpl();
          apiscfile.AALProducts = mapAal();
          apiscfile.JacketProducts = mapJacket();
          apiscfile.StandaloneEndorsementProducts = mapStandalone();
          // apiscfile.FilePricingDetails = mapPricing();
          break;

        case ProductAction.ReportPricing:
          apiscfile.CombinedQNAs = mapQNAs();
          apiscfile.CPLProducts = mapCpl();
          apiscfile.AALProducts = mapAal();
          apiscfile.JacketProducts = mapJacket();
          apiscfile.StandaloneEndorsementProducts = mapStandalone();
          // apiscfile.FilePricingDetails = mapPricing(true);
          break;

        case ProductAction.UndoReport:
          apiscfile.CombinedQNAs = mapQNAs();
          apiscfile.CPLProducts = mapCpl();
          apiscfile.AALProducts = mapAal();
          apiscfile.JacketProducts = mapJacket();
          apiscfile.StandaloneEndorsementProducts = mapStandalone();
          // apiscfile.FilePricingDetails = mapPricing(true);
          break;

        case ProductAction.CalculatePricing:
          apiscfile.CPLProducts = mapCpl(true);
          apiscfile.AALProducts = mapAal(true);
          apiscfile.JacketProducts = mapJacket(true);
          apiscfile.StandaloneEndorsementProducts = mapStandalone(true);
          // apiscfile.FilePricingDetails = mapPricing(false, true);
          break;

        case ProductAction.CalculateItems:
          apiscfile.CPLProducts = mapCpl();
          apiscfile.AALProducts = mapAal();
          apiscfile.JacketProducts = mapJacket();
          apiscfile.StandaloneEndorsementProducts = mapStandalone();
          break;

        case ProductAction.CalculateItem:
        default:
          break;
      }
      markIsDirtyIsNew(apiscfile, initialSCFile, scfile, mapActionType);
      updateFilePricingDetails(apiscfile);
      break;

    case MapActionType.RetrievePricingData:
      apiscfile.Properties = mapProperty();
      apiscfile.CPLProducts = mapCpl();
      apiscfile.AALProducts = mapAal();
      apiscfile.JacketProducts = mapJacket();
      apiscfile.StandaloneEndorsementProducts = mapStandalone();
      apiscfile.FilePricing = mapFilePricing(true);
      break;
    case MapActionType.SaveJacketOPN:
      apiscfile.Properties = mapProperty();
      apiscfile.FilePricing = mapFilePricing(true);
      markIsDirtyIsNew(apiscfile, initialSCFile, scfile, mapActionType);
      apiscfile.JacketProducts = mapJacketOPN(jacketsOPN);
      break;
  }

  return apiscfile;
};

export default mapUiSCFileToApiSCFile;
