import CreateFilePage from "pages/file/CreateFilePage";
import HelpCenter from "pages/help-center/HelpCenter";
import HomePage from "pages/home/HomePage";
import ListFilePage from "pages/myFiles/ListFilePage";
import PendingPayPage from "pages/payments/PendingPayPage";
import ReportPayPage from "pages/payments/ReportPayPage";
import ProfileSettings from "pages/profile-settings/ProfileSettings";
import PolicyRegisterReport from "pages/reports/PolicyRegisterReport";
import Signatures from "pages/signatures/Signatures";
import StewartPreferred from "pages/stewartresources/components/StewartPreferred";
import StewartPreferredBusinessSolution from "pages/stewartresources/components/StewartPreferredBusinessSolution";
import StewartPreferredDigitalSolution from "pages/stewartresources/components/StewartPreferredDigitalSolution";
import StewartPreferredOfficeSolution from "pages/stewartresources/components/StewartPreferredOfficeSolution";
import StewartResources from "pages/stewartresources/components/StewartResources";
import React from "react";
import {
  Navigate,
  createBrowserRouter,
} from "react-router-dom";
import { PERMISSIONS, SiteErrorType } from "utils/data/enum";
import PrivateLayout from "./PrivateLayout";
import SelectedHomePage from "./SelectedHomePage";
import { SiteErrorMessage } from "./components/SiteErrorMessage";
import PolicyImages from "pages/policy-image/PolicyImages";


const createElement = (element: JSX.Element, permission?: PERMISSIONS) => <PrivateLayout permission={permission}>{element}</PrivateLayout>;

const router = createBrowserRouter([
  {
    path: "/",
    element: createElement(<SelectedHomePage />),
  },
  {
    path: "/Home",
    element: createElement(<HomePage />),
  },
  {
    path: "/file",
    element: createElement(
      <CreateFilePage saveFileRef={undefined} saveFileChangeRef={undefined} />,
      PERMISSIONS.CREATE_FILE_ACCESS),
  },
  {
    path: "/file/:encodedFileId",
    element: <Navigate replace to="/Home" />,
  },
  {
    path: "/files",
    element: createElement(<ListFilePage />, PERMISSIONS.FILES_SEARCH),
  },
  {
    path: "/reportpay",
    element: createElement(<ReportPayPage />, PERMISSIONS.VIEW_BILLING),
  },
  {
    path: "/pendingpay",
    element: createElement(<PendingPayPage />, PERMISSIONS.VIEW_BILLING),
  },
  {
    path: "/profile-settings",
    element: createElement(
      <ProfileSettings saveFileRef={undefined} saveFileChangeRef={undefined} />,
    ),
  },
  {
    path: "/help-center",
    element: createElement(<HelpCenter />, PERMISSIONS.HELP_ACCESS),
  },
  {
    path: "/manage-signatures",
    element: createElement(<Signatures />, PERMISSIONS.SIGNATURES_MANAGE),
  },
  {
    path: "/reports",
    element: createElement(<PolicyRegisterReport />, PERMISSIONS.VIEW_REPORTS),
  },
  {
    path: "/resources",
    element: createElement(<StewartResources />, PERMISSIONS.RESOURCES_ACCESS),
  },
  {
    path: "/stewartpreferred",
    element: createElement(<StewartPreferred />, PERMISSIONS.RESOURCES_ACCESS),
  },
  {
    path: "/stewartPreferredDigitalSolution/:item",
    element: createElement(<StewartPreferredDigitalSolution />, PERMISSIONS.RESOURCES_ACCESS),
  },
  {
    path: "/stewartPreferredBusinessSolution/:item",
    element: createElement(<StewartPreferredBusinessSolution />, PERMISSIONS.RESOURCES_ACCESS),
  },
  {
    path: "/stewartPreferredOfficeSolution/:item",
    element: createElement(<StewartPreferredOfficeSolution />, PERMISSIONS.RESOURCES_ACCESS),
  },
  {
    path: "/policyimages",
    element: createElement(<PolicyImages />, PERMISSIONS.POLICYIMAGES_ACCESS),
  },
  {
    path: "/nomoduleaccess",
    element: createElement(<SiteErrorMessage errorType={SiteErrorType.NoModuleAccess} />),
  },
  {
    path: "403",
    element: createElement(<SiteErrorMessage errorType={SiteErrorType.NoAccess} />),
  },
  {
    path: "404",
    element: createElement(<SiteErrorMessage errorType={SiteErrorType.NotFound} />),
  },
  {
    path: "*",
    element: createElement(<SiteErrorMessage errorType={SiteErrorType.NotFound} />),
  },
]);

export default router;
