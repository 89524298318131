import React from "react";
import TableBody from "@mui/material/TableBody";
import { colors } from "theme/defaultStyle";
import PricingProductItems from "./PricingProductItems";
import PricingProductItem from "./PricingProductItem";
import {
  styled,
  TableRow,
} from "@mui/material";
import { PricingType } from "utils/data/enum";
import { PricingProduct } from "entities/UIModel/PricingProduct";
import { PricingProductItem as PricingProductItemModel } from "entities/UIModel";
import { useFieldArray } from "react-hook-form";
import usePricing from "utils/custom-hooks/usePricing";
import useCreateFile from "utils/custom-hooks/useCreateFile";

const StyledTableRow = styled(TableRow)({
  "&.product": {
    fontStyle: "normal",
  },
  "&.tax": {
    fontStyle: "italic",
    "& input": {
      letterSpacing: "0.8px",
    },
  },
  "&.taxLockedUpdateAble": {
    backgroundColor: colors.white,
    fontStyle: "italic",
    "& input": {
      letterSpacing: "0.8px",
    },
  },
  "&.lockedUpdateAble": {
    backgroundColor: colors.white,
  },
});

type Props = {
  products: PricingProduct[];
  displayLiabilityColumn: boolean;
  displayRateTypeColumn: boolean;
  displayTransCodeColumn: boolean;
  displayReissueColumn: boolean;
  displayRiskRateColumn: boolean;
  displayFieldIcon: boolean;
  isIntegratedPricing: boolean;
  isOverride: boolean;
  hasErrorMessage: boolean;
  handleRateTypeChange: (e: any | null, index: number) => void;
  handleReissueChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    pricingProduct: PricingProduct,
    pricingIndex: number
  ) => void;
  handleActualFeeBlur: (e: any, index: number) => Promise<void>;
  // handleActualFeeOnChange: (e: any, index: number) => void;
  handleRiskRateBlur: (e: any, index: number) => void;
  handleAgentRetentionBlur: (e: any, index: number) => void;
  handleTotalDueBlur: (e: any, index: number) => void;
  handleActualFeeItemBlur: (
    e: any,
    index: number,
    itemIndex: number
  ) => Promise<void>;
  handleRiskRateItemBlur: (e: any, index: number, itemIndex: number) => void;
  handleAgentRetentionItemBlur: (
    e: any,
    index: number,
    itemIndex: number
  ) => void;
  handleTotalDueItemBlur: (e: any, index: number, itemIndex: number) => void;
  handleIsReissueClick: (index: number) => void;
  setIsPricingSectionUpdated: (isUpdated: boolean) => void;
};

export default function PricingTableBody({
  products,
  displayLiabilityColumn,
  displayRateTypeColumn,
  displayTransCodeColumn,
  displayReissueColumn,
  displayRiskRateColumn,
  displayFieldIcon,
  isIntegratedPricing,
  isOverride,
  hasErrorMessage,
  handleRateTypeChange,
  handleReissueChange,
  handleActualFeeBlur,
  // handleActualFeeOnChange,
  handleRiskRateBlur,
  handleAgentRetentionBlur,
  handleTotalDueBlur,
  handleActualFeeItemBlur,
  handleRiskRateItemBlur,
  handleAgentRetentionItemBlur,
  handleTotalDueItemBlur,
  handleIsReissueClick,
  setIsPricingSectionUpdated,
}: Props) {
  // NOTE: With the latest verison of react (18.2.0) and react-hook-form (7.40.0),
  // useFieldArray appears to stay behind the file's pricingProducts array
  //  when items are removed from the array external to this control.
  // filter() has been applied below to remove the resultant undefined entries
  //  which will get cleaned up from fields on the next update to this array.
  const { fields } = useFieldArray({
    name: "pricingProducts",
    keyName: "fieldId",
  });
  const { isFileLockedWithUpdateablePricingItem } = usePricing();

  const { isFileLocked, isReadOnly } = useCreateFile();

  return (
    <TableBody>
      {fields
        .filter(pricing => "filePricingDetailId" in pricing)
        .map((pricing: any, index: number) => (
          <React.Fragment key={pricing.fieldId}>
            <StyledTableRow
              key={`${pricing.pricingType}|${pricing.filePricingDetailId}`}
              className={
                pricing.pricingType === PricingType.Tax
                  ? isFileLockedWithUpdateablePricingItem(pricing, isFileLocked, isReadOnly)
                    ? "taxLockedUpdateAble"
                    : "tax"
                  : "product"
              }
            >
              <PricingProductItem
                pricing={pricing}
                index={index}
                isIntegratedPricing={isIntegratedPricing}
                isOverride={isOverride}
                displayLiabilityColumn={displayLiabilityColumn}
                displayRateTypeColumn={displayRateTypeColumn}
                displayReissueColumn={displayReissueColumn}
                displayTransCodeColumn={displayTransCodeColumn}
                displayRiskRateColumn={displayRiskRateColumn}
                displayFieldIcon={displayFieldIcon}
                handleRateTypeChange={handleRateTypeChange}
                handleReissueChange={handleReissueChange}
                handleIsReissueClick={handleIsReissueClick}
                handleActualFeeBlur={handleActualFeeBlur}
                // handleActualFeeOnChange={handleActualFeeOnChange}
                handleRiskRateBlur={handleRiskRateBlur}
                handleAgentRetentionBlur={handleAgentRetentionBlur}
                handleTotalDueBlur={handleTotalDueBlur}
                isFileLocked={isFileLocked}
                isReadOnly={isReadOnly}
                isPricingUpdateable={isFileLockedWithUpdateablePricingItem(
                  pricing,
                  isFileLocked,
                  isReadOnly
                )}
              />
            </StyledTableRow>
            {pricing.productItems &&
              (pricing.productItems as PricingProductItemModel[])
                .filter(item => "productItemId" in item)
                .map((item: PricingProductItemModel, itemIndex: number) => (
                  <StyledTableRow
                    key={item.productItemId}
                    className={
                      isFileLockedWithUpdateablePricingItem(item, isFileLocked, isReadOnly)
                        ? "lockedUpdateAble"
                        : ""
                    }
                  >
                    <PricingProductItems
                      key={`${item.productItemId}`}
                      index={itemIndex}
                      parentIndex={index}
                      item={item}
                      isIntegratedPricing={isIntegratedPricing}
                      isOverride={isOverride}
                      isReadOnly={isReadOnly}
                      hasErrorMessage={hasErrorMessage}
                      displayLiabilityColumn={displayLiabilityColumn}
                      displayRateTypeColumn={displayRateTypeColumn}
                      displayReissueColumn={displayReissueColumn}
                      displayTransCodeColumn={displayTransCodeColumn}
                      displayRiskRateColumn={displayRiskRateColumn}
                      handleActualFeeItemBlur={handleActualFeeItemBlur}
                      handleRiskRateItemBlur={handleRiskRateItemBlur}
                      handleAgentRetentionItemBlur={handleAgentRetentionItemBlur}
                      handleTotalDueItemBlur={handleTotalDueItemBlur}
                      setIsPricingSectionUpdated={setIsPricingSectionUpdated}
                      productType={pricing.productType}
                    />
                  </StyledTableRow>
                )
                )}
          </React.Fragment>
        ))}
    </TableBody>
  );
}
