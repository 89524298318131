import { styled } from "@mui/material";
import ActionButton from "controls/global/action-button";
import React from "react";
import {
  colors,
  fontSize,
} from "theme/defaultStyle";
import EyeIcon from "theme/icons/EyeIcon";

type Props = {
  onNavigate: (url: string) => void;
  disabled?: boolean;
};

const StyledButton = styled(ActionButton)({
  "& .MuiSvgIcon-root": {
    color: colors.blue01,
    fontSize: fontSize.xlarge0,
  },
});

const ViewPendingPayment = ({ onNavigate, disabled = false }: Props) => {
  const handleNavigate = () => {
    onNavigate("/pendingpay");
  };

  return (
    <StyledButton
      variant="outlined"
      color="secondary"
      onClick={handleNavigate}
      startIcon={<EyeIcon />}
      disabled={disabled}
    >
      View Pending Payments
    </StyledButton>
  );
};

export default ViewPendingPayment;
