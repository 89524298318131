import { CancelTokenSource } from "axios";
import {
  axiosSecuredInstance,
  cancelToken,
} from "configurations/axiosConfig";
import {
  Column,
  IColumn,
} from "entities/ApiModel/IColumn";
import { PendingPaySearchResult } from "entities/ApiModel/PendingPaySearchResult";
import { PendingPayFile } from "entities/UIModel/PendingPayFile";
import { PendingPayKeywordSearch } from "entities/UIModel/PendingPayKeywordSearch";
import { PendingPayCriteriaSearch } from "entities/UIModel/PendingPayCriteriaSearch";
import {
  groupBy,
  sortBy,
} from "lodash";
import { getPendingPayByCriteria } from "pages/payments/utils/PendingPayTable";
import {
  StoreActionApi,
  createHook,
  createStore,
} from "react-sweet-state";
import {
  LocalStorageKeys,
} from "utils/data/enum";
import {
  startNotifying,
  stopNotifying,
} from "utils/services/ContextProgressNotifierService";

interface State {
  progressPercent?: number;
  refreshData: boolean;
  error?: string;
  axiosCancelToken?: CancelTokenSource;
  requestTimeoutMs: number;
  selectedItems: PendingPayFile[];
  onlyShowSelectedFiles: boolean;
  isAdvSearchOpen: boolean;
}

type StoreApi = StoreActionApi<State>;
type Actions = typeof actions;

// NOTE: Consider adding a user story to consolidate this into app config.
const searchFieldOverrides: { [K in keyof PendingPayFile]?: keyof PendingPayCriteriaSearch } = {
  reportOptionTypeCode: "reportOptionTypeCode",
  propertyAddresses: "propertyAddress",
  locationLegacyIDs: "locationLegacyID",
  locationDisplayNames: "locationDisplayName",
  lenderNames: "lenderName",
  propertyTypeCodes: "propertyTypeCode",
};

const startProgressNotififer =
  (criteria: PendingPayCriteriaSearch) =>
    (storeApi: StoreApi) => {
      const handleUpdateProgress = (percent: number | undefined) =>
        storeApi.setState({ progressPercent: percent });
      startNotifying(getSearchType(criteria), handleUpdateProgress);
    };

const stopProgressNotififer =
  (criteria?: PendingPayCriteriaSearch) =>
    (storeApi: StoreApi) => {
      const handleUpdateProgress = (percent: number | undefined) =>
        storeApi.setState({ progressPercent: percent });
      stopNotifying(getSearchType(criteria), handleUpdateProgress);
    };

const getSearchType = (criteria?: PendingPayCriteriaSearch) => {
  if (criteria === undefined)
    return "";

  const getDayBetween = (startDate: Date, endDate: Date): number => {
    const msInDay = 24 * 60 * 60 * 1000;
    return Math.round(Math.abs(endDate.valueOf() - startDate.valueOf()) / msInDay
    );
  };

  const propertiesToIgnore = [
    "dateRangeStart",
    "dateTypeCode",
    "fileStatusTypeCode",
    "listCount",
    "requestedPage",
    "showInActive",
    "sortColumn",
    "userFilter",
  ];

  let extendedDateSearch = true;
  const filtersApplied = Object.entries(criteria)
    .filter(([name, value]) => value !== null && !propertiesToIgnore.includes(name))
    .length > 0;

  if (!filtersApplied && criteria.dateRangeStart) {
    const daysForQuickerSearch = 90;
    extendedDateSearch = getDayBetween(new Date(), criteria.dateRangeStart) > daysForQuickerSearch;
  }

  return (filtersApplied || extendedDateSearch) ? "pendingpay-long" : "pendingpay-short";
};

const mapPendingPayFromApiToUIModel = (pendingPayListResponse: PendingPaySearchResult[]) => {
  const grouped = Object.values(groupBy(pendingPayListResponse, f => [f.FileID, f.PaymentSheetID]));
  const sorted = sortBy(grouped, g => pendingPayListResponse.indexOf(g[0]));
  const mapped = sorted.map(g => {
    const firstItem = g[0];
    const group: PendingPayFile = {
      uniqueIdentifier: `${firstItem.FileID}-${firstItem.PaymentSheetID}`, // firstItem.FileID.toString(),
      selected: false,
      fileId: firstItem.FileID,
      paymentSheetID: firstItem.PaymentSheetID,
      filePricingId: firstItem.FilePricingId,
      clientFileID: firstItem.ClientFileID || "",
      orderNumber: firstItem.OrderNumber,
      clientFileIDList: firstItem.ClientFileIDList || "",
      propertyAddresses: firstItem.PropertyAddresses || "",
      lastBilledDate: firstItem.LastBilledDate ? new Date(firstItem.LastBilledDate.substring(0, 19)) : null,
      locationLegacyIDs: firstItem.LocationLegacyIDs ?? "",
      reportOptionTypeCode: firstItem.ReportOptionTypeCode || "",
      reportOptionTypeName: firstItem.ReportOptionTypeName || "",
      lastBilledBy: firstItem.LastBilledBy || "",
      locationDisplayNames: firstItem.LocationDisplayNames || "",
      agencyName: firstItem.AgencyName || "",
      firstJacketEffectiveDate: firstItem.FirstJacketEffectiveDate ? new Date(firstItem.FirstJacketEffectiveDate.substring(0, 19)) : null,
      firstJacketIssueDate: firstItem.FirstJacketIssueDate ? new Date(firstItem.FirstJacketIssueDate.substring(0, 19)) : null,
      lenderNames: firstItem.LenderNames ? firstItem.LenderNames.replaceAll("|", ", ") : "",
      stateAbbr: firstItem.StateAbbr || "",
      propertyTypeCodes: firstItem.PropertyTypeCodes || "",
      transactionTypeCode: firstItem.TransactionTypeCode || "",
      totalBilledActualCombinedFee: firstItem.TotalBilledActualCombinedFee,
      totalBilledAmountDue: firstItem.TotalBilledAmountDue ?? null,
      totalBilledActualFee: firstItem.TotalBilledActualFee ?? null,
      totalBilledActualRetention: firstItem.TotalBilledActualRetention ?? null,
      totalBilledActualRiskRate: firstItem.TotalBilledActualRiskRate ?? null,
      rowsPerPage: firstItem.RowsPerPage || 0,
      totalRows: firstItem.TotalRows || 0,
      currentPage: firstItem.CurrentPage || 0,
      totalPages: firstItem.TotalPages || 0,
      companyStatus :firstItem.CompanyStatus || "",
      details: g.map((file) => ({
        product: file.Product || "",
        muniCnty: file.MuniCnty || "",
        transCode: file.TransCode || "",
        form : file.Form || "",
        liability: file.Liability || 0,
        productIssueDate: file.ProductIssueDate ? new Date(file.ProductIssueDate) : null,
        productEffectiveDate: file.ProductEffectiveDate ? new Date(file.ProductEffectiveDate) : null,
        actualFee: file.ActualFee || 0,
        taxRiskRate: file.TaxRiskRate || 0,
        actualRetention: file.ActualRetention || 0,
        amountDue: file.AmountDue || 0,
      }))
    };
    return group;
  });

  return mapped;
};

const setPendingPayList =
  (pendingPayListResponse: PendingPaySearchResult[]) =>
    (): PendingPayFile[] => {
      if (!pendingPayListResponse || pendingPayListResponse.length === 0) {
        return [];
      }
      return mapPendingPayFromApiToUIModel(pendingPayListResponse);
    };

const setColumns =
  (columns: any[]) =>
    () => {
      const gridColumns: IColumn[] = columns.filter((c) => Column.isValid(c));
      const gridHiddenColumns = gridColumns
        .filter((c) => c.hidden)
        .map((m) => m.field as keyof PendingPayFile);

      // NOTE: Consider adding a user story to consolidate this into app config.
      gridColumns.forEach(c => c.searchField = searchFieldOverrides[c.field as keyof PendingPayFile] ?? c.field);

      localStorage.setItem(LocalStorageKeys.COLUMNS_PENDING_PAY, JSON.stringify(gridColumns));
      localStorage.setItem(LocalStorageKeys.COLUMNS_PENDING_PAY_HIDDEN, JSON.stringify(gridHiddenColumns));

      return { gridColumns, gridHiddenColumns };
    };

const actions = {
  setRequestTimeoutMs:
    (requestTimeoutMs: number) =>
      async ({ setState }: StoreApi) => {
        setState({ requestTimeoutMs });
      },
  cancelRequests:
    () =>
      async ({ dispatch, getState }: StoreApi) => {
        dispatch(stopProgressNotififer());
        const token = getState().axiosCancelToken;
        if (token) {
          token.cancel("PendingPayContext was requested to be cancelled");
        }
      },
  getPendingPayListLocal: (criteria: PendingPayCriteriaSearch) =>
    async ({ getState }: StoreApi) => {
      const response = getState().selectedItems;
      const filteredReportPayList = response
        ? response.filter(
          (reportPayFile) =>
            getPendingPayByCriteria(reportPayFile, criteria))
        : [];
      if (filteredReportPayList.length > 0) {
        const size = criteria.listCount || 10;
        filteredReportPayList[0].totalRows = filteredReportPayList.length;
        filteredReportPayList[0].totalPages = filteredReportPayList.length / size;
        filteredReportPayList[0].currentPage = criteria.requestedPage || 1;
      }
      return filteredReportPayList;
    },
  getPendingPayList:
    (criteria: PendingPayCriteriaSearch) =>
      async ({ dispatch, setState, getState }: StoreApi) => {
        try {
          dispatch(startProgressNotififer(criteria));

          const token = getState().axiosCancelToken;
          if (token) {
            token.cancel("PendingPayContext was cancelled due to new request");
          }

          const newCancelToken = cancelToken.source();
          setState({ error: undefined, axiosCancelToken: newCancelToken });

          const { data } = await axiosSecuredInstance.post(
            "/reportandpay/pendingpaymentsbycriteria",
            criteria,
            {
              cancelToken: newCancelToken.token,
              timeout: getState().requestTimeoutMs,
              timeoutErrorMessage: "TIMEOUT",
            }
          );
          dispatch(stopProgressNotififer(criteria));


          return dispatch(setPendingPayList(data));
        } catch (error: any) {
          setState({ error: error.message });
          dispatch(stopProgressNotififer());
        }
      },
  getAllPendingPayList:
    (criteria: PendingPayCriteriaSearch) =>
      async () => {
        try {
          const { data } = await axiosSecuredInstance.post(
            "/reportandpay/pendingpaymentsbycriteria",
            criteria,
            {
              timeoutErrorMessage: "TIMEOUT",
            }
          );
          return mapPendingPayFromApiToUIModel(data);
        } catch (error: any) {
          return [];
        }
      },
  getAdvancedSearchFiles:
    (criteria: PendingPayKeywordSearch) =>
      async ({ dispatch, setState, getState }: StoreApi) => {
        try {
          dispatch(startProgressNotififer(criteria));

          const token = getState().axiosCancelToken;
          if (token) {
            token.cancel("PendingPayContext was cancelled due to new request");
          }

          const newCancelToken = cancelToken.source();
          setState({ error: undefined, axiosCancelToken: newCancelToken });

          const { data } = await axiosSecuredInstance.post(
            "/reportandpay/pendingpaymentsbykeyword",
            criteria,
            {
              cancelToken: newCancelToken.token,
              timeout: getState().requestTimeoutMs,
              timeoutErrorMessage: "TIMEOUT",
            }
          );
          dispatch(stopProgressNotififer(criteria));

          return dispatch(setPendingPayList(data));
        } catch (error: any) {
          setState({ error: error.message });
        }
      },
  getInitialColumnsDefintion:
    (hidden: boolean) =>
      () => {
        const key = hidden
          ? LocalStorageKeys.COLUMNS_PENDING_PAY_HIDDEN
          : LocalStorageKeys.COLUMNS_PENDING_PAY;
        const existingColumns = localStorage.getItem(key) || "[]";

        return JSON.parse(existingColumns);
      },
  getColumnsDefinition:
    () =>
      async ({ dispatch }: StoreApi) => {
        const { data } = await axiosSecuredInstance.get(
          "/UISettings/grids/columnSettings/pendingpay"
        );

        return dispatch(setColumns(data));
      },
  setColumnDefinition:
    (
      fields: (keyof PendingPayFile)[],
      propName: keyof Omit<IColumn, "field" | "name">,
      value: string | number | boolean | undefined
    ) =>
      async ({ dispatch }: StoreApi) => {
        const data: IColumn[] = fields.map((field) => ({
          field,
          name: "", //NOT REQUIRED,
          [propName]: value,
        }));

        try {
          const response = await axiosSecuredInstance.post("/UISettings/grids/columnSettings/pendingpay", data);
          if (response.data) {
            dispatch(setColumns(response.data));
          }
        } catch (error) {
          console.error(error);
        }
      },
  setRefresh: () =>
    ({ setState, getState }: StoreApi) =>
      setState({ refreshData: !getState().refreshData }),
  setOnlyShowSelectedFiles: (value: boolean) => ({ setState }: StoreApi) => {
    setState({ onlyShowSelectedFiles: value });
  },
  updateSelectedItem: (item: PendingPayFile) => ({ setState, getState }: StoreApi) => {
    const currentItems = getState().selectedItems || [];
    const fileId = item.fileId;
    const paymentSheetID = item.paymentSheetID;

    let updatedItems = currentItems.filter(f => !(f.fileId === fileId && f.paymentSheetID === paymentSheetID) );
    // console.log(JSON.stringify({
    //   selectedItems: currentItems,
    //   item: item,
    //   updatedItemsBefore: updatedItems,
    // }));
    if (updatedItems.length === currentItems.length) {
      updatedItems.push(item);
    }
    // console.log(JSON.stringify({
    //   updatedItemsAfter: updatedItems,
    // }));

    let updateShowSelectedFiles = getState().onlyShowSelectedFiles;
    if (updateShowSelectedFiles && (updatedItems.length === currentItems.length)) {
      updateShowSelectedFiles = false;
    }

    setState({
      selectedItems: updatedItems,
      onlyShowSelectedFiles: updateShowSelectedFiles,
    });
  },
  updateSelectedItems: (items: PendingPayFile[]) => ({ setState, getState }: StoreApi) => {
    const currentItems = getState().selectedItems || [];
    const newItems = items.filter(item => !currentItems.find(obj => obj.fileId === item.fileId && obj.paymentSheetID === item.paymentSheetID));

    let updatedItems: PendingPayFile[] = [];
    if (newItems.length > 0) {
      updatedItems = currentItems.concat(newItems);
    }

    let updateShowSelectedFiles = getState().onlyShowSelectedFiles;
    if (updateShowSelectedFiles && (updatedItems.length === currentItems.length)) {
      updateShowSelectedFiles = false;
    }

    setState({
      selectedItems: updatedItems,
      onlyShowSelectedFiles: updateShowSelectedFiles,
    });
  },
  clearSelectedItems: () => ({ setState }: StoreApi) => {
    setState({ selectedItems: [] });
  },
  restoreSelectedItems: () => ({ setState }: StoreApi) => {
    setState({
      selectedItems: [],
      onlyShowSelectedFiles: false,
      isAdvSearchOpen: false
    });
  },
  setIsAdvSearchOpen: (value: boolean) => ({ setState }: StoreApi) => {
    setState({ isAdvSearchOpen: value });
  },
};

const Store = createStore<State, Actions>({
  initialState: {
    progressPercent: -1,
    refreshData: false,
    requestTimeoutMs: 45000,
    selectedItems: [],
    onlyShowSelectedFiles: false,
    isAdvSearchOpen: false,
  },
  actions,
  name: "pendingpay",
});

const hook = createHook(Store);

export const usePendingPay = () => { return hook(); };
