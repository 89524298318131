import { styled } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import NumberInputFieldPricing from "controls/global/number-input-field/pricing/NumberInputFieldPricing";
import React from "react";
import {
  fontSize,
  fontWeight,
  padding,
} from "theme/defaultStyle";
import PricingProductStaticVal from "./PricingProductStaticVal";

const StyledTableBodyCell = styled(TableCell)({
  fontWeight: fontWeight.normal1,
  fontSize: fontSize.large,
  padding: padding.zero,
  "& > *": {
    width: "150px !important", // To show up to $999,999,999.11
  },
  "&:first-child": {
    paddingLeft: padding.zero,
  },
  "& input": {
    textAlign: "end",
  },
  "& .MuiInputBase-root": {
    display: "flex",
    justifyContent: "flex-end",
  },
});

type Props = {
  errorInfo?: any,
  hasErrorMessage?: boolean;
  isOverride?: boolean;
  name: string;
  value: number;
  isDisabled: boolean;
  isUpdatable: boolean;
  cellClassName?: string;
  notUpdatableClassName?: string;
  errorClassName?: string;
  isStandalone?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
};

export default function PricingProductNumberCell({
  errorInfo,
  hasErrorMessage = false,
  isOverride = false,
  name,
  value,
  isDisabled,
  isUpdatable,
  cellClassName,
  notUpdatableClassName,
  errorClassName = "errorMessageBigRedFloating",
  isStandalone = false,
  onChange,
  onBlur,
}: Props) {
  const hasOutline = errorInfo?.enableField && errorInfo?.outlineColor && !isOverride && !hasErrorMessage;
  const customStyles = {
    borderColor: hasOutline ? errorInfo?.outlineColor : "rgb(215,215,215,1)",
  };

  if (!isUpdatable) {
    return (
      <PricingProductStaticVal
        name={name}
        standalone={isStandalone}
        containerClassName={notUpdatableClassName}
        errorInfo={errorInfo}
      />
    );
  }

  return (
    <StyledTableBodyCell align="right" className={cellClassName}>
      <NumberInputFieldPricing
        name={name}
        value={value}
        disabled={isDisabled}
        errorClassName={errorClassName}
        onChange={onChange}
        onBlur={onBlur}
        size="small"
        styles={customStyles}
      />
    </StyledTableBodyCell>
  );
}
