import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import { styled } from "@mui/material";
import ActionButton from "controls/global/action-button";
import DatePickerField from "controls/global/datepicker";
import ErrorDialog from "controls/global/dialogs/error-dialog";
import PageSection from "controls/global/page-section";
import PdfViewer from "controls/global/pdf-viewer";
import ScrollToTopArrow from "controls/global/scroll-to-top-arrow";
import SelectField from "controls/global/select-field";
import {
  PdfDocument,
  Reports,
} from "entities/UIModel";
import { RegisterReport } from "entities/UIModel/RegisterReport";
import ReportsValidationSchema from "pages/file/utils/yup/schema/reportsValidationSchema";
import React, {
  useEffect,
  useState,
} from "react";
import {
  FormProvider,
  useForm,
} from "react-hook-form";
import {
  colors,
  margin,
} from "theme/defaultStyle";
import { AgencyLocation } from "utils/context/AgencyLocationsContext";
import { useAutomaticProgressDialogActions } from "utils/context/AutomaticProgressDialogContext";
import { useReportLookup } from "utils/context/ReportContext";
import { useStewartAccess } from "utils/context/StewartAccessContext";
import useTitle from "utils/custom-hooks/useTitle";
import { formatDateToJSONString } from "utils/shared";
import { v4 as uuidv4 } from "uuid";
import ReportAgencyField from "./ReportAgencyField";
import ReportAgencyLocationField from "./ReportAgencyLocationField";
import ReportStateField from "./ReportStateField";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import { PERMISSIONS } from "utils/data/enum";

const SUB_HEADING_TEXT = `The Policy Register Report contains files with either jackets issued or
jackets with effective dates during the given time frame. All CPLs/AALs and
endorsements issued in those files are also included in this report. Note, a
file with a CPL/AAL only will not be included in this report.`;

const StyledBoxContainer = styled(Box)({
  position: "relative",
});

const Dash = styled("div")({
  background: colors.grey11,
  height: "3px",
  margin: margin.small,
  width: "10px",
});

const CustomGridContainer = styled("div")({
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  marginTop: "-15px", //NOTE: This is needed to offset mui grid offsets until this is refactored to css flexbox
});

const CustomGrid = styled("div")({
  alignItems: "center",
  display: "flex",
});

const CustomGridItemMedium = styled("div")({
  width: "165px",
  marginRight: margin.xlarge1,
});

const CustomGridItemSmall = styled("div")({
  width: "145px",
});

function PolicyRegisterReport() {
  useTitle("Stewart Connect - Reports");

  const initialValues = {
    id: 0,
    isFileDefault: true,
    errorMessage: "",
    identityKey: 0,
    integrationKey: uuidv4(),
    fileNameNumber: "",
    agency: { id: "", name: "" },
    agencyLocation: { id: "", name: "", isValidationRequired: false },
    statusTypeCode: "",
    report: {
      id: 0,
      agency: { id: "", name: "" },
      state: [],
      agencyLocation: [],
      dateType: { name: "Effective Date", code: "EFFECTIVE_DATE" },
      beginDate: undefined,
      endDate: undefined,
      format: "",
    },
    transactionTypeCode: "",
  }; //useFiles();

  const methods = useForm({
    resolver: yupResolver(ReportsValidationSchema),
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: initialValues,
  });

  const { getValues, setValue, trigger, clearErrors, formState } = methods;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { errors, touchedFields } = formState;

  const agencyField = getValues(`report.agency`);
  const agencyLocation = getValues(`report.agencyLocation`);

  const [tempAgencyId, setTempAgencyId] = useState<string>();
  const [
    { agencies, agencyLocations, agencyStates },
    { getAgencies, getAgencyStates, getAgencyLocations },
  ] = useReportLookup();
  const [agencyValueChanged, setAgencyValueChanged] = useState("");
  const [agencyStatesRetrieved, setAgencyStatesRetrieved] = useState(false);
  const [currentAgencyId, setCurrentAgencyId] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  // PDF Dialog
  const [openPdfViewer, setOpenPdfViewer] = useState(false);
  const [pdfDocuments, setPdfDocuments] = useState<PdfDocument[]>([]);
  const [reportSelections, setReportSelections] = useState<Reports>();
  const [dateFrom, setDateFrom] = useState<Date | undefined>();
  const [dateTo, setDateTo] = useState<Date | undefined>();

  const [dateFromError, setDateFromError] = useState<boolean>(false);
  const [dateToError, setDateToError] = useState<boolean>(false);
  const [, { getReportsString }] = useStewartAccess();
  const [, { openAutomaticProgressDialog, closeAutomaticProgressDialog }] =
    useAutomaticProgressDialogActions();
  const [{ selectedAgency }] = useGlobalAccess(); 
  const [{ profileSettings }] = useProfileSettingsCache();

  useEffect(() => {
    const currentFormat = getValues(`report.format`);
    if (!currentFormat) {
      setValue(`report.format`, "pdf");
    }

    if (agencies.length === 0) getAgencies();
    if (agencies.length === 1) {
      setValue("report.agency", {
        id: agencies[0].value,
        name: agencies[0].text,
      });
      setIsDisabled(false);
    }
  }, [agencies]);

  useEffect(() => {
    if (agencyLocation.toString() !== "") {
      setValue(`report.agencyLocation`, agencyLocation);
    }
  }, [agencyLocation]);

  useEffect(() => {
    let agency = getValues(`report.agency`);
    if (
      (agency && agency.id !== currentAgencyId) ||
      (!agencyStatesRetrieved && agency?.id !== "")
    ) {
      getAgencyStates(agency?.id);
      setAgencyStatesRetrieved(true);
      setCurrentAgencyId(agency ? agency?.id : currentAgencyId);
    } else {
      agency = getValues(`report.agency`);
      if (agency?.id !== "" && !agencyStatesRetrieved) {
        //setValue(`report.agency`, agency);
        setCurrentAgencyId(agency ? agency?.id : currentAgencyId);
      }
    }
  }, [agencyValueChanged, agencyField, agencyStatesRetrieved]);

  useEffect(() => {
    const currentAgency = getValues(`report.agency`);
    if (currentAgency?.id && currentAgency?.id !== tempAgencyId) {
      getAgencyLocations(currentAgency.id);
      setTempAgencyId(currentAgency.id);
    }
  }, [agencyValueChanged, agencyField, tempAgencyId, agencies]);

  useEffect(() => {
    const agencyField = getValues("report.agency");    
    const disabled = agencyField && !agencyField.id && !selectedAgency?.[0]?.CompanyID;    
    setIsDisabled(disabled);
  }, [agencyValueChanged, getValues, selectedAgency]);

  let statesWithDefaultOption = agencyStates;
  statesWithDefaultOption = [
    { text: "All", value: "all" },
    ...statesWithDefaultOption,
  ];

  let agencyLocationOptions: AgencyLocation[] = agencyLocations;
  agencyLocationOptions =
    agencyLocations?.length >= 1
      ? [
        { text: "", id: "", value: "", selected: false },
        { text: "All", id: "all", value: "All", selected: true },
        ...agencyLocationOptions,
      ]
      : agencyLocations;

  const internalSubmit = async () => {
    clearErrors();

    const isFormValid = await trigger();
    if (!isFormValid) return;

    const report: RegisterReport = getValues("report");
    const documents: Array<PdfDocument> = getResultDocuments();

    if (report.format === "pdf") {
      if (documents.length > 0) {
        setPdfDocuments(documents);
        setOpenPdfViewer(true);
      }
    } else if (documents?.length > 0) {
      openAutomaticProgressDialog();
      const reportQuery = getReportData();
      const reportData = await getReportsString(reportQuery);
      closeAutomaticProgressDialog();

      if (reportData?.error?.response?.status === 403) {
        // Notes: Navigate causes infinite loop here when there useEffect within the page, it was due to existing bugs in react router for this case
        //redirect("/404", null);
        window.location.href = "/404"; // Temporary will into more about react router
      }

      if (reportData && reportData.reportString && reportData.error === "") {
        const reportName = getReportFilename();

        const byteCharacters = atob(reportData.reportString);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", reportName);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        document.body.removeChild(link);
      }
    }
  };

  const onChangeDateType = async (event: any, selectedOptionValue: any) => {
    setValue("report.dateType", selectedOptionValue);
  };
  const dateTypes = [
    { text: "Effective Date", value: "EFFECTIVE_DATE" },
    { text: "Issue Date", value: "ISSUE_DATE" },
  ];

  const handleOnAgencyChange = (selectedAgency: any) => {
    setValue(`report.agency`, {
      id: selectedAgency.value,
      name: selectedAgency.text,
    });
    if (selectedAgency && selectedAgency.value)
      setAgencyValueChanged(selectedAgency.value);
  };

  const hasError = () =>
    errors.report !== undefined ||
    (errors.agency !== undefined && touchedFields.agency !== undefined) ||
    (errors.agencyLocation !== undefined &&
      touchedFields.agencyLocation !== undefined);

  const isValidDate = (date?: Date | null) =>
    Boolean(date && !isNaN(date.getTime()));

  const getReportData = () => {
    const report = getValues("report");

    const beginDate = report.beginDate
      ? formatDateToJSONString(report.beginDate, true)
      : "";
    const endDate = report.endDate
      ? formatDateToJSONString(report.endDate, true)
      : "";

    const reportQuery: Reports = {
      agency: report.agency.id,
      state:
        report.state.toString() !== "All" ? report.state.toString() : "ALL",
      agencyLocation: prepareReportQueryAgencyLocations(),
      dateType: report.dateType.code,
      beginDate: beginDate || "",
      endDate: endDate || "",
      reportFormat: report.format,
    };

    return reportQuery;
  };

  const prepareReportQueryAgencyLocations = () => {
    const currentAgencyLocation: any = getValues(`report.agencyLocation`);

    if (
      !currentAgencyLocation ||
      (currentAgencyLocation && currentAgencyLocation.length === 0)
    )
      return "";
    if (
      currentAgencyLocation.length === 1 &&
      currentAgencyLocation[0].toUpperCase() === "ALL"
    )
      return "ALL";

    let alQuery = "";
    currentAgencyLocation.forEach((al: any) => {
      const matchingAL = agencyLocationOptions?.find((alo) => alo.text === al);
      if (matchingAL)
        alQuery = alQuery
          ? alQuery + "~" + matchingAL.id + "|" + matchingAL.text
          : matchingAL.id + "|" + matchingAL.text;
    });

    return alQuery;
  };

  const handleOnClosePdfViewer = () => {
    setOpenPdfViewer(false);
  };

  const handleDateFromChange = (newDate: any) => {
    if (!isValidDate(newDate)) {
      setDateFromError(true);
    } else if (newDate != null) {
      setValue("report.beginDate", newDate);
      setDateFrom(newDate);
      setDateToError(false);
    }
  };

  const handleDateToChange = (newDate: any) => {
    if (!isValidDate(newDate)) {
      setDateToError(true);
    } else if (newDate != null) {
      setValue("report.endDate", newDate);
      setDateTo(newDate);
      setDateToError(false);
    }
  };

  const getReportFilename = () => {
    const report = getValues("report");
    const reportName = `${report.agency.name} ${report.state} Policy Register Report.${report.format}`;

    return reportName;
  };

  const getResultDocuments = () => {
    let documents: Array<PdfDocument> = [];
    const report = getValues("report");

    if (report) {
      const documentId = 1;

      if (documentId > 0) {
        const reportQuery = getReportData();

        setReportSelections(reportQuery);

        const pdfFilename = getReportFilename();
        documents.push({
          fileId: 0,
          productType: "",
          orderId: 0,
          documentId: documentId,
          fileName: pdfFilename,
          showHidden: 0,
        });
      }
    }

    return documents;
  };
  const isViewReportsDisabled = !(profileSettings?.userPermissionItemTypes as string[] | undefined)?.includes(
    PERMISSIONS.VIEW_REPORTS,
  );

  if (isViewReportsDisabled) {
    // Notes: Navigate causes infinite loop here when there useEffect within the page, it was due to existing bugs in react router for this case
    // redirect("/404", null);
    window.location.href = "/404"; // Temporary will into more about react router
  } 

  return isViewReportsDisabled ? (
      null
    ) : (
      <StyledBoxContainer>
        <FormProvider {...methods}>
          <form noValidate>
            <PageSection
              icon={<InsertChartIcon />}
              title="Policy Register Report"
              isError={hasError()}
              contentAlignment="below-header"
            >
              <>
                <Typography className="subheading">{SUB_HEADING_TEXT}</Typography>
                <Grid 
                  container 
                  spacing={3} 
                  style={{ marginRight: "0px" }}
                >
                  <Grid 
                    item 
                    xs={12} 
                    sm={12} 
                    md={4} 
                    lg={3}
                  >
                    <ReportAgencyField
                      agencies={agencies}
                      onChange={handleOnAgencyChange}
                    />
                  </Grid>
                  <Grid 
                    item 
                    xs={12} 
                    md={2} 
                    lg={2}
                  >
                    <ReportStateField
                      name={"report.state"}
                      states={statesWithDefaultOption}
                      labelPrefix=""
                      isDisabled={isDisabled}
                    />
                  </Grid>
                  <Grid 
                    item 
                    xs={12} 
                    sm={12} 
                    md={6} 
                    lg={7}
                  >
                    <ReportAgencyLocationField
                      label="Agency Location"
                      name={"report.agencyLocation"}
                      disabled={isDisabled}
                      reportAgencyLocations={agencyLocationOptions}
                    />
                  </Grid>
                </Grid>
                <CustomGridContainer>
                  <CustomGrid>
                    <CustomGridItemMedium>
                      <SelectField
                        label={`Date Type`}
                        options={dateTypes}
                        name={`report.dateType`}
                        dataTextField="name"
                        dataValueField="code"
                        disabled={false}
                        onChange={onChangeDateType}
                      />
                    </CustomGridItemMedium>
                    <CustomGridItemSmall>
                      <DatePickerField
                        validateOnBlur={false}
                        disabled={false}
                        name="report.beginDate"
                        maxDate={dateTo}
                        onChangeCallback={handleDateFromChange}
                        showError={dateFromError}
                        label="mm/dd/yyyy"
                      />
                    </CustomGridItemSmall>
                    <Dash />
                    <CustomGridItemSmall>
                      <DatePickerField
                        validateOnBlur={false}
                        disabled={false}
                        name="report.endDate"
                        minDate={dateFrom}
                        onChangeCallback={handleDateToChange}
                        showError={dateToError}
                        label="mm/dd/yyyy"
                      />
                    </CustomGridItemSmall>
                  </CustomGrid>
                  <ActionButton
                    variant="outlined"
                    color="primary"
                    onClick={internalSubmit}
                    disabled={isDisabled}
                    startIcon={<CheckIcon />}
                  >
                    Submit
                  </ActionButton>
                </CustomGridContainer>
              </>
            </PageSection>
          </form>
        </FormProvider>
        <ErrorDialog 
          isOpen={false} 
          confirmationMessage={""} 
        />
        <PdfViewer
          isOpen={openPdfViewer}
          reports={reportSelections}
          isReports={true}
          onClose={handleOnClosePdfViewer}
          pdfDocuments={pdfDocuments}
        />
        <ScrollToTopArrow />
      </StyledBoxContainer>
    );
}

export default PolicyRegisterReport;
