import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import {
  Jacket,
  JacketFormDetail,
} from "entities/UIModel";
import { TransCode } from "utils/context/StewartAccessContext";
import { Translation } from "utils/context/TranslationContext";
import {
  JacketFormDetailName,
  JacketFormType,
  OrderStatusType,
} from "utils/data/enum";
import { getFormDetailTranslatedName } from "../helper";

const isValidPairForSimultaneous = (jackets: Jacket[]) => {
  let isValidPair = false;

  if (
    jackets.length >= 2 &&
    ((jackets[0].formType === JacketFormType.Loan &&
      jackets[1].formType === JacketFormType.Owners) ||
      (jackets[0].formType === JacketFormType.Owners &&
        jackets[1].formType === JacketFormType.Loan))
  ) {
    isValidPair = true;
  }

  return isValidPair;
};

const isFormTypeValidForSimultaneous = (type: string) =>
  type === JacketFormType.Loan.toString() ||
  type === JacketFormType.Owners.toString();

const isAllowedJacketType = (currentJacket: Jacket, nextJacket: Jacket) =>
  currentJacket && nextJacket && currentJacket.formType !== nextJacket.formType;

const isJacketValidForSimultaneous = (jacket: Jacket) =>
  jacket &&
  isFormTypeValidForSimultaneous(jacket.formType) &&
  (!jacket.orderStatusTypeCode ||
    jacket.orderStatusTypeCode === OrderStatusType.Pending) &&
  !jacket.pricingDetail?.isSimultaneous;

const shouldUncheckSimultaneousJacket = (jacket: Jacket) =>
  jacket &&
  (!jacket.orderStatusTypeCode ||
    jacket.orderStatusTypeCode === OrderStatusType.Pending) &&
  jacket.pricingDetail?.isSimultaneous;

const combineFileAndLookupFormDetails = (
  currentFormID: string,
  orderStatusTypeCode: string,
  validBorrowerName: string,
  isRevising: boolean,
  existingFormDetails?: Array<JacketFormDetail>,
  apiFormDetails?: Array<JacketFormDetail>,
  translations?: Array<Translation>
) => {
  let fileAndApiFormDetails: JacketFormDetail[] = [];
  let missingFormDetails: JacketFormDetail[] = [];

  if (apiFormDetails && apiFormDetails?.length > 0) {
    fileAndApiFormDetails = apiFormDetails?.map((afd) => {
      if (existingFormDetails && existingFormDetails.length > 0) {
        let existingDetail = existingFormDetails.find(
          (efd) => efd.formDetailName === afd.formDetailName
        );

        if (!existingDetail && translations && translations.length > 0) {
          const translationMatch = checkForFormDetails(
            afd.formDetailName || "",
            existingFormDetails,
            translations
          );
          if (translationMatch) existingDetail = translationMatch;
        }

        if (existingDetail) {
          //Translate formDetailNames
          existingDetail.formDetailDisplayName = getFormDetailTranslatedName(
            existingDetail.formDetailName,
            translations,
            true
          );
          if (
            isRevising ||
            (orderStatusTypeCode !== OrderStatusType.Issued &&
              orderStatusTypeCode !== OrderStatusType.Voided)
          ) {
            existingDetail.formID = currentFormID;
            existingDetail.formDetailID = afd.formDetailID;
          }
          return existingDetail; // return the combined form detail
        }
      }

      // No existingDetail found
      afd.formDetailValue = getInitialValue(
        afd.formDetailName || "",
        validBorrowerName
      );
      missingFormDetails.push(afd);
      return afd;
    });
  }

  return { fileAndApiFormDetails, missingFormDetails };
};

const checkForFormDetails = (
  searchItem: string,
  existingFormDetails?: Array<JacketFormDetail>,
  translations?: Array<any>
) => {
  const matchingTranslation = translations?.find(
    (t) => t.source1 === searchItem || t.target1 === searchItem
  );
  if (matchingTranslation) {
    const matchFound = existingFormDetails?.find(
      (fd) =>
        matchingTranslation.source1 === fd.formDetailName ||
        matchingTranslation.target1 === fd.formDetailName
    );
    return matchFound;
  }
};

const getInitialValue = (name: string, validBorrowerName?: string) => {
  if (name === JacketFormDetailName.BorrowerNamesAndVesting)
    return validBorrowerName;
  if (name === JacketFormDetailName.AddendumAttached) return "N";
};

const buildTransCodeOption = (
  transCode: TransCode,
  currentTransCode: string
): SelectFieldOption => {
  const value = transCode["@Value"];
  const description = transCode["@Description"];
  const key = buildTransCodeKey(value, description);

  return {
    text: key,
    value: key,
    desc: description,
    selected: Boolean(currentTransCode === value),
    originalValue: value,
  };
};

const buildTransCodeKey = (code?: string, desc?: string): string => {
  if (!code || !desc) return "";
  return `${code} - ${desc}`;
};

// PricingConfig-Value template: "XXX - Description"
// ex: 123 - Default TransCode (Jacket)
const getTransCodeKey = (
  text: string
): { code: string; description: string; } => {
  const idx = text.indexOf("-");
  const [code, desc] = [text.slice(0, idx), text.slice(idx + 1)];

  return {
    code: code ? code.trim() : "",
    description: desc ? desc.trim() : "",
  };
};

const setDirtyInQnA = (apiSCFile: any, jacketInQnA: Jacket) => {
  const { integrationKey } = jacketInQnA;
  const { JacketProducts } = apiSCFile;
  const dirtyJacketProducts = JacketProducts.map((product: any) => {
    if (product.IntegrationKey === integrationKey) {
      const dirtyEndorsements = product.Endorsements.map((endorsement: any) => {
        return {
          ...endorsement,
          IsDirty: true,
        };
      });
      return {
        ...product,
        Endorsements: dirtyEndorsements,
        IsDirty: true,
      };
    } else return product;
  });

  return {
    ...apiSCFile,
    JacketProducts: dirtyJacketProducts,
  };
};

export {
  buildTransCodeKey, buildTransCodeOption, combineFileAndLookupFormDetails, getTransCodeKey, isAllowedJacketType, isFormTypeValidForSimultaneous, isJacketValidForSimultaneous,
  isValidPairForSimultaneous, setDirtyInQnA, shouldUncheckSimultaneousJacket
};

