import * as APIModel from "entities/ApiModel";
import * as UIModel from "entities/UIModel";

import { AgencyLocation as LookupAgencyLocation } from "utils/context/AgencyLocationsContext";
import { CPL } from "entities/UIModel";
import { Jacket } from "entities/UIModel";
import { EndorsementStatusType, OrderStatusType } from "utils/data/enum";
import { PartyRoleType } from "utils/data/enum";
import { PartyType } from "utils/data/enum";
import { PricingOriginalPolicy } from "entities/UIModel";
import { PricingProduct } from "entities/UIModel";
import { PricingProductDetail } from "entities/UIModel";
import { PricingProductItem } from "entities/UIModel";
import { PricingType } from "utils/data/enum";
import { ProductAction } from "utils/data/enum";
import { ProductType } from "utils/data/enum";
import { RemittanceSplitType } from "utils/data/enum";
import { SCFile } from "entities/UIModel";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import { Translation } from "utils/context/TranslationContext";

import { convertToNumber } from "utils/shared";
import { get } from "lodash";
import { hasValue } from "utils/shared";
import { roundToPrecision } from "utils/shared";

const checkIfFileIsLocked = (isLocked: number): boolean => {
  return isLocked === 1;
};

const isValidParty = (fileParty: UIModel.GeneralFileParty): boolean =>
  !fileParty.isDeleted &&
  ((fileParty.partyTypeCode === PartyType.Individual &&
    hasNotNullOrEmptyProp(fileParty, ["partyFirstName", "partyLastName"])) ||
    hasNotNullOrEmptyProp(fileParty, ["companyName"]));

const isValidBuyerBorrower = (fileParty: UIModel.GeneralFileParty): boolean =>
  !fileParty.isDeleted &&
  ((fileParty.partyTypeCode === PartyType.Individual &&
    hasNotNullOrEmptyProp(fileParty, ["partyFirstName"]) &&
    hasNotNullOrEmptyProp(fileParty, ["partyLastName"])) ||
    (fileParty.partyTypeCode === PartyType.BusTrustEstate &&
      hasNotNullOrEmptyProp(fileParty, ["companyName"])));

const getValidParties = (parties: UIModel.GeneralFileParty[]) =>
  parties.filter((party) => isValidParty(party));

const isSavableParty = (fileParty: UIModel.GeneralFileParty): boolean => {
  if (fileParty.isDeleted) return false;
  if (fileParty.isDirty) return true;

  if (
    fileParty.partyTypeCode === PartyType.Individual &&
    hasNotNullOrEmptyProp(fileParty, [
      "partyFirstName",
      "partyLastName",
      "partyMiddleName",
    ])
  )
    return true;

  if (hasNotNullOrEmptyProp(fileParty, ["companyName"])) return true;

  if (
    hasNotNullOrEmptyProp(fileParty, [
      "suffixType.code",
      "individualAttorney",
      "addressOne",
      "addressTwo",
      "city",
      "state.code",
      "zipCode",
      "loanNumber",
      "successorLanguage",
      "attentionTo",
    ]) ||
    fileParty.isForeignAddress === 1
  )
    return true;

  return false;
};

const isValidProperty = (property: UIModel.Property): boolean => {
  return hasNotNullOrEmptyProp(property, [
    "addressOne",
    "addressTwo",
    "city",
    "state.code",
    "zipCode",
    "legalDescription",
    "county.code",
  ]);
};

const isValidLetter = (product: UIModel.CPL | UIModel.AAL ): boolean => {
  if (
    product.isDeleted ||
    (product.id === 0 &&
      product.orderStatusTypeCode !== OrderStatusType.Pending)
  ) {
    return false;
  } 

  return (
    Boolean(product.coveredParty) ||
    Boolean(product.form) ||
    Boolean(product.addresseeNames && product.addresseeNames.length > 0)
  );
};

const isValidJacket = (jacket: UIModel.Jacket): boolean => {
  if (
    jacket.isDeleted ||
    (jacket.id === 0 && jacket.orderStatusTypeCode !== OrderStatusType.Pending)
  ) {
    return false;
  } 

  return true;
};

const isValidEndorsement = (endorsement: UIModel.Endorsement): boolean => {
  if (endorsement.isSFE)
    return endorsement.isSelected === undefined ? true : endorsement.isSelected;

  return Boolean(endorsement.endorsementName);
};

const isIssuableEndorsement = (endorsement: UIModel.Endorsement): boolean => {
  return (
    !endorsement.endorsementID ||
    endorsement.endorsementStatusTypeCode === OrderStatusType.Pending
  );
};

const isValidStandalone = (
  standalone: UIModel.StandaloneEndorsement
): boolean => {
  if (
    standalone.isDeleted ||
    (standalone.id === 0 && standalone.orderStatusTypeCode !== OrderStatusType.Pending)
  ) {
    return false;
  } 

  let isValid: boolean = false;
  if (
    standalone.originalJacketDate &&
    !isNaN(new Date(standalone.originalJacketDate) as any) &&
    standalone.originalJacketDate !== new Date("0001/01/01") &&
    (standalone.originalJacketNumber || standalone.originalJacketType)
  ) {
    isValid = true;
  }

  return isValid;
};

const getDisabled = (val?: boolean) => {
  if (val) return { disabled: true };
  return {};
};

const isProductSectionAvailable = (
  availableProducts: string[],
  fileNameNumber?: string,
  agencyId?: string,
  agencyLocationId?: string,
  stateAbbr?: string
) => {
  let isAvailable = false;
  if (
    fileNameNumber &&
    agencyId &&
    agencyLocationId &&
    stateAbbr &&
    availableProducts.length > 0
  ) {
    isAvailable = true;
  }

  return isAvailable;
};

const hasNotNullOrEmptyProp = (obj: any, propNames: string[]) => {
  if (!obj) return false;
  return propNames.some((name) => {
    const value = get(obj, name, "");
    return (
      value &&
      value !== null &&
      typeof value === "string" &&
      value.trim() !== ""
    );
  });
};

const calculateRemittance = (
  productType: string,
  liabilityAmount: number,
  premium: number,
  remitSplitType: string | undefined,
  remitFlatPct: number,
  remitPerThousand: number,
  minRemitPerThousand: number,
  cplRemittancePct?: number,
  pricingType?: string
) => {
  if (pricingType && pricingType === PricingType.Tax) {
    return premium;
  }
  if (productType === ProductType.Cpl) {
    if (hasValue(premium) && hasValue(cplRemittancePct)) {
      return roundToPrecision((premium * cplRemittancePct!) / 100.0, 2);
    }
  } else {
    if (remitSplitType) {
      switch (remitSplitType.toUpperCase()) {
        case RemittanceSplitType.Flat: {
          if (hasValue(premium) && hasValue(remitFlatPct)) {
            return roundToPrecision((premium * remitFlatPct) / 100.0, 2);
          }
          break;
        }
        case RemittanceSplitType.CentPerThousand: {
          if (
            hasValue(liabilityAmount) &&
            (hasValue(remitPerThousand) || hasValue(minRemitPerThousand))
          ) {
            // Pick Greater of Remit per Thousand or Min Remit per Thousand
            let greaterRemitPerThousand = minRemitPerThousand;
            if (hasValue(greaterRemitPerThousand)) {
              if (
                hasValue(remitPerThousand) &&
                remitPerThousand > greaterRemitPerThousand
              ) {
                greaterRemitPerThousand = remitPerThousand;
              }
            } else {
              greaterRemitPerThousand = remitPerThousand;
            }
            if (hasValue(greaterRemitPerThousand)) {
              return roundToPrecision(
                Math.round(
                  Math.floor(liabilityAmount / 1000) * greaterRemitPerThousand
                ) / 100,
                2
              );
            }
          }
          break;
        }
        case RemittanceSplitType.Staggered: {
          break;
        }
      }
    }
  }
};

const getPartyPrefix = (partyRole: string) => {
  switch (partyRole) {
    case PartyRoleType.Lender.toString():
      return "lenderParties";
    case PartyRoleType.BuyerBorrower.toString():
      return "buyerBorrowerParties";
    case PartyRoleType.Seller.toString():
      return "sellerParties";
    default:
      return "";
  }
};

const maskZipCode = (val?: string) => {
  if (!val) return "";
  if (val.length === 10) return val;
  if (val.length === 9 && val.indexOf("-") === -1)
    return [val.slice(0, 5), "-", val.slice(5)].join("");
  if (val.length === 6 && val.charAt(val.length - 1) === "-")
    return val.slice(0, 5);
  if (
    val.length >= 6 &&
    val.charAt(val.length - 1) !== "-" &&
    val.indexOf("-") === -1
  )
    return [val.slice(0, 5), "-", val.slice(5)].join("");

  return val;
};

const fuzzyMatch = (str1: string, str2: string) => {
  if (str1 && str2) {
    return (
      str1.replace(/[^A-Z0-9]/gi, "").toLowerCase() ===
      str2.replace(/[^A-Z0-9]/gi, "").toLowerCase()
    );
  }
  return false;
};

const fuzzyMatchWithList = (
  str1: string,
  listValues: Array<SelectFieldOption>
) => {
  let matchingItem = null;
  if (str1 && listValues && listValues.length > 0) {
    matchingItem = listValues.find((lv: SelectFieldOption) =>
      fuzzyMatch(str1, lv.text)
    );
  }

  return matchingItem;
};

const normalizeValue = (maskedValue?: string) =>
  maskedValue ? maskedValue.replace(/[^0-9|^-]/g, "") : "";

const VALID_ZIP_LENGTH = [5, 10];
const isValidZipCode = (code?: string) =>
  code && code !== "" && VALID_ZIP_LENGTH.includes(code.length);

const getPropertiesCount = (
  properties: any,
  onlyWithValidZip: boolean = false
) => {
  if (properties && !onlyWithValidZip) return properties.length;
  const propertiesWithValidZip = properties?.filter((p: any) => p.zipCode);
  if (propertiesWithValidZip) return propertiesWithValidZip.length;
  return 0;
};

const hasPendingProducts = (scfile: UIModel.SCFile) => {
  if (
    scfile.jackets?.length > 1 ||
    scfile.cpls?.length > 1 ||
    (scfile.aALProducts && scfile.aALProducts?.length > 1) ||
    scfile.standaloneEndorsements?.length > 1
  ) {
    return true;
  }

  if (
    scfile.jackets?.length > 0 &&
    (scfile.jackets[0].id !== 0 || scfile.jackets[0].isDefault === false)
  ) {
    return true;
  }

  if (
    scfile.cpls?.length > 0 &&
    (scfile.cpls[0].id !== 0 || scfile.cpls[0].isDefault === false)
  ) {
    return true;
  }

  if (
    scfile.aALProducts &&
    scfile.aALProducts.length > 0 &&
    (scfile.aALProducts[0].id !== 0 ||
      scfile.aALProducts[0].isDefault === false)
  ) {
    return true;
  }

  if (
    scfile.standaloneEndorsements?.length > 0 &&
    (scfile.standaloneEndorsements[0].id !== 0 ||
      scfile.standaloneEndorsements[0].isDefault === false)
  ) {
    return true;
  }

  return false;
};

const hasAdditionalParty = (
  additionalParties: Array<UIModel.GeneralFileParty>
) => {
  if (
    additionalParties &&
    (additionalParties?.length > 1 ||
      (additionalParties?.length > 0 && isValidParty(additionalParties[0])))
  ) {
    return true;
  } else return false;
};

const shouldDefaultAddlParty = (
  initialValues: SCFile,
  addlParties: Array<UIModel.OrderParty>,
  productIndex: number,
  productType: string
) => {
  let retVal = true;

  if (!initialValues || (initialValues && initialValues.cpls.length === 0))
    return true;

  let letterProductOrderParties: any = [];
  const initialAddlParties = initialValues.additionalParties;

  if (
    productType === ProductType.Cpl &&
    initialValues.cpls &&
    initialValues.cpls.length > 0
  ) {
    if (!initialValues.cpls[productIndex]) return retVal;
    letterProductOrderParties = initialValues.cpls[productIndex].orderParties;
  } else if (
    productType === ProductType.Aal &&
    initialValues.aALProducts &&
    initialValues.aALProducts.length > 0
  ) {
    if (!initialValues.aALProducts[productIndex]) return retVal;
    letterProductOrderParties =
      initialValues.aALProducts[productIndex].orderParties;
  }
  // This is when there were additional parties
  if (
    letterProductOrderParties &&
    letterProductOrderParties.length === 0 &&
    initialAddlParties &&
    initialAddlParties.length > 0
  )
    return false;

  return retVal;
};

const isPropertyStateChangeWarningRequired = (scfile: UIModel.SCFile) => {
  return (
    hasPendingProducts(scfile) || hasAdditionalParty(scfile?.additionalParties)
  );
};

const isValidDate = (date?: Date | null) =>
  Boolean(date && !isNaN(date.getTime()));

const hasErrorOnRevise = (
  apiResponse: any,
  productType: string,
  integrationKey: string
) => {
  let hasError = false;
  if (apiResponse) {
    const scfile = apiResponse as SCFile;

    // check file level error
    if (scfile?.errorMessage) return true;

    // check product level error

    switch (productType) {
      case ProductType.Aal: {
        const errorAal = scfile.aALProducts?.find(
          (aal) => aal.integrationKey === integrationKey && aal.errorMessage
        );
        if (errorAal) hasError = true;
        break;
      }
      case ProductType.Cpl: {
        const errorCpl = scfile.cpls?.find(
          (cpl: CPL) =>
            cpl.integrationKey === integrationKey && cpl.errorMessage
        );
        if (errorCpl) hasError = true;

        break;
      }
      case ProductType.Jacket: {
        const errorJacket = scfile.jackets?.find(
          (jacket: Jacket) =>
            jacket.integrationKey === integrationKey && jacket.errorMessage
        );

        if (errorJacket) hasError = true;
        break;
      }
    }
  }

  return hasError;
};

// check if any error during any product action,
const hasErrorOnSingleProductAction = (
  apiResponse: any,
  productAction: string,
  productType: string,
  integrationKey: string,
  parentProductType?: string,
  parentOrderID?: number
) => {
  let hasError = false;
  if (apiResponse) {
    const scfile = apiResponse as APIModel.SCFile;

    // check file level error
    if (
      scfile?.ErrorMessage &&
      (productAction !== ProductAction.Void ||
        (productAction === ProductAction.Void &&
          scfile?.ErrorMessage?.startsWith(`${ProductAction.Void}:`)))
    ) {
      return true;
    }

    // check product level error
    switch (productType) {
      case ProductType.Aal: {
        const errorAal = scfile.AALProducts?.find(
          (aal) =>
            aal.IntegrationKey === integrationKey &&
            aal.ErrorMessage &&
            (productAction !== ProductAction.Void ||
              (productAction === ProductAction.Void &&
                aal.ErrorMessage?.startsWith(`${ProductAction.Void}:`)))
        );
        if (errorAal) hasError = true;
        break;
      }
      case ProductType.Cpl: {
        const errorCpl = scfile.CPLProducts?.find(
          (cpl) =>
            cpl.IntegrationKey === integrationKey &&
            cpl.ErrorMessage &&
            (productAction !== ProductAction.Void ||
              (productAction === ProductAction.Void &&
                cpl.ErrorMessage?.startsWith(
                  `${ProductAction.Void}:`
                )))
        );
        if (errorCpl) hasError = true;

        break;
      }
      case ProductType.Jacket: {
        const errorJacket = scfile.JacketProducts?.find(
          (jacket) =>
            jacket.IntegrationKey === integrationKey &&
            jacket.ErrorMessage &&
            (productAction !== ProductAction.Void ||
              (productAction === ProductAction.Void &&
                jacket.ErrorMessage?.startsWith(`${ProductAction.Void}:`)))
        );

        if (errorJacket) hasError = true;
        break;
      }
      case ProductType.Endorsement: {
        if (parentProductType && parentOrderID) {
          if (parentProductType === ProductType.Jacket) {
            const parentJacket = scfile.JacketProducts?.find(
              (jacket) => jacket.OrderID === parentOrderID
            );
            if (parentJacket) {
              if (parentJacket.ErrorMessage) {
                hasError = true;
              } else {
                const errorEndorsement = parentJacket.Endorsements?.find(
                  (e) => e.IntegrationKey === integrationKey && e.ErrorMessage
                );
                if (errorEndorsement) hasError = true;
              }
            }
          } else if (parentProductType === ProductType.StandaloneEndorsement) {
            const parentStandalone = scfile.StandaloneEndorsementProducts?.find(
              (standalone) => standalone.OrderID === parentOrderID
            );
            if (parentStandalone) {
              if (parentStandalone.ErrorMessage) {
                hasError = true;
              } else {
                const errorEndorsement = parentStandalone.Endorsements?.find(
                  (e) => e.IntegrationKey === integrationKey && e.ErrorMessage
                );
                if (errorEndorsement) hasError = true;
              }
            }
          }
        }
        break;
      }
    }
  }

  return hasError;
};

const getErrorMessageByProductAction = (productAction: string, errorMessage: string) => {
  return productAction === ProductAction.Void ? errorMessage.substring(`${ProductAction.Void}:`.length) : errorMessage;
}

const hasErrorOnAllProductsAction = (
  apiResponse: any,
  integrationKeys: string[]
) => {
  let hasError = false;
  if (apiResponse) {
    const scfile = apiResponse as APIModel.SCFile;

    // check file level error
    if (scfile?.ErrorMessage) return true;

    // check product level error
    const errorAal = scfile.AALProducts?.find(
      (aal) => integrationKeys.includes(aal.IntegrationKey) && aal.ErrorMessage
    );
    if (errorAal) return true;

    const errorCpl = scfile.CPLProducts?.find(
      (cpl) => integrationKeys.includes(cpl.IntegrationKey) && cpl.ErrorMessage
    );
    if (errorCpl) return true;

    const errorJacket = scfile.JacketProducts?.find(
      (jacket) =>
        integrationKeys.includes(jacket.IntegrationKey) && jacket.ErrorMessage
    );
    if (errorJacket) return true;

    const errorStandone = scfile.StandaloneEndorsementProducts?.find(
      (standlone) =>
        integrationKeys.includes(standlone.IntegrationKey) &&
        standlone.ErrorMessage
    );
    if (errorStandone) return true;
  }

  return hasError;
};

const getEndOfDay = (date: Date) => new Date(date.setHours(23, 59, 59, 999));

const getProductLocations = (
  locations: any[],
  productLocationID?: string,
  productLocationName?: string,
  productOrderStatusTypeCode?: string,
  isReadOnly?: boolean
) => {
  let productAgencyLocations: LookupAgencyLocation[] = locations?.map(
    (location) => {
      return {
        id: location.locationUniqueID || "",
        text: location.text || "",
        value: location.value || "",
        disabled: false,
      };
    }
  );

  // For issued/voided product;
  // - add the issued location into product's locations when it's not in file's locations
  if (
    productLocationName &&
    (productOrderStatusTypeCode === OrderStatusType.Issued ||
      productOrderStatusTypeCode === OrderStatusType.Voided ||
      isReadOnly)
  ) {
    const foundLocation = locations?.find(
      (location) => location.text === productLocationName
    );    
    if (!foundLocation) {
      productAgencyLocations.push({
        id: productLocationID || "",
        text: productLocationName,
        value: productLocationName,
        disabled: true,
      });
    }
  }

  productAgencyLocations.sort((a, b) => a.text.localeCompare(b.text));

  return productAgencyLocations;
};

const mapPricingTax = (
  actualPremiumTaxSum: number,
  calculatedPremiumTaxSum: number,
  isBillable: number,
  isBilled: number,
  isReadyToBeBilled: number
): APIModel.FilePricingDetail => {
  return {
    Liability: 0,
    ActualFee: actualPremiumTaxSum,
    CalculatedFee: calculatedPremiumTaxSum,
    ActualRiskRate: actualPremiumTaxSum,
    CalculatedRiskRate: calculatedPremiumTaxSum,
    ActualRemittance: actualPremiumTaxSum,
    CalculatedRemittance: calculatedPremiumTaxSum,
    ActualRetention: 0,
    CalculatedRetention: 0,
    ActualPremiumTax: 0,
    CalculatedPremiumTax: 0,
    IsBillable: isBillable,
    IsBilled: isBilled,
    IsReadyToBeBilled: isReadyToBeBilled,

    // not used for tax but required
    IntegrationKey: "",
    FileID: 0,
    FilePricingDetailID: 0,
    FilePricingID: 0,
    OrderID: 0,
  };
};

const mapPricingProduct = (
  pricingType: string,
  productType: string,
  orderId: number,
  productId: number,
  filePricingDetailId: number,
  formType: string,
  product: string,
  sortOrder: string,
  sortOrder2: number,
  issueDate: Date,
  orderStatusDate: Date,
  productItems: PricingProductItem[],
  pricingDetail?: APIModel.FilePricingDetail,
  rateTypes?: SelectFieldOption[],
  jacketIntegrationKey?: string,
  originalPolicy?: PricingOriginalPolicy,
  actualFeeSum?: number
): PricingProduct => {
  return {
    pricingType: pricingType,
    filePricingDetailId: filePricingDetailId,
    orderId: orderId,
    productId: productId,
    productType: productType,
    formType: formType,
    product: product,
    liability: pricingDetail?.Liability || 0,
    actualFee: pricingDetail?.ActualFee || 0,
    actualFeePrevious: pricingDetail?.ActualFee || 0,
    actualFeeInitial: pricingDetail?.ActualFee || 0,
    calculatedFee: pricingDetail?.CalculatedFee || 0,
    calculatedFeePrevious: pricingDetail?.CalculatedFee || 0,
    agentRetention: pricingDetail?.ActualRetention || 0,
    agentRetentionPrevious: pricingDetail?.ActualRetention || 0,
    agentRetentionInitial: pricingDetail?.ActualRetention || 0,
    calculatedRetention: pricingDetail?.CalculatedRetention || 0,
    calculatedRetentionPrevious: pricingDetail?.CalculatedRetention || 0,
    totalDue: pricingDetail?.ActualRemittance || 0,
    totalDuePrevious: pricingDetail?.ActualRemittance || 0,
    totalDueInitial: pricingDetail?.ActualRemittance || 0,
    calculatedTotalDue: pricingDetail?.CalculatedRemittance || 0,
    calculatedTotalDuePrevious: pricingDetail?.CalculatedRemittance || 0,
    actualRiskRate: pricingDetail?.ActualRiskRate || 0,
    actualRiskRatePrevious: pricingDetail?.ActualRiskRate || 0,
    actualRiskRateInitial: pricingDetail?.ActualRiskRate || 0,
    calculatedRiskRate: pricingDetail?.CalculatedRiskRate || 0,
    calculatedRiskRatePrevious: pricingDetail?.CalculatedRiskRate || 0,
    actualPremiumTax: pricingDetail?.ActualPremiumTax || 0,
    actualPremiumTaxPrevious: pricingDetail?.ActualPremiumTax || 0,
    calculatedPremiumTax: pricingDetail?.CalculatedPremiumTax || 0,
    calculatedPremiumTaxPrevious: pricingDetail?.CalculatedPremiumTax || 0,
    productItems: productItems,
    sortOrder: sortOrder,
    sortOrder2: sortOrder2,
    issueDate: issueDate,
    orderStatusDate: orderStatusDate,
    actualFeeUpdated: false, // pricingDetail?.IsDirty || false,
    isBilled: pricingDetail?.IsBilled,
    //lastReportedDate: pricingDetail?.LastReportedDate,
    isBillable: pricingDetail?.IsBillable,
    isReadyToBeBilled: pricingDetail?.IsReadyToBeBilled,
    altPricingReferenceID: pricingDetail?.AltPricingReferenceID,
    lastErrorMessage: pricingDetail?.LastErrorMessage,
    // TESTING DATA ONLY: will be replaced by pricingDetails?.LastDisplayMessage,
    //lastDisplayMessage: "This is testing error message|red", //|GREY',
    lastDisplayMessage: pricingDetail?.LastDisplayMessage,
    //isManualFee: pricingDetail?.IsManualFee || 0,
    isFeeError: pricingDetail?.IsFeeError || 0,
    transCode: pricingDetail?.TransCode,
    transCodeDescription: pricingDetail?.TransCodeDescription,
    pricingRateType: pricingDetail?.PricingRateType,
    pricingRateTypeData: pricingDetail?.PricingRateTypeData,
    pricingTransactionTypeCode: pricingDetail?.PricingTransactionTypeCode,
    pricingFeeTypeCode: pricingDetail?.PricingFeeTypeCode,
    isReissue: pricingDetail?.IsReissue,
    rateTypes: rateTypes,
    jacketIntegrationKey: jacketIntegrationKey,
    coverageType: pricingDetail?.CoverageType,
    coverageTypeData: pricingDetail?.CoverageTypeData, //  getCoverageTypeData(pricingDetail),
    originalPolicy: originalPolicy,
    totalProductPremium: actualFeeSum || 0,
    isValidationRequired: false,
  };
};

const mapPricingProductItem = (
  filePricingDetailId: number,
  productItemId: number,
  product: string,
  pricingDetail?: APIModel.FilePricingDetail
): PricingProductItem => {
  return {
    filePricingDetailId: filePricingDetailId,
    productItemId: productItemId,
    product: product,
    actualFee: pricingDetail?.ActualFee || 0,
    actualFeePrevious: pricingDetail?.ActualFee || 0,
    actualFeeInitial: pricingDetail?.ActualFee || 0,
    calculatedFee: pricingDetail?.CalculatedFee || 0,
    calculatedFeePrevious: pricingDetail?.CalculatedFee || 0,
    agentRetention: pricingDetail?.ActualRetention || 0,
    agentRetentionPrevious: pricingDetail?.ActualRetention || 0,
    agentRetentionInitial: pricingDetail?.ActualRetention || 0,
    calculatedRetention: pricingDetail?.CalculatedRetention || 0,
    calculatedRetentionPrevious: pricingDetail?.CalculatedRetention || 0,
    totalDue: pricingDetail?.ActualRemittance || 0,
    totalDuePrevious: pricingDetail?.ActualRemittance || 0,
    totalDueInitial: pricingDetail?.ActualRemittance || 0,
    calculatedTotalDue: pricingDetail?.CalculatedRemittance || 0,
    calculatedTotalDuePrevious: pricingDetail?.CalculatedRemittance || 0,
    actualFeeUpdated: false, // pricingDetail?.IsDirty || false,
    actualRiskRate: pricingDetail?.ActualRiskRate || 0,
    actualRiskRatePrevious: pricingDetail?.ActualRiskRate || 0,
    actualRiskRateInitial: pricingDetail?.ActualRiskRate || 0,
    calculatedRiskRate: pricingDetail?.CalculatedRiskRate || 0,
    calculatedRiskRatePrevious: pricingDetail?.CalculatedRiskRate || 0,
    actualPremiumTax: pricingDetail?.ActualPremiumTax || 0,
    actualPremiumTaxPrevious: pricingDetail?.ActualPremiumTax || 0,
    calculatedPremiumTax: pricingDetail?.CalculatedPremiumTax || 0,
    calculatedPremiumTaxPrevious: pricingDetail?.CalculatedPremiumTax || 0,
    isBilled: pricingDetail?.IsBilled,
    //lastReportedDate: pricingDetail?.LastReportedDate,
    isBillable: pricingDetail?.IsBillable,
    isReadyToBeBilled: pricingDetail?.IsReadyToBeBilled,
    altPricingReferenceID: pricingDetail?.AltPricingReferenceID,
    lastErrorMessage: pricingDetail?.LastErrorMessage,  
    //isManualFee: pricingDetail?.IsManualFee || 0,
    isFeeError: pricingDetail?.IsFeeError || 0,
    transCode: pricingDetail?.TransCode,
    transCodeDescription: pricingDetail?.TransCodeDescription,
    isValidationRequired: false,
  };
};

const isPricingItemUpdateable = (pricingItem: PricingProductDetail) => {
  return pricingItem.isReadyToBeBilled === 1 && pricingItem.isBilled === 0;
};

const hasUpdateblePricingItems = (pricingProduct: PricingProduct) => {
  const isPricingProductUpdatable = isPricingItemUpdateable(pricingProduct);

  const isPricingProductItemUpdatable = pricingProduct.productItems?.some(
    (ppi) => isPricingItemUpdateable(ppi)
  );

  return isPricingProductUpdatable || isPricingProductItemUpdatable;
};

const getSumPricingTaxes = (
  pricingProduct: PricingProduct,
  isFileLocked: boolean
) => {
  // Default the Tax row to be updateable or not based on the parent product flag
  let isBilled = 1;
  if (pricingProduct.isBilled !== undefined) isBilled = pricingProduct.isBilled;
  let isReadyToBeBilled = 0;
  if (pricingProduct.isReadyToBeBilled !== undefined)
    isReadyToBeBilled = pricingProduct.isReadyToBeBilled;

  // initialize the amounts
  let actualFeeSum = 0;
  let actualPremiumTaxSum = 0;
  let calculatedPremiumTaxSum = 0;

  const hasUpdatebleItems = hasUpdateblePricingItems(pricingProduct);

  if (isFileLocked && hasUpdatebleItems) {
    // Only sum up tax for updateable item(s)
    pricingProduct?.productItems
      ?.filter((pi) => isPricingItemUpdateable(pi))
      ?.forEach((pi) => {
        actualFeeSum += convertToNumber(pi.actualFee); // not sure for this one yet
        actualPremiumTaxSum += convertToNumber(pi.actualPremiumTax);
        calculatedPremiumTaxSum += convertToNumber(pi.calculatedPremiumTax);

        // Enable the Tax row when it has any updatable items
        isBilled = 0;
        isReadyToBeBilled = 1;
      });
  } else {
    // Sum up tax for both Product and ProductItems
    actualFeeSum = convertToNumber(pricingProduct.actualFee);
    actualPremiumTaxSum = convertToNumber(pricingProduct.actualPremiumTax);
    calculatedPremiumTaxSum = convertToNumber(
      pricingProduct.calculatedPremiumTax
    );

    pricingProduct?.productItems?.forEach((pi) => {
      actualFeeSum += convertToNumber(pi.actualFee);
      actualPremiumTaxSum += convertToNumber(pi.actualPremiumTax);
      calculatedPremiumTaxSum += convertToNumber(pi.calculatedPremiumTax);

      if (isPricingItemUpdateable(pi)) {
        // Enable the Tax row when it has any updatable items
        isBilled = 0;
        isReadyToBeBilled = 1;
      }
    });
  }

  return {
    actualPremiumTaxSum,
    calculatedPremiumTaxSum,
    actualFeeSum,
    isBilled,
    isReadyToBeBilled,
  };
};

const getFormDetailTranslatedName = (
  name?: string,
  translations?: Array<Translation>,
  source: boolean = false
) => {
  if (source) {
    const matchingItem = translations?.find((t) => t.source1 === name);
    if (matchingItem) return matchingItem.target1;
  } else {
    const matchingItem = translations?.find((t) => t.target1 === name);
    if (matchingItem) return matchingItem.source1;
  }
  return name;
};

// Operates decimal values
function round(value: number, precision: number = 1) {
  var multiplier = Math.pow(10, precision);
  return Math.round(value * multiplier) / multiplier;
}

const isForeignAddress = (index: number, items: any) => {
  if (!items) return false;
  return items[index].isForeignAddress === 1 ? true : false;
};

const effectiveDateValidationMsg = (index: number, items: any): string => {
  if (!items) return "";
  const msg = items[index].effectiveDateValidationMsg;
  return msg || "";
};

const isNullOrUndefined = (value: unknown): boolean =>
  value === null || value === undefined;

const getCorrelationId = (httpResponseHeaders: any): string => {
  if (httpResponseHeaders && httpResponseHeaders["x-correlation-id"]) {
    return httpResponseHeaders["x-correlation-id"];
  }
  return "";
};

const errorMessageArray = (generalConfig: any) => {
  return [
    "<b>Agency Support Center</b>",
    `<a href="tel:${generalConfig.supportPhone}">${generalConfig.supportPhone}</a>  Option 2`,
    `<a href="mailto:${generalConfig.supportEmail}">${generalConfig.supportEmail}</a>`
  ];
};

const isIssuedStandalone = (standalone: UIModel.StandaloneEndorsement) => {
  return standalone?.orderStatusTypeCode !== OrderStatusType.Pending &&
    standalone?.endorsements?.some(
      (e) =>
        e.endorsementID &&
        e.endorsementStatusTypeCode !== EndorsementStatusType.Pending
    )
    ? true
    : false;
};

export {
  checkIfFileIsLocked,
  isValidParty,
  isValidBuyerBorrower,
  getValidParties,
  isSavableParty,
  isValidProperty,
  isValidLetter,
  isValidJacket,
  isValidEndorsement,
  isIssuableEndorsement,
  isValidStandalone,
  getDisabled,
  isProductSectionAvailable,
  calculateRemittance,
  getPartyPrefix,
  maskZipCode,
  normalizeValue,
  isValidZipCode,
  hasPendingProducts,
  hasAdditionalParty,
  shouldDefaultAddlParty,
  VALID_ZIP_LENGTH,
  isValidDate,
  hasErrorOnRevise,
  hasErrorOnSingleProductAction,
  getErrorMessageByProductAction,
  hasErrorOnAllProductsAction,
  getEndOfDay,
  hasNotNullOrEmptyProp,
  getProductLocations,
  mapPricingProduct,
  mapPricingProductItem,
  getSumPricingTaxes,
  isPricingItemUpdateable,
  hasUpdateblePricingItems,
  mapPricingTax,
  round,
  getFormDetailTranslatedName,
  isPropertyStateChangeWarningRequired,
  isForeignAddress,
  effectiveDateValidationMsg,
  isNullOrUndefined,
  getPropertiesCount,
  fuzzyMatch,
  fuzzyMatchWithList,
  getCorrelationId,
  errorMessageArray,
  isIssuedStandalone,
};
