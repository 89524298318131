import React, {
  useEffect,
  useState,
} from "react";
import { useFormContext } from "react-hook-form";
import ComboBoxField from "controls/global/combo-box-field";
import { useTransCodeContext } from "utils/context/CompanyTransCodeContext";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import { Endorsement } from "entities/UIModel";
import {
  OrderStatusType,
  ProductType,
} from "utils/data/enum";
import { dateWithoutTime } from "utils/shared";

type Props = {
  fullName: string;
  disabled: boolean;
  formPath: string;
  parentProductType: string;
  parentProductIndex: number;
  formType?: string;
  currentEndorsement: Endorsement;
  isRevising?: boolean;
};

export default function EndorsementTranscodeDropDown({
  fullName,
  disabled,
  formPath,
  formType,
  currentEndorsement,
  parentProductType,
  parentProductIndex,
  isRevising,
}: Props) {
  const { getValues, setValue, clearErrors } = useFormContext();

  const effectiveDate = getValues(`${formPath}.effectiveDate`);
  const stateCode = getValues("properties.0.state.abbr");
  const nameMatch = getValues(`${formPath}.endorsementName`);
  const propertyType = getValues("properties.0.propertyType");

  const fieldName = `${formPath}.pricingDetail.transCodeKey`;
  const transCodePropName = `${formPath}.pricingDetail.transCode`;
  const transCodeDescPropName = `${formPath}.pricingDetail.transCodeDescription`;

  const [options, setOptions] = useState<SelectFieldOption[]>([]);
  const [, { getEndorsementTransCodes }] = useTransCodeContext();

  const buildTransCodeKey = (code?: string, desc?: string): string => {
    if (!code || !desc) return "";
    return `${code} - ${desc}`;
  };

  const handleOnChange = (_: React.ChangeEvent<any>, newValue: any) => {
    if (newValue && newValue.value) {
      clearErrors(`${fieldName}` as const);
      setValue(fieldName, newValue.originalValue);
      //setValue(fullName, newValue.originalValue);
      setValue(transCodePropName, newValue.originalValue);
      setValue(transCodeDescPropName, newValue.desc);
    }
  };

  useEffect(() => {
    let endorsementEffectiveDate = effectiveDate;
    if (!endorsementEffectiveDate) {
      if (parentProductType === ProductType.Jacket) {
        endorsementEffectiveDate = getValues(`jackets.${parentProductIndex}.effectiveDate`);
      } else if (parentProductType === ProductType.StandaloneEndorsement) {
        endorsementEffectiveDate = dateWithoutTime();
      }
    }

    const criteria = {
      defaultDate: endorsementEffectiveDate,
      jacketFormType: formType ? formType : "",
      nameMatch: nameMatch,
      propertyType: propertyType,
      state: stateCode,
      transCodeType: "Endorsement",
    };

    async function getEndorsementTransCodesAsync() {
      const data = await getEndorsementTransCodes(criteria);
      const codes =
        (data || []).map((t) => {
          const key = buildTransCodeKey(t.TransCode, t.TransDescription?.replace("* ", ""));
          return {
            text: key,
            value: key,
            desc: t.TransDescription,
            originalValue: t.TransCode,
            default: t.Default,
          };
        }) || [];

      setOptions(codes.length === 0 ? [] : codes);
      if (!isRevising && currentEndorsement?.endorsementStatusTypeCode?.toUpperCase() === OrderStatusType.Pending) {
        const currentTransCodeValue = getValues(transCodePropName);
        if (!currentTransCodeValue) {
          const defaultTransCode = codes.find((c) => c.default === 1);
          if (defaultTransCode) {
            clearErrors(`${fieldName}` as const);
            setValue(fieldName, defaultTransCode.originalValue);
            setValue(transCodePropName, defaultTransCode.originalValue);
            setValue(transCodeDescPropName, defaultTransCode.desc);
          } else {
            setValue(fieldName, "");
            setValue(transCodePropName, "");
            setValue(transCodeDescPropName, "");
          }
        }
      }
    }

    // Make API Call only when all required params are present
    if (endorsementEffectiveDate && nameMatch && formType && propertyType && stateCode) {
      getEndorsementTransCodesAsync();
    }
  }, [effectiveDate, formType, stateCode, nameMatch, propertyType]);

  useEffect(() => {
    //if(disabled) return;
    const fieldValue = getValues(fieldName);
    const transCodeValue = getValues(transCodePropName);
    const transCodeDescValue = getValues(transCodeDescPropName);

    if (!fieldValue && transCodeValue && transCodeDescValue && options) {
      setValue(fieldName, transCodeValue);
    }
  }, [disabled, options]);

  return (
    <ComboBoxField
      label=""
      name={fieldName}
      options={options}
      size="small"
      disabled={disabled}
      noBorder
      onChange={handleOnChange}
      paperStyle={{ width: "300px" }}
      toolTipTitle={buildTransCodeKey(getValues(transCodePropName), getValues(transCodeDescPropName))}
      showTooltip={true}
      controlTooltipOnMouseEvents={true}
    />
  );
}
