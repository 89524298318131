import React from "react";
import ActionButton from "../action-button";
import CheckBookIcon from "theme/icons/CheckBookIcon";

interface Props {
  disabled?:boolean;
  hidden?:boolean;
  onClick?:() => void;
}
export default function ReportAndPayByCheckButton({disabled, hidden, onClick}:Props) {
  return (
    <ActionButton
      variant="outlined"
      color="primary"
      startIcon={<CheckBookIcon />}
      hidden={hidden}
      disabled={disabled}
      onClick={onClick}
      name="ReportAndPay"
    >
      Report & Pay By Check
    </ActionButton>
  )
}
