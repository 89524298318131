import PayByCheckButton from "controls/global/pay-by-check/PayByCheckButton";
import { ReportAndPayCriteria } from "entities/ApiModel/ReportAndPayCriteria";
import { ReportingResult } from "entities/UIModel/ReportingResult";
import PaymentSheetContentMessage from "pages/payments/components/payment-sheet-option/PaymentSheetContentMessage";
import { 
  PAYMENT_SHEET_OPTION_TYPE, 
  PaymentSheetOption,
} from "pages/payments/components/payment-sheet-option/PaymentSheetSelectionOptions";
import React, { 
  useRef,
} from "react";
import { useAutomaticProgressDialogActions } from "utils/context/AutomaticProgressDialogContext";
import { useDialog } from "utils/context/DialogContext";
import { usePayment } from "utils/context/PaymentContext";
import { useProcessStatusTracking } from "utils/context/ProcessStatusContext";
import { useProgressbar } from "utils/context/ProgressbarContext";
import useCreateFile from "utils/custom-hooks/useCreateFile";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import usePricingCalculator from "utils/custom-hooks/usePricingCalculator";
import {
  PaymentSheetOptionType,
  ReportAndPayAction,
  ReportType,
} from "utils/data/enum";
import { v4 as uuidv4 } from "uuid";
import usePricing from "utils/custom-hooks/usePricing";

interface Props {
  isPricingSectionUpdated: boolean;
  hasFileLockedWithUpdateablePricingItem: boolean;
  displayRiskRateColumn: boolean;
  onReportingComplete: (reportingResult: ReportingResult) => void;
}

export default function PricingPayByCheck({
  isPricingSectionUpdated,
  hasFileLockedWithUpdateablePricingItem,
  displayRiskRateColumn,
  onReportingComplete,
}: Props) {
  const [, { openDialog }] = useDialog();
  const [, { openProgressbar, closeProgresbar }] = useProgressbar();
  const [, { setPercentageComplete }] = useProcessStatusTracking();
  const { setValue, getValues } = useFormWrapper();
  const { reloadFile } = useCreateFile();
  const {
    hasBilledWithPartialPaymentSheet,
    getBilledWithNoPaymentSheetTotalAmountDue,
    getTotalBilledAmountDue,
  } = usePricingCalculator();
  const { getSelectedPaymentSheetItems } = usePricing();
  const [, { reportPricing }] = usePayment();
  const [, { openAutomaticProgressDialog, closeAutomaticProgressDialog }] =
    useAutomaticProgressDialogActions();
  const selectedPaymentSheetOption = useRef<PAYMENT_SHEET_OPTION_TYPE>(PaymentSheetOptionType.Product)

  const hasPaymentSheetOptions = hasBilledWithPartialPaymentSheet();

  const entiredFileTotalAmountDue = getTotalBilledAmountDue();
  let mainText = "";
  let helperText = "";
  let paymentSheetOptionDefault:PAYMENT_SHEET_OPTION_TYPE = PaymentSheetOptionType.Default;

  if (hasPaymentSheetOptions) {
    paymentSheetOptionDefault = PaymentSheetOptionType.Product;
    mainText = ""; 
    helperText = "By clicking <strong>Submit</strong>, a payment sheet will be generated.";
  }
  else {
    paymentSheetOptionDefault = PaymentSheetOptionType.File;
    mainText = `By clicking <strong>Submit</strong>, a payment sheet for this file in the amount of ${entiredFileTotalAmountDue} will be generated`;
    helperText = "";    
  }

  const setSelectedPaymentSheetOption = (paymentSheetOptionType:PAYMENT_SHEET_OPTION_TYPE) => {
    selectedPaymentSheetOption.current = paymentSheetOptionType;
  }

  const getPaymentSheetSelectionOptions = () => {
    const paymentSheetSelectionOptions: PaymentSheetOption[] = [];
    if (hasPaymentSheetOptions) {
      paymentSheetSelectionOptions.push({
        label: `Added endorsement(s) only - ${getBilledWithNoPaymentSheetTotalAmountDue()}`,
        value: PaymentSheetOptionType.Product,        
      })
    }

    paymentSheetSelectionOptions.push({
      label: `Entire file - ${entiredFileTotalAmountDue}`, 
      value: PaymentSheetOptionType.File,      
    })

    return paymentSheetSelectionOptions;
  }    

  const handlePayByCheckClick = () => {
    openDialog({
      title: "Pay By Check",
      content: (
        <PaymentSheetContentMessage 
          mainText={mainText}
          helperText={helperText}
          showPaymentSheetSelectionOptions={hasPaymentSheetOptions}
          paymentSheetSelectionTitle="Generate a payment sheet for:"
          paymentSheetSelectionOptions={getPaymentSheetSelectionOptions()}
          paymentSheetOptionDefault={paymentSheetOptionDefault} 
          setSelectedPaymentSheetOption={setSelectedPaymentSheetOption}          
        />
      ),      
      onSubmitAction: onDialogSubmit,
    });
  };

  const onDialogSubmit = async () => {
    const requestId = uuidv4();
    const fileId = getValues("id");
    openProgressbar({
      requestId,
      title: "Please wait while processing Pay by Check...",
      // onCloseAction: async () => {
      //   openAutomaticProgressDialog();
      //   await reloadFile(fileId);
      //   closeAutomaticProgressDialog();
      // },
    });

    setValue("pricing.reportOptionTypeCode", ReportType.ReportPayCheck);

    const reportAndPayCriteria: ReportAndPayCriteria = {
      PaymentSheetOptionTypeCode: selectedPaymentSheetOption.current,
      PendingPaymentItems: getSelectedPaymentSheetItems(selectedPaymentSheetOption.current),
    }

    const result = await reportPricing(reportAndPayCriteria, requestId, ReportAndPayAction.PayByCheck);
    setPercentageComplete(requestId);
    closeProgresbar();

    openAutomaticProgressDialog();
    await reloadFile(fileId);
    closeAutomaticProgressDialog();    

    onReportingComplete(result);
  };

  return (
    <PayByCheckButton
      disabled={false}
      onClick={handlePayByCheckClick}
    />
  );
}
