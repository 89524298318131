import { LocalAtm } from "@mui/icons-material";
import Topic from "@mui/icons-material/Topic";
import FolderIcon from "@mui/icons-material/Folder";
import HelpIcon from "@mui/icons-material/Help";
import AttachMoney from "@mui/icons-material/AttachMoney";
import Add from "@mui/icons-material/Add";
import TopicIcon from "@mui/icons-material/Topic";
import NoteAdd from "@mui/icons-material/NoteAdd";
import { ReactElement } from "react";
import School from "@mui/icons-material/School";
// import ComingSoonIcon from "theme/images/icons/rollover/coming-soon-sm-red.svg";
import CreateFileIconHover from "theme/images/icons/rollover/createfile-shopping-bag-sm-red.svg";
import FilesIconHover from "theme/images/icons/rollover/files-folders-sm-red.svg";
import HelpCenterIconHover from "theme/images/icons/rollover/question-circle-lg-red.svg";
import ReportsIcon from "theme/images/icons/base/reports-file-alt-Connect-sm-gray.svg";
import { ensure } from "utils/validations";
import { PERMISSIONS } from "utils/data/enum";

enum menuIds {
  menuGlobalAccessAgency = "GLOBAL_ACCESS_AGENCY",
  menuHome = "HOME",
  menuCreateFile = "CREATE_FILE",
  menuFiles = "FILES",
  menuPayments = "PAYMENTS",
  menuPaymentsReportPay  = "PAYMENTS_REPORT_PAY",
  menuPaymentsPendingPayments  = "PAYMENTS_PENDING_PAYMENTS",
  menuReports = "REPORTS",
  menuPolicyImages = "POLICY_IMAGES",
  menuStewartResources = "STEWART_RESOURCES",
  menuProfileSettings = "PROFILE_SETTINGS",
  menuManageSignatures = "MANAGE_SIGNATURES",
  menuHelpCenter = "HELP_CENTER",
  menuLogout = "LOGOUT",
  menuRedirectAdmin ="MENU_REDIRECT_ADMIN",
  menuRedirectPSP ="MENU_REDIRECT_ADMIN",
  menuRedirectMain ="MENU_REDIRECT_ADMIN",
}

enum menuNames {
  lblMenuGlobalAccessAgency = "Choose Agency",
  lblMenuCreateFile = "Create File",
  lblMenuFiles = "Files",
  // lblMenuHome = "Home",
  // lblMenuOrderProducts = "Order Products",
  lblMenuPayments = "Payments",
  lblMenuPaymentsReportPay = "Report & Pay",
  lblMenuPaymentsPendingPayments = "Pending Payments",
  lblMenuReports = "Reports",
  lblMenuPolicyUpload = "Policy Images",
  lblMenuStewartResources = "Stewart Resources",
  lblMenuProfileSettings = "Profile Settings",
  lblMenuManageSignatures = "Manage Signatures",
  lblMenuHelpCenter = "Help Center",
  lblMenuLogout = "Logout",
  lblMenuComingSoon = "Coming soon",
}

enum menuType {
  homeMenu = "HOME_MENU",
  mainMenu = "MAIN-MENU",
  subMenu = "SUB-MENU"
}

export type MenuDefinition = {
  // centerMenuId: string;
  menuId: string;
  menuName: string;
  url: string;
  disabled: boolean;
  rolloverIconUrl: string;
  isVisibleInHeader: boolean;
  type: menuType;
  icon?: ReactElement | any;
  headerIcon?: ReactElement | any;
  permissionCode?: PERMISSIONS
};

const getUrlFromMenuName = (menuName: string) =>
  `/${menuName.toLocaleLowerCase().replace(" ", "-")}`;

const menuDefinitions: Array<MenuDefinition> = [
  {
    type: menuType.homeMenu,
    // centerMenuId: "centerMenuOrderProducts",
    menuId: menuIds.menuHome,
    menuName: "",
    url: "/Home",
    disabled: false,
    rolloverIconUrl: CreateFileIconHover,
    isVisibleInHeader: true,
    icon: Add,
    permissionCode: PERMISSIONS.CREATE_FILE_ACCESS
  },
  {
    type: menuType.mainMenu,
    // centerMenuId: "centerMenuOrderProducts",
    menuId: menuIds.menuCreateFile,
    menuName: menuNames.lblMenuCreateFile,
    url: "/file",
    disabled: false,
    rolloverIconUrl: CreateFileIconHover,
    isVisibleInHeader: true,
    icon: Add,
    permissionCode: PERMISSIONS.CREATE_FILE_ACCESS
  },
  {
    type: menuType.mainMenu,
    // centerMenuId: "centerMenuMyFiles",
    menuId: menuIds.menuFiles,
    menuName: menuNames.lblMenuFiles,
    url: getUrlFromMenuName(menuNames.lblMenuFiles),
    disabled: false,
    rolloverIconUrl: FilesIconHover,
    isVisibleInHeader: true,
    icon: Topic,
    headerIcon: FolderIcon,
    permissionCode: PERMISSIONS.FILES_SEARCH
  },
  {
    type: menuType.mainMenu,
    // centerMenuId: "centerMenuHelpCenter",
    menuId: menuIds.menuHelpCenter,
    menuName: menuNames.lblMenuHelpCenter,
    url: "/help-center",
    disabled: false,
    rolloverIconUrl: HelpCenterIconHover,
    isVisibleInHeader: false,
    icon: HelpIcon,
    permissionCode: PERMISSIONS.HELP_ACCESS
  },
  {
    type: menuType.mainMenu,
    // centerMenuId: "centerMenuMyPayments",
    menuId: menuIds.menuPayments,
    menuName: menuNames.lblMenuPayments,
    url: "/reportpay",
    disabled: true,
    rolloverIconUrl: "", //ComingSoonIcon,
    isVisibleInHeader: true,
    icon: AttachMoney,
    headerIcon: LocalAtm,
    permissionCode: PERMISSIONS.VIEW_BILLING
  },
  {
    type: menuType.subMenu,
    // centerMenuId: "centerMenuMyPaymentsReportPay",
    menuId: menuIds.menuPaymentsReportPay,
    menuName: menuNames.lblMenuPaymentsReportPay,
    url: "/reportpay",
    disabled: false,
    rolloverIconUrl: "", //ComingSoonIcon,
    isVisibleInHeader: true,
    permissionCode: PERMISSIONS.VIEW_BILLING
  },
  {
    type: menuType.subMenu,
    // centerMenuId: "centerMenuMyPaymentsPendingPayments",
    menuId: menuIds.menuPaymentsPendingPayments,
    menuName: menuNames.lblMenuPaymentsPendingPayments,
    url: "/pendingpay",
    disabled: false,
    rolloverIconUrl:"", //ComingSoonIcon,
    isVisibleInHeader: true,
    permissionCode: PERMISSIONS.VIEW_BILLING
  },
  {
    type: menuType.mainMenu,
    // centerMenuId: "centerMenuPolicyImages",
    menuId: menuIds.menuPolicyImages,
    menuName: menuNames.lblMenuPolicyUpload,
    url:"/policyimages",
    disabled: false,
    rolloverIconUrl: ReportsIcon,
    isVisibleInHeader: true,
    icon: NoteAdd,
    permissionCode: PERMISSIONS.POLICYIMAGES_ACCESS
  },
  {
    type: menuType.mainMenu,    
    // centerMenuId: "centerStewartResources",
    menuId: menuIds.menuStewartResources,
    menuName: menuNames.lblMenuStewartResources,
    url: "/resources",
    disabled: false,
    rolloverIconUrl: "",
    isVisibleInHeader: true,
    icon: School,
    permissionCode: PERMISSIONS.RESOURCES_ACCESS
  },
  {
    type: menuType.mainMenu,    
    // centerMenuId: "centerMenuReports",
    menuId: "menuReports",
    menuName: menuNames.lblMenuReports,
    url: getUrlFromMenuName(menuNames.lblMenuReports),
    disabled: false,
    rolloverIconUrl: ReportsIcon,
    isVisibleInHeader: true,
    icon: TopicIcon,
    permissionCode: PERMISSIONS.VIEW_REPORTS
  },
  {
    type: menuType.subMenu,
    menuId: menuIds.menuProfileSettings,
    menuName: menuNames.lblMenuProfileSettings,
    url: "/profile-settings",
    disabled: false,
    rolloverIconUrl:"", 
    isVisibleInHeader: true,
  },   
  {
    type: menuType.subMenu,
    menuId: menuIds.menuManageSignatures,
    menuName: menuNames.lblMenuManageSignatures,
    url: "/manage-signatures",
    disabled: false,
    rolloverIconUrl:"", 
    isVisibleInHeader: true,
  }, 
  {
    type: menuType.subMenu,
    menuId: menuIds.menuHelpCenter,
    menuName: menuNames.lblMenuHelpCenter,
    url: "/help-center",
    disabled: false,
    rolloverIconUrl:"", 
    isVisibleInHeader: true,
  }, 
  {
    type: menuType.subMenu,
    menuId: menuIds.menuGlobalAccessAgency,
    menuName: menuNames.lblMenuGlobalAccessAgency,
    url: "/",
    disabled: false,
    rolloverIconUrl:"", 
    isVisibleInHeader: true,
  },   
];

const getUrlByMenuId = (menuId: string) =>
  ensure(
    menuDefinitions.find(
      (menuDefinition) => menuDefinition.menuId === menuId,
    ),
  ).url;

const getUrlByMenuName = (menuName: string) =>
  ensure(
    menuDefinitions.find(
      (menuDefinition) => menuDefinition.menuName === menuName,
    ),
  ).url;

const getMenuIdByMenuName = (menuName: string) =>
  ensure(
    menuDefinitions.find(
      (menuDefinition) => menuDefinition.menuName === menuName,
    ),
  ).menuId;

// const getCenterMenuIdByMenuName = (menuName: string) =>
//   ensure(
//     menuDefinitions.find(
//       (menuDefinition) => menuDefinition.menuName === menuName,
//     ),
//   ).centerMenuId;


export {
  // getCenterMenuIdByMenuName,
  getMenuIdByMenuName,
  getUrlByMenuName,
  getUrlByMenuId,
  menuDefinitions,
  menuNames,
  menuIds,
  menuType,
};
