import {
  Typography,
  styled,
} from "@mui/material";
import { axiosSecuredInstance } from "configurations/axiosConfig";
import EmbedViewer, {
  EmbedViewerRefType,
} from "controls/global/embed-viewer/EmbedViewer";
import Loading from "controls/global/loading";
import {
  HELP_CENTER_RELEASE_NOTE_API_PATH,
} from "pages/help-center/utils";
import React, {
  useEffect,
  useRef,
  useState,
} from "react";
import {
  fontFamily,
  fontSize,
  fontWeight,
  letterSpacing,
  lineHeight,
  padding,
} from "theme/defaultStyle";
import PdfIconBigRed from "theme/icons/PdfIconBigRed";
import ReleaseNoteIcon from "theme/icons/ReleaseNoteIcon";
import { DownloadFileType } from "utils/data/enum";
import {
  downloadByBase64,
  getBase64DataType,
} from "utils/shared";
import { default as cssClasses } from "../../HelpCenter.module.css";

export type ReleaseNoteDto = {
  ReleaseNoteId: string;
  Name: string;
  Description: string;
  Version: string;
  PathFile: string;
  DocFileName: string;
  Tags: string;
  ReleaseNotesDate: string;
  FileBase64: string;
};

type ReleaseNoteType = {
  searchTags: string;
};

const RelaseNoteContainer = styled("div")({
  padding: `${padding.small2} ${padding.zero}`,
  "& .titleName": {
    fontFamily: fontFamily.primary,
    fontSize: fontSize.xlarge0,
    fontWeight: fontWeight.bold2,
    letterSpacing: letterSpacing.medium1,
    lineHeight: lineHeight.xlarge,
    textAlign: "left",
  },
});

const ReleaseNote = ({ searchTags }: ReleaseNoteType) => {
  const [releaseNotes, setReleaseNotes] = useState<ReleaseNoteDto[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const showEmbedViewerRef = useRef<EmbedViewerRefType>(null);

  async function GetReleaseNotesList(): Promise<ReleaseNoteDto[]> {
    const { data } = await axiosSecuredInstance.get(
      HELP_CENTER_RELEASE_NOTE_API_PATH
    );
    return data;
  }

  async function getReleaseNoteItem(
    releaseNoteId: string
  ): Promise<ReleaseNoteDto> {
    const { data } = await axiosSecuredInstance.get(
      `${HELP_CENTER_RELEASE_NOTE_API_PATH}/${releaseNoteId}`
    );
    return data;
  }

  function getReleaseNoteDisplayName({ Name }: ReleaseNoteDto): string {
    return Name;
  }

  useEffect(() => {
    GetReleaseNotesList().then((result: ReleaseNoteDto[]) => {
      setReleaseNotes(result || []);
    });
  }, []);

  function getFilteredItems() {
    return releaseNotes?.filter?.(
      (rn) =>
        !searchTags ||
        rn.Tags.toLowerCase().includes(searchTags.trim().toLowerCase())
    );
  }

  function showContent(quickReferenceCardId: string, fileName: string) {
    setIsLoading(true);
    getReleaseNoteItem(quickReferenceCardId)
      .then(({ FileBase64 }: ReleaseNoteDto) => {
        showEmbedViewerRef.current?.open(
          fileName,
          `data:${getBase64DataType(DownloadFileType.Pdf)};base64,${FileBase64}`
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <div className={cssClasses.globalReleaseNote}>
      <div className={cssClasses.container}>
        <div className={cssClasses.title}>
          <ReleaseNoteIcon />
          <Typography className={cssClasses.gradientTitle} variant="h6">
            Release Notes
          </Typography>
        </div>
      </div>
      <div className={cssClasses.mainContainer}>
        {getFilteredItems().length === 0 && !!searchTags ? (
          <>
            <Typography className={cssClasses.noKeywordMatch}>
              {`No matches found for keyword '${searchTags.trim()}'`}
            </Typography>
            <Typography className={cssClasses.adjustKeywords}>
              {" "}
              Please adjust your keyword search and try again.
            </Typography>
          </>
        ) : (
          getFilteredItems().map((item: ReleaseNoteDto) => {
            const { ReleaseNoteId, DocFileName } = item;
            return (
              <RelaseNoteContainer key={ReleaseNoteId}
                className={cssClasses.itemContainer}>
                <div className={cssClasses.releaseNoteContainer}>
                  <Typography className={"titleName"}>
                    {" "}
                    <a
                      className={cssClasses.downloadFile}
                      href="#"
                      onClick={() => {
                        showContent(ReleaseNoteId, DocFileName);
                      }}
                    >
                      {getReleaseNoteDisplayName(item)}
                    </a>
                  </Typography>
                  <div
                    style={{
                      marginRight: "10px",
                      fill: "#0075AA",
                      cursor: "pointer",
                    }}
                    onClick={async () => {
                      getReleaseNoteItem(ReleaseNoteId).then(
                        ({ FileBase64 }: ReleaseNoteDto) => {
                          downloadByBase64(
                            `data:${getBase64DataType(DownloadFileType.Pdf)};base64,${FileBase64}`,
                            `${DocFileName}.${DownloadFileType.Pdf}`
                          );
                        }
                      );
                    }}
                  >
                    <PdfIconBigRed width={21} height={26} />
                  </div>
                </div>
              </RelaseNoteContainer>
            );
          })
        )}
        {getFilteredItems().length === 0 && !searchTags && (
          <div className={cssClasses.emptySection}>
            <Typography className={cssClasses.emptySectionLabel}>
              No Records
            </Typography>
          </div>
        )}
      </div>
      {isLoading && (
        <div>
          <Loading />
        </div>
      )}
      <EmbedViewer ref={showEmbedViewerRef} />
    </div>
  );
};

export default ReleaseNote;
