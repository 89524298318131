import {
  styled,
} from "@mui/material";
import Box from "@mui/material/Box";
import DatePickerSearPendingPay from "controls/global/datepicker-search/DatePickerSearch";
import MultiLineCell from "controls/global/stewart-table/MultiLineCell";
import StewartTable, {
  DetailsViewConfig,
  SelectionConfig,
  StewartTableColumn,
} from "controls/global/stewart-table/StewartTable";
import StewartTableFooterPaginator from "controls/global/stewart-table/StewartTableFooterPaginator";
import TooltipCell from "controls/global/stewart-table/TooltipCell";
import { IColumn } from "entities/ApiModel/IColumn";
import { PendingPayCriteriaSearch } from "entities/UIModel/PendingPayCriteriaSearch";
import { PendingPayFile } from "entities/UIModel/PendingPayFile";
import { orderBy as _orderBy } from "lodash";
import React, {
  useEffect,
  useState,
} from "react";
import { Link } from "react-router-dom";
import {
  borderRadius,
  borderSize,
  colors,
  defaultStyle,
  fontSize,
  fontWeight,
  gaps,
  padding,
} from "theme/defaultStyle";
import {
  getColumnConfig,
  getColumnSearchField,
} from "utils/columnsGrid";
import { usePendingPay } from "utils/context/PendingPayContext";
import useReportOptionType from "utils/custom-hooks/useReportOptionType";
import { 
  AgencyStatus,
  ReportingOptionType, 
  SessionStorageKeys,
 } from "utils/data/enum";
import {
  formatCurrency,
  formatDate,
  formatPaymentSheetOrderNumber,
} from "utils/shared";
import { Order } from "utils/sorting";
import InputSearchPendingpay from "../input-search/InputSearchPendingpay";
import PaymentDetailsGrid from "../payment-details-grid/PaymentDetailsGrid";
import ReportingOption from "../reporting-option";
import EPay from "./EPay";
import PayByCheck from "./PayByCheck";
import PendingPayFooter from "./PendingPayFooter";
import UndoReport from "./UndoReport";
// import AgencySelectStatus from "./AgencySelectStatus";

type Props = {
  colsConfig: IColumn[];
  rows: PendingPayFile[];
  rowsPerPage: number;
  loadingProgressPercent?: number;
  noRowsMessage: string[];
  onlyShowSelectedFiles: boolean;
  page: number;
  hiddenColumns?: (keyof PendingPayFile)[];
  reportingOptions?: ReportingOptionType[];
  currentFilter?: PendingPayCriteriaSearch;
  isAdvSearchOpen?: boolean;
  onPageChange: (page: number) => void;
  onFiltersChange: (name: keyof PendingPayCriteriaSearch, value: any) => void;
  selectionConfig?: SelectionConfig<PendingPayFile>;
  onOnlySelectedFilesChange?: (value: boolean) => void;
  onRowsPerPageChange: (count: number) => void;
  onRequestSort?: (property: string, disableToggling?: boolean) => void;
  onColumnResize: (column: keyof PendingPayFile, newWidth: number) => void;
  onColumnsModified?: (columns: IColumn[]) => void;
  onShowPdf: (pdfUrl: string) => void;
  order: Order;
  orderBy?: keyof PendingPayFile;
  defaultValues? : any;
  companyStatus: AgencyStatus[];
};

const StyledLink = styled(Link)({
  color: colors.blue01,
  cursor: "pointer",
  fontWeight: fontWeight.bold2,
  textDecoration: "underline",
});

const StyledTableContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
  "& > .MuiTableContainer-root > table.MuiTable-root:not(.details-table)": {
    "& > thead": {
      "& th.MuiTableCell-root.MuiTableCell-head": {
        color: colors.grey08,
        fontSize: fontSize.base,
        fontWeight: fontWeight.normal2,
        "& div.MuiBox-root": {
          color: colors.grey08,
          fontSize: fontSize.base,
          fontWeight: fontWeight.normal2,
        },
        "& div.MuiInputBase-root.MuiOutlinedInput-root.MuiOutlinedInput-adornedEnd": {
          border: borderSize.zero,
          borderBottom: `${borderSize.xsmall} solid ${colors.grey15}`,
          borderRadius: 0,
          "&:focus-within": {
            borderBottom: `${borderSize.small} solid ${colors.blue01}`,
          },
          "& fieldset": {
            border: borderSize.zero,
          },
        },
      },
    },
    "& > tbody": {
      "& > tr": {
        "&:hover td": {
          background: colors.grey10,
        },
        "&:last-child": {
          "& > td:not(.emptyCell)": {
            borderBottom: `1px solid ${colors.grey08}`,
          },
          "& > td:not(.emptyCell):first-child": {
            borderBottomLeftRadius: borderSize.zero,
          },
          "& > td:not(.emptyCell):last-child": {
            borderBottomRightRadius: borderSize.zero
          },
        },
      },
    },
    "& td.MuiTableCell-root.MuiTableCell-body": {
      "& div": {
        color: colors.grey08,
        fontWeight: fontWeight.normal1,
      },
    },
    "& td.MuiTableCell-root.MuiTableCell-footer": {
      borderBottom: defaultStyle.table.border,
      borderBottomLeftRadius: borderRadius.small,
      borderBottomRightRadius: borderRadius.small,
      borderLeft: defaultStyle.table.border,
      borderRight: defaultStyle.table.border,
      padding: padding.xsmall2,
      paddingRight: padding.small2,
    },
  },
  "& .rightColumn": {
    "& span": {
      display: "flex",
      justifyContent: "end",
      paddingRight: padding.small2,
    },
    "& .MuiFormLabel-root.MuiInputLabel-root": {
      textAlign: "end",
    },
  },
});

const StyledTableButtons = styled(Box)({
  display: "flex",
  gap: gaps.small2,
  justifyContent: "end",
});

const CurrencyBox = styled(Box)({
  display: "flex",
  justifyContent: "end",
});

export default function PendingPayGrid({
  colsConfig,
  rows,
  rowsPerPage,
  loadingProgressPercent,
  noRowsMessage,
  onlyShowSelectedFiles,
  page,
  hiddenColumns,
  currentFilter,
  isAdvSearchOpen,
  reportingOptions,
  selectionConfig,
  onPageChange,
  onFiltersChange,
  onRequestSort,
  onColumnResize,
  onColumnsModified,
  onOnlySelectedFilesChange,
  onRowsPerPageChange,
  onShowPdf,
  order,
  orderBy,
  defaultValues,
  companyStatus,
}: Props) {
  const [{ selectedItems }] = usePendingPay();
  const { ePayVisible, payByCheckVisible } = useReportOptionType();
  const noSelectedRows = !selectedItems?.length;
  const [isLoading, setIsLoading] = useState(false);
  const usePriorSearchCriteria = sessionStorage.getItem(SessionStorageKeys.USE_PRIOR_SEARCH_CRITERIA);

  const detailsViewConfig: DetailsViewConfig<PendingPayFile> = {
    enabled: true,
    renderRowDetails: (file) =>
      <PaymentDetailsGrid file={file} />
  };

  const inputSearchLabel = onlyShowSelectedFiles ? "Filter" : "Search";

  const getSearchField = (field: keyof PendingPayFile) =>
    getColumnSearchField<PendingPayFile, PendingPayCriteriaSearch>(colsConfig, field);

  const columns: StewartTableColumn<PendingPayFile>[] = _orderBy(
    [
      {
        field: "clientFileID",
        left: 98,
        actionComponent: () => (
          <InputSearchPendingpay
            name={getSearchField("clientFileID")}
            label={inputSearchLabel}
            filterValue={currentFilter?.clientFileId}
            disabled={isAdvSearchOpen}
            onChange={onFiltersChange}
            defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["clientFileID"] : ""}
          />
        ),
        valueGetter: ({ clientFileID, fileId }: PendingPayFile) => (
          <TooltipCell title={clientFileID}>
            <StyledLink
              to={"/file"}
              state={{
                fileID: fileId,
                isAdvSearchOpen: isAdvSearchOpen,
                clientId: clientFileID,
              }}
            >
              {clientFileID}
            </StyledLink>
          </TooltipCell>
        ),
        ...getColumnConfig<PendingPayFile>(colsConfig, "clientFileID"),
      },
      {
        field: "orderNumber",
        actionComponent: () => (
          <InputSearchPendingpay
            name={getSearchField("orderNumber")}
            label={inputSearchLabel}
            disabled={isAdvSearchOpen}
            onChange={onFiltersChange}
            defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["orderNumber"] : ""}
          />
        ),
        valueGetter: (row: PendingPayFile) => (
          <TooltipCell
            alwaysShowOnHover
            title={row.clientFileIDList}
          >
            {formatPaymentSheetOrderNumber(row.orderNumber)}
          </TooltipCell>
        ),
        ...getColumnConfig<PendingPayFile>(colsConfig, "orderNumber"),
      },
      {
        field: "propertyAddresses",
        actionComponent: () => (
          <InputSearchPendingpay
            name={getSearchField("propertyAddresses")}
            label={inputSearchLabel}
            disabled={isAdvSearchOpen}
            onChange={onFiltersChange}
            defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["propertyAddress"] : ""}
          />
        ),
        valueGetter: (row: PendingPayFile) => <MultiLineCell names={row.propertyAddresses} />,
        ...getColumnConfig<PendingPayFile>(colsConfig, "propertyAddresses"),
      },
      {
        field: "lastBilledDate",
        actionComponent: () => (
          <DatePickerSearPendingPay
            header
            disabled={isAdvSearchOpen}
            handleDateChange={(date: Date | null) => {
              onFiltersChange("lastBilledDate", date);
            }}
            size="small"
            value={defaultValues && usePriorSearchCriteria ? defaultValues["lastBilledDate"] : ""}
          />
        ),
        valueGetter: (row: PendingPayFile) => <TooltipCell title={formatDate(row.lastBilledDate)} />,
        ...getColumnConfig<PendingPayFile>(colsConfig, "lastBilledDate"),
      },
      {
        field: "reportOptionTypeName",
        actionComponent: () => (
          <ReportingOption
            name="reportOptionTypeCode"
            disabled={isAdvSearchOpen}
            defaultValue={
              defaultValues && usePriorSearchCriteria && defaultValues["reportOptionTypeCode"]
                ? defaultValues["reportOptionTypeCode"].split("|")
                : reportingOptions
            }
            onChange={onFiltersChange}
          />
        ),
        valueGetter: (row: PendingPayFile) => <TooltipCell title={row.reportOptionTypeName} />,
        ...getColumnConfig<PendingPayFile>(colsConfig, "reportOptionTypeName"),
      },
      {
        field: "lastBilledBy",
        actionComponent: () => (
          <InputSearchPendingpay
            name={getSearchField("lastBilledBy")}
            label={inputSearchLabel}
            filterValue={
              defaultValues && usePriorSearchCriteria ? defaultValues["lastBilledBy"] : currentFilter?.lastBilledBy
            }
            disabled={isAdvSearchOpen}
            onChange={onFiltersChange}
          />
        ),
        valueGetter: (row: PendingPayFile) => <TooltipCell title={row.lastBilledBy} />,
        ...getColumnConfig<PendingPayFile>(colsConfig, "lastBilledBy"),
      },
      {
        field: "locationLegacyIDs",
        actionComponent: () => (
          <InputSearchPendingpay
            name={getSearchField("locationLegacyIDs")}
            disabled={isAdvSearchOpen}
            onChange={onFiltersChange}
            defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["locationLegacyID"] : ""}
          />
        ),
        valueGetter: (row: PendingPayFile) => <MultiLineCell names={row.locationLegacyIDs} />,
        ...getColumnConfig<PendingPayFile>(colsConfig, "locationLegacyIDs"),
      },
      {
        field: "locationDisplayNames",
        actionComponent: () => (
          <InputSearchPendingpay
            name={getSearchField("locationDisplayNames")}
            label={inputSearchLabel}
            disabled={isAdvSearchOpen}
            onChange={onFiltersChange}
            defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["locationDisplayName"] : ""}
          />
        ),
        valueGetter: (row: PendingPayFile) => <MultiLineCell names={row.locationDisplayNames} />,
        ...getColumnConfig<PendingPayFile>(colsConfig, "locationDisplayNames"),
      },
      {
        field: "agencyName",
        actionComponent: () => (
          <InputSearchPendingpay
            name={getSearchField("agencyName")}
            label={inputSearchLabel}
            disabled={isAdvSearchOpen}
            onChange={onFiltersChange}
            defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["agencyName"] : ""}
          />
        ),
        valueGetter: (row: PendingPayFile) => <TooltipCell title={row.agencyName} />,
        ...getColumnConfig<PendingPayFile>(colsConfig, "agencyName"),
      },
      {
        field: "totalBilledActualRetention",
        actionComponent: () => (
          <InputSearchPendingpay
            name={getSearchField("totalBilledActualRetention")}
            label={inputSearchLabel}
            disabled={isAdvSearchOpen}
            onChange={onFiltersChange}
            defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["totalBilledActualRetention"] : ""}
          />
        ),
        valueGetter: (row: PendingPayFile) => (
          <CurrencyBox>{formatCurrency(row.totalBilledActualRetention, true, false)}</CurrencyBox>
        ),
        ...getColumnConfig<PendingPayFile>(colsConfig, "totalBilledActualRetention"),
      },
      {
        field: "firstJacketEffectiveDate",
        actionComponent: () => (
          <DatePickerSearPendingPay
            header
            disabled={isAdvSearchOpen}
            handleDateChange={(date: Date | null) => {
              onFiltersChange("firstJacketEffectiveDate", date);
            }}
            size="small"
            value={defaultValues && usePriorSearchCriteria ? defaultValues["firstJacketEffectiveDate"] : ""}
          />
        ),
        valueGetter: (row: PendingPayFile) => <TooltipCell title={formatDate(row.firstJacketEffectiveDate)} />,
        ...getColumnConfig<PendingPayFile>(colsConfig, "firstJacketEffectiveDate"),
      },
      {
        field: "firstJacketIssueDate",
        actionComponent: () => (
          <DatePickerSearPendingPay
            header
            disabled={isAdvSearchOpen}
            handleDateChange={(date: Date | null) => {
              onFiltersChange("firstJacketIssueDate", date);
            }}
            size="small"
            value={defaultValues && usePriorSearchCriteria ? defaultValues["firstJacketIssueDate"] : ""}
          />
        ),
        valueGetter: (row: PendingPayFile) => <TooltipCell title={formatDate(row.firstJacketIssueDate)} />,
        ...getColumnConfig<PendingPayFile>(colsConfig, "firstJacketIssueDate"),
      },
      {
        field: "lenderNames",
        actionComponent: () => (
          <InputSearchPendingpay
            name={getSearchField("lenderNames")}
            disabled={isAdvSearchOpen}
            onChange={onFiltersChange}
            defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["lenderName"] : ""}
          />
        ),
        valueGetter: (row: PendingPayFile) => <TooltipCell title={row.lenderNames} />,
        ...getColumnConfig<PendingPayFile>(colsConfig, "lenderNames"),
      },
      {
        field: "stateAbbr",
        actionComponent: () => (
          <InputSearchPendingpay
            name={getSearchField("stateAbbr")}
            label={inputSearchLabel}
            disabled={isAdvSearchOpen}
            onChange={onFiltersChange}
            defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["stateAbbr"] : ""}
          />
        ),
        valueGetter: (row: PendingPayFile) => <TooltipCell title={row.stateAbbr} />,
        ...getColumnConfig<PendingPayFile>(colsConfig, "stateAbbr"),
      },
      {
        field: "propertyTypeCodes",
        actionComponent: () => (
          <InputSearchPendingpay
            name={getSearchField("propertyTypeCodes")}
            label={inputSearchLabel}
            disabled={isAdvSearchOpen}
            onChange={onFiltersChange}
            defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["propertyTypeCodes"] : ""}
          />
        ),
        valueGetter: (row: PendingPayFile) => <TooltipCell title={row.propertyTypeCodes} />,
        ...getColumnConfig<PendingPayFile>(colsConfig, "propertyTypeCodes"),
      },
      // {
      //   field: "companyStatus",
      //   actionComponent: () => (
      //     <AgencySelectStatus
      //       name="companyStatus"
      //       defaultValue={(defaultValues && usePriorSearchCriteria && defaultValues["companyStatus"]) ? defaultValues["companyStatus"].split("|") : companyStatus}
      //       disabled={isAdvSearchOpen}
      //       onChange={onFiltersChange}
      //     />
      //   ),
      //   valueGetter: (row: PendingPayFile) => (<MultiLineCell names={row.companyStatus} />),
      //   ...getColumnConfig<PendingPayFile>(colsConfig, "companyStatus"),
      // },
      {
        field: "transactionTypeCode",
        actionComponent: () => (
          <InputSearchPendingpay
            name={getSearchField("transactionTypeCode")}
            label={inputSearchLabel}
            disabled={isAdvSearchOpen}
            onChange={onFiltersChange}
            defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["transactionTypeCode"] : ""}
          />
        ),
        valueGetter: (row: PendingPayFile) => <TooltipCell title={row.transactionTypeCode} />,
        ...getColumnConfig<PendingPayFile>(colsConfig, "transactionTypeCode"),
      },
      {
        field: "totalBilledActualRiskRate",
        actionComponent: () => (
          <InputSearchPendingpay
            name={getSearchField("totalBilledActualRiskRate")}
            label={inputSearchLabel}
            disabled={isAdvSearchOpen}
            onChange={onFiltersChange}
            defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["totalBilledActualRiskRate"] : ""}
          />
        ),
        valueGetter: (row: PendingPayFile) => (
          <CurrencyBox>{formatCurrency(row.totalBilledActualRiskRate, true, false)}</CurrencyBox>
        ),
        ...getColumnConfig<PendingPayFile>(colsConfig, "totalBilledActualRiskRate"),
      },
      {
        field: "totalBilledActualFee",
        actionComponent: () => (
          <InputSearchPendingpay
            name={getSearchField("totalBilledActualFee")}
            label={inputSearchLabel}
            inputType="number"
            filterValue={currentFilter?.totalBilledActualFee}
            disabled={isAdvSearchOpen}
            onChange={onFiltersChange}
            defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["totalBilledActualFee"] : ""}
          />
        ),
        valueGetter: (row: PendingPayFile) => (
          <CurrencyBox>{formatCurrency(row.totalBilledActualFee, true, false)}</CurrencyBox>
        ),
        ...getColumnConfig<PendingPayFile>(colsConfig, "totalBilledActualFee"),
      },
      {
        field: "totalBilledAmountDue",
        actionComponent: () => (
          <InputSearchPendingpay
            name={getSearchField("totalBilledAmountDue")}
            label={inputSearchLabel}
            inputType="number"
            filterValue={currentFilter?.totalBilledAmountDue}
            disabled={isAdvSearchOpen}
            onChange={onFiltersChange}
            defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["totalBilledAmountDue"] : ""}
          />
        ),
        valueGetter: (row: PendingPayFile) => (
          <CurrencyBox>{formatCurrency(row.totalBilledAmountDue, true, true)}</CurrencyBox>
        ),
        ...getColumnConfig<PendingPayFile>(colsConfig, "totalBilledAmountDue"),
      },
    ],
    "position",
  );

  // Add 1 to column span for expand/select row header.
  //const totalColSpan = columns.length - (hiddenColumns?.length ?? 0) + 1;

  useEffect(() => {
    setIsLoading(loadingProgressPercent !== undefined && loadingProgressPercent >= 0);
  }, [loadingProgressPercent]);

  return (
    <StyledTableContainer width={1}>
      <StewartTable
        cols={columns}
        rows={rows}
        rowsPerPage={rowsPerPage}
        loadingProgressPercent={loadingProgressPercent}
        noRowsMessage={noRowsMessage}
        page={page}
        hiddenColumns={hiddenColumns}
        order={order}
        orderBy={orderBy}
        onRequestSort={onRequestSort}
        onColumnResize={onColumnResize}
        onColumnsModified={onColumnsModified}
        useDbPagingSorting={!onlyShowSelectedFiles}
        detailsViewConfig={detailsViewConfig}
        selectionConfig={selectionConfig}
        footerComponent={<>        
            <PendingPayFooter
              rows={selectedItems}
              onlyShowSelectedFiles={onlyShowSelectedFiles}
              page={page}
              isLoading={isLoading}
              onPageChange={onPageChange}
              onOnlySelectedFilesChange={onOnlySelectedFilesChange}
            />
          </>
       }
      />
      <StewartTableFooterPaginator
        totalRows={rows[0]?.totalRows ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        totalPages={rows[0]?.totalPages ?? 0}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
      <StyledTableButtons>
        <UndoReport
          selectedItems={selectedItems}
          disabled={noSelectedRows || isLoading}
        />
        <EPay
          selectedItems={selectedItems}
          disabled={noSelectedRows || isLoading}
          hidden={!ePayVisible}
        />
        <PayByCheck
          selectedItems={selectedItems}
          disabled={noSelectedRows || isLoading}
          onShowPdf={onShowPdf}
          hidden={!payByCheckVisible}
        />
      </StyledTableButtons>
    </StyledTableContainer>
  );
}
