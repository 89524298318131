import {
  useIsAuthenticated,
} from "@azure/msal-react";
import {
  styled,
} from "@mui/material";
import { axiosSecuredInstance } from "configurations/axiosConfig";
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  gaps,
  padding,
  zIndex,
} from "theme/defaultStyle";
import { PERMISSIONS, SiteErrorType } from "utils/data/enum";
import {
  startNotifying,
  stopNotifying,
} from "utils/services/ContextProgressNotifierService";
import theme from "../theme/default";
import { LayoutBodyContext } from "./LayoutBodyContext";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { SiteErrorMessage } from "./components/SiteErrorMessage";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";
import { useLocation } from "react-router-dom";
import {
  isHelpCenterPage,
  isManageSignaturesPage,
  isProfileSettingsPage,
} from "utils/routes/pathValidator";
import GlobalErrorBoundary from "./components/GlobalErrorBoundary";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import useNavRedirect from "utils/custom-hooks/useNavRedirect";
import useDocumentStore from "utils/context/ImageArchiveContext";
import Toast from "controls/global/datepicker-search/Toast";

type Props = {
  children?: any; // ReactElement<string> | undefined; // any; //ReactNode;
  permission?: string;
};

const RootContainer = styled("div")((props) => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  // gap: gaps.large1,
  justifyContent: "space-between",
  padding: `0 ${padding.xlarge21}`,
  position: "sticky",
  width: "100%",
  zIndex: zIndex.high0,
  "&.show-global-access": {
    gap: gaps.zero,
  },
  [theme.breakpoints.down("md")]: {
    padding: `0 ${padding.xsmall1}`,
  },
  "& > :first-child": {
    flexGrow: 0,
    flexShrink: 0,
    position: "sticky",
    top: 0,
  },
  "& > :last-child": {
    flex: "auto",
    paddingBottom: padding.small2,
  },
}));
const StyledToast = styled(Toast)({
 
  margin: "auto",
  zIndex: 1000,
});

const BodyContainer = styled("div")({
  // NOTE: Currently this element has the necessary flex-grow / flex-shrink rules set by the parent for clarity.
  display: "flex",
  flexDirection: "column",
  justifyContent: "inherit",
  gap: gaps.zero,
});

enum LoginStatus {
  LoggingIn,
  Valid,
  Invalid,
  TimedOut,
  OtherError
}

const PrivatePage = ({ children, permission }: Props) => {
  const [isValidLogin, setIsValidLogin] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [loginStatus, setLoginStatus] = useState<LoginStatus>();
  const [pageContent, setPageContent] = useState<JSX.Element | undefined>();
  const [loginProgressPercent, setLoginProgressPercent] = useState<number | undefined>();
  const bodyRef = useRef<HTMLDivElement>(null);
  const saveFileRef = useRef<HTMLButtonElement>();
  const saveFileChangeRef = useRef<HTMLButtonElement>();
  const isAuthenticated = useIsAuthenticated();
  const [{ globalAccessLoaded }] = useGlobalAccess();
  const { pathname } = useLocation();
  const [{ showChangeAgency }] = useGlobalAccess();
  const [{showToast, isEditMode}, { setShowToast }] = useDocumentStore();

  const showGAAgency = (!(isManageSignaturesPage(pathname) ||
    isProfileSettingsPage(pathname) ||
    isHelpCenterPage(pathname))) &&
    showChangeAgency;

  const processLogin = useMemo(
    () => async (callback: (status: LoginStatus) => void) => {
      startNotifying("login", setLoginProgressPercent);
      //Call DB from here to ensure that the user is valid.
      let status = LoginStatus.LoggingIn;
      callback(status);

      try {
        const response = await axiosSecuredInstance.get("/user/processLogin",
          {
            timeout: 15000,
            timeoutErrorMessage: "TIMEOUT",
          });

        const data = response.data;
        if (data && data.LoginResult && data.LoginResult === 1) {
          status = LoginStatus.Valid;
        }
        else {
          status = LoginStatus.Invalid;
        }
      } catch (error: any) {
        if (String(error?.message).includes("TIMEOUT")) {
          status = LoginStatus.TimedOut;
        }
        else {
          status = LoginStatus.OtherError;
        }
      }
      finally {
        stopNotifying("login", setLoginProgressPercent);
      }

      callback(status);
    }, []);

  const getErrorType = (): SiteErrorType | undefined => {
    let errorType: SiteErrorType | undefined = undefined;

    if (loginStatus === LoginStatus.Invalid || profileSettings.isUserProfileSet === false) {
      errorType = SiteErrorType.BadAccount;
    }
    else if (loginStatus === LoginStatus.OtherError || loginStatus === LoginStatus.TimedOut) {
      errorType = SiteErrorType.NetworkError;
    }

    return errorType;
  };

  useEffect(() => {
    const tryLogin = async () => {
      if (isAuthenticated)
        processLogin((status: LoginStatus) => {
          setLoginStatus(status);
        });
      else {
        setLoginStatus(LoginStatus.Invalid);
      }
    };

    tryLogin();
  }, [isAuthenticated, processLogin]);

  const [{ profileSettings }] = useProfileSettingsCache();
  const permissionCodes = (profileSettings?.userPermissionItemTypes as string[]);
  const { redirect } = useNavRedirect();

  const haspermissionToAccessModule = () => {
    const permissionsEnumArray = Object.entries(PERMISSIONS).map(([key, value]) => (value))
    return permissionsEnumArray.some(item => permissionCodes.includes(item));
  }

  useEffect(() => {
    let controlToShow = undefined;
    if (loginStatus === LoginStatus.Valid && globalAccessLoaded && profileSettings.isUserProfileSet) {
      controlToShow = React.cloneElement(children, {
        saveFileRef,
        saveFileChangeRef,
      });
    }
    setPageContent(controlToShow);
    setIsValidLogin(loginStatus === LoginStatus.Valid);
    isEditMode ? setIsDisabled(true) : setIsDisabled(false);

    // check if user has permission to access any module
    if (permissionCodes.length > 0 && !haspermissionToAccessModule()) {
      redirect("/nomoduleaccess", null);
    }
    // check if user has permission to access routed module
    if (permission && permissionCodes.length > 0 && !permissionCodes.includes(permission)) {
      redirect("/404", null);
    }
  }, [loginStatus, profileSettings.isUserProfileSet, children, globalAccessLoaded, isEditMode]);

  const handleCloseToast =()=>{
    setShowToast(false)
  }
  
  return (
    <RootContainer
      id="root-container"
      className={showGAAgency ? "show-global-access" : ""}
    >
      <Header
        {...{ isValidLogin, saveFileRef, saveFileChangeRef, isDisabled }}
      />
      <GlobalErrorBoundary>
        <LayoutBodyContext.Provider value={bodyRef}>
          <BodyContainer ref={bodyRef} id="body-container">
            {pageContent ? (
              <div>{pageContent}</div>
            ) : (
              <SiteErrorMessage
                errorType={getErrorType()}
                progressPercent={loginProgressPercent}
              />
            )}
            {showToast && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  position: "fixed",
                  top: "calc(100vh - 160px)",
                  right: "10px",
                }}
              >
                <StyledToast
                  title="Toast"
                  message="The date selected is outside the range specified in the advanced search."
                  onClose={handleCloseToast}
                />
              </div>
            )}
            <Footer />
          </BodyContainer>
        </LayoutBodyContext.Provider>
      </GlobalErrorBoundary>
    </RootContainer>
  );
};

export default PrivatePage;
