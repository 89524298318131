import {
  createHook,
  createStore,
  StoreActionApi,
} from "react-sweet-state";
import { resetSessionStorage } from "utils/shared";

interface State {
  nextUrl: string;
}

type StoreApi = StoreActionApi<State>;

const Store = createStore<State, any>({
  initialState: {
    nextUrl: "",
  },
  actions: {
    setNextUrl:
      (nextUrl: string) =>
        ({ setState }: StoreApi) => {
          setState({ nextUrl });
        },
    navigateToNextUrl:
      (navigateFunction: (url: string) => void) =>
        ({ getState }: StoreApi) => {
          const url = getState().nextUrl;

          // if the requested url starts with "https" then user wants to go to an absolute path
          // so we take the browser to the new absolute url
          if (url.startsWith('https://') || url.startsWith('http://')) {
            window.location.href = url;
          }
          else
          {
            if (url === "reload") {
              resetSessionStorage();
              window.location.reload();
            }
            else if (url) {
              navigateFunction(url);
            }
          }
        },
  },
  name: "navigation",
});

const hook = createHook(Store);
export const useNavigation = () => { return hook(); };
